import { twJoin } from "tailwind-merge";
import AwardCategoryList from "./AwardCategoryList";

const AwardsCategoryPage = () => {
  return (
    <div
      className={twJoin(
        "text-left "
      )}
    >
      <AwardCategoryList/>
    </div>
  );
};
export default AwardsCategoryPage;
