import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import MultipleImageUploader from "../../../components/SeoData/MultipleImageUploader";
import LabelAndSelect from "../../../components/SeoData/LabelAndSelect";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
export interface ILabelValueData {
    value: string;
    label: string;
}
interface IData {
    initialData: IInitialData;
    setValue: React.Dispatch<any>;
    setShowList: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: LatestNewsImageUploaderType | undefined, imageFiles: File[], imageUrlList: string[]) => Promise<void>;
    isSave: boolean;
    categoryList: ILabelValueData[];
    setRemoveImage: React.Dispatch<React.SetStateAction<string[]>>;
    removeImages: string[];
}

export interface LatestNewsImageUploaderType {
    id: number;
    news:string;
    image_list: File[] | undefined;
    image_url_list: string[]|[];
    
}
export interface IInitialData{
    id:number;
    category_id:string,
    media_files:[]
}
const options = [
    {
        label: "Passenger services",
        value: "1",
    },
    {
        label: "Ramp Services",
        value: "2",
    },
    {
        label: "Load Control and Flight Operations ",
        value: "3",
    },
];

const LatestNewsImageUploader: FC<IData> = ({ setShowList, initialData, handle_submit, isSave, categoryList,setRemoveImage,removeImages }) => {
    const [activeLang, setActiveLang] = useState<string>("en");
    const [data, setData] = useState<LatestNewsImageUploaderType>({
        id:0,
        image_list:undefined,
        image_url_list:[],
        news:''
    });
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();

    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [imageUrlList, setImageUrlList] = useState<string[]>([]
    );

    const description_en_Ref = useRef();

    const change_data = (value: any, key: string) => {
        if (data) {
            if (key == "image") {
                setData((prev:any) => {
                    return {
                        ...prev,
                        image: value,
                        image_url: value ? URL.createObjectURL(value) : ''
                    }
                })
                return;
            }
            setData({ ...data, [key]: value });
        }
    };

    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };

    const handleFilesChange = (newFiles: File[], remainingUrls: string[],updatedPreviews:string[]) => {
        setImageFiles(newFiles);
        setImageUrlList(remainingUrls);
        //setImageUrlList(updatedPreviews);
        console.log("New selected files:", newFiles);
        console.log("image url list updatedPreviews :", remainingUrls,updatedPreviews);
    };
    useEffect(()=>{
        if(initialData){
            console.log("initialData ",initialData)
            setData({
                id:initialData?.id,
                news:(initialData.category_id?initialData.id.toString():categoryList?categoryList[0]?.value:''),
                image_list:undefined,
                image_url_list:initialData?.media_files?.map((img:any)=>{
                    return img?.file_path
                })
            })
        }
    },[initialData])
    return (
        <div>
            <h2 className="text-lg text-black font-bold text-left my-5">
                New Image
            </h2>
            <div className={twJoin(data.id?'hidden':'',"mt-5")}>
                <LabelAndSelect label="Category" value={data?.news} options={categoryList?.map((cat)=>{
              return {
                label:cat.label,
                value:cat.value
              }
            })} setValue={(value) => change_data(value, 'news')}/>
            </div>
            <div>
                <MultipleImageUploader 
                    imageUrlList={data?.image_url_list}
                    onChange={handleFilesChange}
                    setRemoveImage={setRemoveImage}
                    imageObj={initialData?.media_files}
                    removeImages={removeImages}
                />
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
                <button
                    className={classNames(
                        isSave ? "opacity-50 pointer-events-none" : "",
                        "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                    )}
                    onClick={() => handle_submit(data, imageFiles,imageUrlList)}
                >
                    <SaveIcon className="mr-2" /> Save
                </button>
                <button
                    className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                    onClick={() => {
                        setShowList(true);
                    }}
                >
                    <CancelIcon className="mr-2" color="#9E3039" />
                    Cancel
                </button>
            </div>
        </div>
    );
};
export default LatestNewsImageUploader;
