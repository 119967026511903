import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import LabelAndNumber from "../../components/SeoData/LabelAndNumber";
import UndoChanges from "../../components/UndoChanges/UndoChanges";
import { getAuthUser } from "../../app/services/dashboard";
interface IData {
  activeLang: string;
  initialData: IWhoWeAreData;
  setValue: React.Dispatch<any>;
}
export interface IWhoWeAreData {
  title_en: string;
  title_tc: string;
  title_sc: string;
  subtitle_en: string;
  subtitle_tc: string;
  subtitle_sc: string;
  description_en: string;
  description_tc: string;
  description_sc: string;
  button_en: string;
  button_tc: string;
  button_sc: string;
  button_link_en: string;
  button_link_tc: string;
  button_link_sc: string;
  name_one_en: string;
  name_one_tc: string;
  name_one_sc: string;
  count_one_en: string;
  count_one_tc: string;
  count_one_sc: string;
  sign_one_en: string;
  sign_one_tc: string;
  sign_one_sc: string;
  name_two_en: string;
  name_two_tc: string;
  name_two_sc: string;
  count_two_en: string;
  count_two_tc: string;
  count_two_sc: string;
  sign_two_en: string;
  sign_two_tc: string;
  sign_two_sc: string;
  name_three_en: string;
  name_three_tc: string;
  name_three_sc: string;
  count_three_en: string;
  count_three_tc: string;
  count_three_sc: string;
  sign_three_en: string;
  sign_three_tc: string;
  sign_three_sc: string;

}
export interface IWhoWeAre {
  id: number;
  name_en: string;
  count_en: string;
  sign_en: string;
  name_zh: string;
  count_zh: string;
  sign_zh: string;
  name_cn: string;
  count_cn: string;
  sign_cn: string;
}
const defaultData = [{
  id: 1,
  name_en: '',
  name_zh: '',
  name_cn: '',
  count_en: '0',
  count_zh: '0',
  count_cn: '0',
  sign_en: '',
  sign_zh: '',
  sign_cn: '',
}, {
  id: 2,
  name_en: '',
  name_zh: '',
  name_cn: '',
  count_en: '0',
  count_zh: '0',
  count_cn: '0',
  sign_en: '',
  sign_zh: '',
  sign_cn: '',
}, {
  id: 3,
  name_en: '',
  name_zh: '',
  name_cn: '',
  count_en: '0',
  count_zh: '0',
  count_cn: '0',
  sign_en: '',
  sign_zh: '',
  sign_cn: '',
}]
const WhoWeAre: FC<IData> = ({ activeLang, initialData }) => {
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [data, setData] = useState<IWhoWeAreData>({
    title_en: '',
    title_tc: '',
    title_sc: '',
    subtitle_en: '',
    subtitle_tc: '',
    subtitle_sc: '',
    description_en: '',
    description_tc: '',
    description_sc: '',
    button_en: '',
    button_tc: '',
    button_sc: '',
    button_link_en: '',
    button_link_tc: '',
    button_link_sc: '',
    name_one_en: '',
    name_one_tc: '',
    name_one_sc: '',
    count_one_en: '',
    count_one_tc: '',
    count_one_sc: '',
    sign_one_en: '',
    sign_one_tc: '',
    sign_one_sc: '',
    name_two_en: '',
    name_two_tc: '',
    name_two_sc: '',
    count_two_en: '',
    count_two_tc: '',
    count_two_sc: '',
    sign_two_en: '',
    sign_two_tc: '',
    sign_two_sc: '',
    name_three_en: '',
    name_three_tc: '',
    name_three_sc: '',
    count_three_en: '',
    count_three_tc: '',
    count_three_sc: '',
    sign_three_en: '',
    sign_three_tc: '',
    sign_three_sc: '',
  });
  const [isSave, setIsSave] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [list, setList] = useState<IWhoWeAre[]>(defaultData);
  const descriptionRef_en: any = useRef(null);
  const descriptionRef_zh: any = useRef(null);
  const descriptionRef_cn: any = useRef(null);
  const change_data=(value:any,key:string)=>{
    if(data){
      setData({...data,[key]:value});
    }
  }
  const set_initial_value=()=>{
    if(initialData){
      setData(initialData);
      const listData:any=[
        {
          id:1,
          name_en:initialData?.name_one_en,
          name_zh:initialData?.name_one_tc,
          name_cn:initialData?.name_one_sc,
          count_en:initialData?.count_one_en,
          count_zh:initialData?.count_one_tc,
          count_cn:initialData?.count_one_sc,
          sign_en:initialData?.sign_one_en,
          sign_zh:initialData?.sign_one_tc,
          sign_cn:initialData?.sign_one_sc,
        },
        {
          id:2,
          name_en:initialData?.name_two_en,
          name_zh:initialData?.name_two_tc,
          name_cn:initialData?.name_two_sc,
          count_en:initialData?.count_two_en,
          count_zh:initialData?.count_two_tc,
          count_cn:initialData?.count_two_sc,
          sign_en:initialData?.sign_two_en,
          sign_zh:initialData?.sign_two_tc,
          sign_cn:initialData?.sign_two_sc,
        },
        {
          id:3,
          name_en:initialData?.name_three_en,
          name_zh:initialData?.name_three_tc,
          name_cn:initialData?.name_three_sc,
          count_en:initialData?.count_three_en,
          count_zh:initialData?.count_three_tc,
          count_cn:initialData?.count_three_sc,
          sign_en:initialData?.sign_three_en,
          sign_zh:initialData?.sign_three_tc,
          sign_cn:initialData?.sign_three_sc,
        }
      ];
      setList(listData);
    }
  }
  useEffect(()=>{
    set_initial_value()
  },[initialData]);
  const change_list_data = (id: number, value: string, name: string) => {
    setList([...list?.map((list) => {
      if (list.id === id) {
        let myObj: any = { ...list };
        myObj[name] = value;
        return myObj;
      }
      return list;
    })])
  }
  
  const handle_submit = async() => {
    setIsSave(true);
    const formData = new FormData();
    formData.append('who_are_we_title_en', data?.title_en);
    formData.append('who_are_we_title_tc', data?.title_tc);
    formData.append('who_are_we_title_sc', data?.title_sc);
    formData.append('who_are_we_sub_title_en', data?.subtitle_en);
    formData.append('who_are_we_sub_title_tc', data?.subtitle_tc);
    formData.append('who_are_we_sub_title_sc', data?.subtitle_sc);
    formData.append('who_are_we_description_en', data?.description_en);
    formData.append('who_are_we_description_tc', data?.description_tc);
    formData.append('who_are_we_description_sc', data?.description_sc);
    formData.append('who_are_we_btn_text_en', data?.button_en);
    formData.append('who_are_we_btn_text_tc', data?.button_tc);
    formData.append('who_are_we_btn_text_sc', data?.button_sc);
    formData.append('who_are_we_btn_link_en', data?.button_link_en);
    formData.append('who_are_we_btn_link_tc', data?.button_link_tc);
    formData.append('who_are_we_btn_link_sc', data?.button_link_sc);
    list?.map((lis,key)=>{
      const index=key+1;
      if(index===1){
        formData.append('who_are_we_list_title_one_en', lis.name_en);
        formData.append('who_are_we_list_title_one_tc', lis.name_zh);
        formData.append('who_are_we_list_title_one_sc', lis.name_cn);
        formData.append('who_are_we_list_count_one_en', lis.count_en?.toString());
        formData.append('who_are_we_list_count_one_tc', lis.count_zh?.toString());
        formData.append('who_are_we_list_count_one_sc', lis.count_cn?.toString());
        formData.append('who_are_we_list_sign_one_en', lis.sign_en?.toString());
        formData.append('who_are_we_list_sign_one_tc', lis.sign_zh?.toString());
        formData.append('who_are_we_list_sign_one_sc', lis.sign_cn?.toString());
      }
      if(index===2){
        formData.append('who_are_we_list_title_two_en', lis.name_en);
        formData.append('who_are_we_list_title_two_tc', lis.name_zh);
        formData.append('who_are_we_list_title_two_sc', lis.name_cn);
        formData.append('who_are_we_list_count_two_en', lis.count_en?.toString());
        formData.append('who_are_we_list_count_two_tc', lis.count_zh?.toString());
        formData.append('who_are_we_list_count_two_sc', lis.count_cn?.toString());
        formData.append('who_are_we_list_sign_two_en', lis.sign_en?.toString());
        formData.append('who_are_we_list_sign_two_tc', lis.sign_zh?.toString());
        formData.append('who_are_we_list_sign_two_sc', lis.sign_cn?.toString());
      }
      if(index===3){
        formData.append('who_are_we_list_title_three_en', lis.name_en);
        formData.append('who_are_we_list_title_three_tc', lis.name_zh);
        formData.append('who_are_we_list_title_three_sc', lis.name_cn);
        formData.append('who_are_we_list_count_three_en', lis.count_en?.toString());
        formData.append('who_are_we_list_count_three_tc', lis.count_zh?.toString());
        formData.append('who_are_we_list_count_three_sc', lis.count_cn?.toString());
        formData.append('who_are_we_list_sign_three_en', lis.sign_en?.toString());
        formData.append('who_are_we_list_sign_three_tc', lis.sign_zh?.toString());
        formData.append('who_are_we_list_sign_three_sc', lis.sign_cn?.toString());
      }
      return;
    });
    var getUser = getAuthUser();
    await axios(endpointUrl + "update-who-are-we", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status === true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {
          setErrorPopup(true);
          setMessage(res?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        console.log('error ', err);
        setErrorPopup(true);
        setMessage(err?.data?.message);
      });
  }
  const undoChanges=()=>{
    set_initial_value()
    setIsCancel(false);
  }
  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={()=>undoChanges()}/>
      <h2 className="text-15 text-black font-bold text-left my-5">
        WHO ARE WE
      </h2>
      <div className="mt-5">
        <div
          className={twJoin(
            activeLang == "en"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Title (EN)"
            value={data?.title_en}
            setValue={(value)=>change_data(value,'title_en')}
          />
          <LabelAndTextbox
            label="Subtitle (EN)"
            value={data?.subtitle_en}
            setValue={(value)=>change_data(value,'subtitle_en')}
          />
          <LabelAndEditor
            refId={descriptionRef_en}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value)=>change_data(value,'description_en')}
          />
          <LabelAndTextbox
            label="Button Text (EN)"
            value={data?.button_en}
            setValue={(value)=>change_data(value,'button_en')}
          />
          <LabelAndTextbox
            label="Button Link (EN)"
            value={data?.button_link_en}
            setValue={(value)=>change_data(value,'button_link_en')}
          />
          <div>
            <h2 className="text-lg text-black font-bold text-left my-5">
              List
            </h2>
            <div>
              <div className="mt-5">
                {list?.map((ls, key) => {
                  return <div key={key} className="grid md:grid-cols-3 grid-cols-1 gap-3 md:mb-0 mb-5 md:pb-0 pb-5 md:border-b-0 border-b md:border-b-gray last:border-b-0">
                    <LabelAndTextbox
                      label="Title (EN)"
                      value={ls.name_en}
                      setValue={(value: any) => change_list_data(ls.id, value, 'name_en')}
                    />
                    <LabelAndNumber
                      label="Count (EN)"
                      value={Number(ls.count_en)}
                      setValue={(value: any) => change_list_data(ls.id, value, 'count_en')}
                    />
                    <LabelAndTextbox
                      label="Sign (EN)"
                      value={ls.sign_en}
                      setValue={(value: any) => change_list_data(ls.id, value, 'sign_en')}
                    />
                  </div>
                })}

              </div>
            </div>
          </div>
        </div>
        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Title (TC)"
            value={data?.title_tc}
            setValue={(value)=>change_data(value,'title_tc')}
          />
          <LabelAndTextbox
            label="Subtitle (TC)"
            value={data?.subtitle_tc}
            setValue={(value)=>change_data(value,'subtitle_tc')}
          />
          <LabelAndEditor
            refId={descriptionRef_zh}
            label="Description (TC)"
            value={data?.description_tc}
            setValue={(value)=>change_data(value,'description_tc')}
          />
          <LabelAndTextbox
            label="Button Text (TC)"
            value={data?.button_tc}
            setValue={(value)=>change_data(value,'button_tc')}
          />
          <LabelAndTextbox
            label="Button Link (TC)"
            value={data?.button_link_tc}
            setValue={(value)=>change_data(value,'button_link_tc')}
          />
          <div>
            <h2 className="text-lg text-black font-bold text-left my-5">
              List
            </h2>
            <div>
              <div className="mt-5">
                {list?.map((list, key) => {
                  return <div key={key} className="grid md:grid-cols-3 grid-cols-1 gap-3">
                    <LabelAndTextbox
                      label="Title (TC)"
                      value={list.name_zh}
                      setValue={(value: any) => change_list_data(list.id, value, 'name_zh')}
                    />
                    <LabelAndNumber
                      label="Count (TC)"
                      value={Number(list.count_zh)}
                      setValue={(value: any) => change_list_data(list.id, value, 'count_zh')}
                    />
                    <LabelAndTextbox
                      label="Sign (TC)"
                      value={list.sign_zh}
                      setValue={(value: any) => change_list_data(list.id, value, 'sign_zh')}
                    />
                  </div>
                })}

              </div>
            </div>
          </div>
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Title (SC)"
            value={data?.title_sc}
            setValue={(value)=>change_data(value,'title_sc')}
          />
          <LabelAndTextbox
            label="Subtitle (SC)"
            value={data?.subtitle_sc}
            setValue={(value)=>change_data(value,'subtitle_sc')}
          />
          <LabelAndEditor
            refId={descriptionRef_cn}
            label="Description (SC)"
            value={data?.description_sc}
            setValue={(value)=>change_data(value,'description_sc')}
          />
          <LabelAndTextbox
            label="Button Text (SC)"
            value={data?.button_sc}
            setValue={(value)=>change_data(value,'button_sc')}
          />
          <div className="mt-5">
          <LabelAndTextbox
            label="Button Link (SC)"
            value={data?.button_link_sc}
            setValue={(value)=>change_data(value,'button_link_sc')}
          />
          </div>
          <div>
            <h2 className="text-lg text-black font-bold text-left my-5">
              List
            </h2>
            <div>
              <div className="mt-5">
                {list?.map((list, key) => {
                  return <div key={key} className="grid md:grid-cols-3 grid-cols-1 gap-3">
                    <LabelAndTextbox
                      label="Title (SC)"
                      value={list.name_cn}
                      setValue={(value: any) => change_list_data(list.id, value, 'name_cn')}
                    />
                    <LabelAndNumber
                      label="Count (SC)"
                      value={Number(list.count_cn)}
                      setValue={(value: any) => change_list_data(list.id, value, 'count_cn')}
                    />
                    <LabelAndTextbox
                      label="Sign (SC)"
                      value={list.sign_cn}
                      setValue={(value: any) => change_list_data(list.id, value, 'sign_cn')}
                    />
                  </div>
                })}

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setIsCancel(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default WhoWeAre;
