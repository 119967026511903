import { useEffect, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import WhoWeAre, { IWhoWeAreData } from "../components/WhoWeAre";
import { twJoin } from "tailwind-merge";
import { useGetWhoWeAreQuery } from "../../app/services/home/home";

const WhoWeAreSection = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [whoWeAreData, setWhoWeAreData] = useState<IWhoWeAreData>({
    title_en: '',
    title_tc: '',
    title_sc: '',
    subtitle_en: '',
    subtitle_tc: '',
    subtitle_sc: '',
    description_en: '',
    description_tc: '',
    description_sc: '',
    button_en: '',
    button_tc: '',
    button_sc: '',
    button_link_en: '',
    button_link_tc: '',
    button_link_sc: '',
    name_one_en: '',
    name_one_tc: '',
    name_one_sc: '',
    count_one_en: '',
    count_one_tc: '',
    count_one_sc: '',
    sign_one_en: '',
    sign_one_tc: '',
    sign_one_sc: '',
    name_two_en: '',
    name_two_tc: '',
    name_two_sc: '',
    count_two_en: '',
    count_two_tc: '',
    count_two_sc: '',
    sign_two_en: '',
    sign_two_tc: '',
    sign_two_sc: '',
    name_three_en: '',
    name_three_tc: '',
    name_three_sc: '',
    count_three_en: '',
    count_three_tc: '',
    count_three_sc: '',
    sign_three_en: '',
    sign_three_tc: '',
    sign_three_sc: '',
  });
  const { data:whoAreWe } = useGetWhoWeAreQuery();
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  useEffect(() => {
    if (whoAreWe) {
      const data=whoAreWe?.who_are_we;
      setWhoWeAreData({
        description_en: data?.description_en,
        description_tc: data?.description_tc,
        description_sc: data?.description_sc,
        subtitle_en: data?.sub_title_en,
        subtitle_tc: data?.sub_title_tc,
        subtitle_sc: data?.sub_title_sc,
        title_en: data?.title_en,
        title_tc: data?.title_tc,
        title_sc: data?.title_sc,
        button_en:data?.additional_data_en?.btn_text,
        button_tc:data?.additional_data_tc?.btn_text,
        button_sc:data?.additional_data_sc?.btn_text,
        button_link_en:data?.additional_data_en?.btn_link,
        button_link_tc:data?.additional_data_tc?.btn_link,
        button_link_sc:data?.additional_data_sc?.btn_link,

        name_one_en:data?.additional_data_en?.list_title_one,
        name_one_tc:data?.additional_data_tc?.list_title_one,
        name_one_sc:data?.additional_data_sc?.list_title_one,

        count_one_en:data?.additional_data_en?.list_count_one,
        count_one_tc:data?.additional_data_tc?.list_count_one,
        count_one_sc:data?.additional_data_sc?.list_count_one,
        sign_one_en:data?.additional_data_en?.list_sign_one,
        sign_one_tc:data?.additional_data_tc?.list_sign_one,
        sign_one_sc:data?.additional_data_sc?.list_sign_one,

        name_two_en:data?.additional_data_en?.list_title_two,
        name_two_tc:data?.additional_data_tc?.list_title_two,
        name_two_sc:data?.additional_data_sc?.list_title_two,
        count_two_en:data?.additional_data_en?.list_count_two,
        count_two_tc:data?.additional_data_tc?.list_count_two,
        count_two_sc:data?.additional_data_sc?.list_count_two,

        sign_two_en:data?.additional_data_en?.list_sign_two,
        sign_two_tc:data?.additional_data_tc?.list_sign_two,
        sign_two_sc:data?.additional_data_sc?.list_sign_two,

        name_three_en:data?.additional_data_en?.list_title_three,
        name_three_tc:data?.additional_data_tc?.list_title_three,
        name_three_sc:data?.additional_data_sc?.list_title_three,
        count_three_en:data?.additional_data_en?.list_count_three,
        count_three_tc:data?.additional_data_tc?.list_count_three,
        count_three_sc:data?.additional_data_sc?.list_count_three,
        sign_three_en:data?.additional_data_en?.list_sign_three,
        sign_three_tc:data?.additional_data_tc?.list_sign_three,
        sign_three_sc:data?.additional_data_sc?.list_sign_three,
      })
    }
  }, [whoAreWe]);
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      <CommonLanguage changeLanguage={changeLanguage} activeLang={activeLang} />
      <WhoWeAre
        activeLang={activeLang}
        initialData={whoWeAreData}
        setValue={setWhoWeAreData}
      />
    </div>
  );
};
export default WhoWeAreSection;