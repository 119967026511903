

export interface Employee {
  id: number;
  name: string;
  data: EmployeeData[];
}

export interface WorkingInfo {
  startWorkHr: string;
  endWorkHr: string;
  totalHr: string;
}

export interface WorkingDetailInfo {
  activeTime: string;
  downTime: string;
  lateness: string;
  overtime: string;
}

export interface EmployeeData {
  date: string;
  workingInfo: WorkingInfo;
  workingDetail: WorkingDetailInfo;
  productive: string;
  unproductive: string;
  neutral: string;
  productivePercent: string;
  unproductivePercent: string;
  neutralPercent: string;
}

export const dummyTimesheetData = [
  {
    id: 1,
    date: "2023-11-03",
    employee: "Visibee 1",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 2,
    date: "2023-11-03",
    employee: "Visibee 2",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 3,
    date: "2023-11-03",
    employee: "Visibee 3",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 4,
    date: "2023-11-03",
    employee: "Visibee 4",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
  {
    id: 5,
    date: "2023-11-03",
    employee: "Visibee 1",
    clockIn1: "08:01:36",
    clockOut1: "12:57:54",
    location1: "--",
    clockIn2: "13:57:36",
    clockOut2: "18:57:45",
    location2: "--",
    clockIn3: "08:01:36",
    clockOut3: "12:57:54",
    location3: "--",
  },
];
export interface TimeSheetDataCap {
  Id: number;
  Date: string;
  Employee: string;
  ClockIn1: string;
  ClockOut1: string;
  Location1: string;
  ClockIn2: string;
  ClockOut2: string;
  Location2: string;
  ClockIn3: string;
  ClockOut3: string;
  Location3: string;
}
export type TTemplate = {
  id: number;
  label: string;
  value: string;
  viewsColumn: string[]
}
export const templateList = [
  { id: 1, label: "View #1", value: "view-i23vd", viewColumns: [] },
  { id: 2, label: "View #2", value: "view-i23vd", viewColumns: [] },
  { id: 3, label: "View #3", value: "view-i23vd", viewColumns: [] },
];

const ActiveTimeReport = () => {
  return (
    <>
    <div></div>
    </>
  );
};
export default ActiveTimeReport;
