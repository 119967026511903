import { FC, useEffect, useState } from "react";
import { baseObj } from "../../../utils/constants";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import Button from "../../../components/common/Button";
import AwardAndCertificateInput from "../Awards/AwardAndCertificateInput";
import AwardsAndCertificateTable from "../Awards/AwardsAndCertificateTable";
import CustomerAirlinesInput, { IAirline, airlines_type } from "./CustomerAirlinesInput";
import CustomerAirlinesTable from "./CustomerAirlinesTable";
import { useGetAirlinesInfoMutation, useGetAirlinesListQuery } from "../../../app/services/company/airline";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import { endpointUrl } from "../../../app/services/api";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import CustomDropdownListForFilter from "../../../components/EmployeeTable/CustomDropdownListForFilter";
import { NumberDropdownList } from "../../../components/common/DropdownList";
import ErrorPopup from "../../../components/ErrorPopup/ErrorPopup";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { getAuthUser } from "../../../app/services/dashboard";
export interface IAwardAndCertificate {
    id: number;
    date?: Date | undefined;
    title_en: string;
    title_tc: string;
    title_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    image: File | undefined;
    image_url: string;
}
export interface IAwardAndCertificateList {
    data_list: baseObj[];
}
const CustomerAirlinesList: FC<IAwardAndCertificateList> = ({
    data_list
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IAirline>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        link_en: '',
        link_tc: '',
        link_sc: '',
        type: '',
        image: undefined,
        image_url: ''
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [type, setType] = useState<string>('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [getAirlineInfo] = useGetAirlinesInfoMutation();
    const { data: airlines } = useGetAirlinesListQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch,
        type: type,
        sort: sorting?sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','):'',
    })
    useEffect(() => {
        if (airlines) {
            setData(airlines?.data);
            setItemCount(airlines?.total);
        }
    }, [airlines]);
    const handle_submit = async (data: IAirline) => {
        const formData = new FormData();
        if (data) {
            formData.set('id', data.id?.toString() ?? 0)
            formData.set('title_en', data.title_en);
            formData.set('title_tc', data.title_tc);
            formData.set('title_sc', data.title_sc);
            formData.set('link_en', data.link_en);
            formData.set('link_tc', data.link_tc);
            formData.set('link_sc', data.link_sc);
            formData.set('type', data.type);
            if (data.image) {
                formData.set('image', data.image);
            }
            const url = detailData?.id ? 'update-airline-content' : 'airline-content';
            var getUser = getAuthUser();
            await axios(endpointUrl + url, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getUser.token}`,
                },
                data: formData,
            })
                .then((res: any) => {
                    if (res?.data?.status == true) {
                        getAirlineInfo({
                            page: currentPage,
                            limit: pagination.pageSize,
                            search: '',
                            type: type,
                            sort:''
                        }).then((res: any) => {
                            setData(res?.data?.data);
                            setItemCount(res?.data?.total)
                        })
                        toast(res?.data?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            type: "success",
                        });
                        setShow(true);
                    } else {
                        setErrorPopup(true);
                        setMessage(res?.response?.data?.message);
                    }
                    setIsSave(false);
                })
                .catch((err) => {
                    setErrorPopup(true);
                    setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
                });
        }

    }
    const clear_data = () => {
        setDetailData({
            id: 0,
            title_en: '',
            title_tc: '',
            title_sc: '',
            link_en: '',
            link_tc: '',
            link_sc: '',
            type: '',
            image: undefined,
            image_url: ''
        })
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pb-8 sm:pb-9 "
        )}
    >
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />   
        <div className="text-left">
            {show == false ? <CustomerAirlinesInput handle_submit={handle_submit} isSave={isSave} airlines={detailData} setAirlines={setDetailData} setShow={setShow} /> :
                <>
                    <div className="flex items-center justify-between">
                        <div className="flex">
                            <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                                <DebounceSearchInput
                                    setState={setGlobalSearch}
                                    debounce={800}
                                >
                                    {(setState) => (
                                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                            <SearchIcon className="mr-2" />
                                        </div>
                                    )}
                                </DebounceSearchInput>
                            </div>
                            <div
                                className={twJoin(
                                    // styles.permissionDropdown,
                                    "flex items-center mr-[10px] sm:w-auto w-full"
                                )}
                            >
                                <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium  xl:text-sm text-12 text-[#464E5F] mr-1">
                                    Type
                                </p>
                                <CustomDropdownListForFilter
                                    placeholder="Type"
                                    state={type}
                                    setState={setType}
                                    dropdownItems={
                                        airlines_type
                                            ? airlines_type.map((dp: any) => ({
                                                value: dp.value,
                                                label: dp.name,
                                            }))
                                            : []
                                    }
                                    textAlign="text-left py-0"       
                                    customClassName="py-0"   
                                    classNameCus="py-0"
                                    height={30}       
                                    className="max-h-[30px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full py-0"
                                />
                            </div>
                        </div>
                        <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
                            <Button
                                label="+ Add New"
                                onClick={() => { clear_data(); setShow(false) }}
                                type="button"
                                variant="primary"
                                customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                                size="base"
                            />
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col justify-end mb-1">
                        <div className="flex items-center sm:justify-start justify-end">
                            <div className="flex items-center 3xl:ml-3 mr-[10px]">
                                <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                                    DISPLAY
                                </p>
                                <NumberDropdownList
                                    state={pagination.pageSize}
                                    setState={value =>
                                        setPagination(prev => ({
                                            ...prev,
                                            pageSize: value as number
                                        }))}
                                    dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                                        const count = (index + 1) * 20;
                                        return {
                                            value: count,
                                            label: String(count)
                                        };
                                    })}
                                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                                    textAlign="text-left pl-3"
                                    paddingClass="py-[2px]"
                                    placeholder="All"
                                />
                            </div>
                        </div>
                    </div>
                    <CustomerAirlinesTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                        sorting={sorting}
            setSorting={setSorting}
                    />
                </>
            }
        </div>
    </div>
}
export default CustomerAirlinesList;