import { FC, useEffect, useState } from "react";
import { baseObj } from "../../../utils/constants";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import Button from "../../../components/common/Button";
import { useGetAirlinesInfoMutation, useGetAirlinesListQuery } from "../../../app/services/company/airline";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import { endpointUrl } from "../../../app/services/api";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import CustomDropdownListForFilter from "../../../components/EmployeeTable/CustomDropdownListForFilter";
import ActivitiesInput, { IActivities } from "./ActivitiesInput";
import ActivitiesTable from "./ActivitiesTable";
import { useFetch_activitiesQuery, useFetch_activities_infoMutation } from "../../../app/services/job/career";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { getAuthUser } from "../../../app/services/dashboard";
export interface IAwardAndCertificate {
    id: number;
    date?: Date | undefined;
    title_en: string;
    title_tc: string;
    title_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    image: File | undefined;
    image_url: string;
}
export interface IAwardAndCertificateList {
    data_list: baseObj[];
}
const ActivitiesList = () => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IActivities>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: ''
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [getActivitiesInfo] = useFetch_activities_infoMutation();
    const { data: activities } = useFetch_activitiesQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch,
        type: 'staff-activities',
        sort: sorting
            .map(
                (s) =>
                    `${s.desc ? "-" : ""}${s.id}`
            )
            .join(","),
    })
    useEffect(() => {
        if (activities) {
            setData(activities?.data);
            setItemCount(activities?.total);
        }
    }, [activities]);
    const handle_submit = async (data: IActivities) => {
        const formData = new FormData();
        if (data) {
            formData.set('id', data.id?.toString() ?? 0)
            formData.set('title_en', data.title_en);
            formData.set('title_tc', data.title_tc);
            formData.set('title_sc', data.title_sc);
            formData.set('page_type', 'staff-activities');
            if (data.image) {
                formData.set('image', data.image);
            }
            const url = detailData?.id ? 'update-sats-care-content' : 'sats-care-content';
            var getUser = getAuthUser();
            await axios(endpointUrl + url, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getUser.token}`,
                },
                data: formData,
            })
                .then((res: any) => {
                    if (res?.data?.status == true) {
                        getActivitiesInfo({
                            page: currentPage,
                            limit: pagination.pageSize,
                            search: '',
                            type: 'staff-activities',
                            sort: sorting
                                .map(
                                    (s) =>
                                        `${s.desc ? "-" : ""}${s.id}`
                                )
                                .join(","),
                        }).then((res: any) => {
                            setData(res?.data?.data);
                            setItemCount(res?.data?.total);
                        })
                        toast(res?.data?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            type: "success",
                        });
                        setShow(true);
                    } else {
                        setErrorPopup(true);
                        setMessage(res?.response?.data?.message);
                    }
                    setIsSave(false);
                })
                .catch((err) => {
                    setErrorPopup(true);
                    setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
                });
        }

    }
    const clear_data = () => {
        setDetailData({
            id: 0,
            title_en: '',
            title_tc: '',
            title_sc: '',
            image: undefined,
            image_url: ''
        })
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
        />
        <div className="text-left">
            {show == false ? <ActivitiesInput handle_submit={handle_submit} isSave={isSave} initialData={detailData} setShow={setShow} /> :
                <>
                    <div className="flex items-center justify-between">
                        <div className="flex">
                            <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                                <DebounceSearchInput
                                    setState={setGlobalSearch}
                                    debounce={800}
                                >
                                    {(setState) => (
                                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                            <SearchIcon className="mr-2" />
                                        </div>
                                    )}
                                </DebounceSearchInput>
                            </div>
                        </div>
                        <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
                            <Button
                                label="+ Add New"
                                onClick={() => { clear_data(); setShow(false) }}
                                type="button"
                                variant="primary"
                                customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                                size="base"
                            />
                        </div>
                    </div>

                    <ActivitiesTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                </>
            }
        </div>
    </div>
}
export default ActivitiesList;