import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { compareByFieldSpecs } from "@fullcalendar/core/internal";
import { IFooterLink, getFooterData, useGetFooterDataMutation } from "../../app/services/footer";
import { getAuthUser } from "../../app/services/dashboard";
export interface IFooterBottomInput {
    title_en: string;
    title_tc: string;
    title_sc: string;
    link_en: string;
    link_tc: string;
    link_sc: string;
    id: number;
}
interface IFooterBottomInputData {
    footerData: IFooterBottomInput | undefined,
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setFooterData: (value: React.SetStateAction<IFooterLink[]>) => void;
}
const FooterBottomForm: FC<IFooterBottomInputData> = ({
    footerData,
    setShow,
    setFooterData
}) => {
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [activeLang, setActiveLang] = useState<string>("en");
    const [data, setData] = useState<IFooterBottomInput | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        link_en: '',
        link_tc: '',
        link_sc: '',
        id: 0
    });
    const [getFooterData]=useGetFooterDataMutation();
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const handle_change = (value: any, key: string) => {
        if (data) {
            setData({ ...data, [key]: value });
        }
    }
    const handle_submit = async (footer: IFooterBottomInput | undefined) => {
        setIsSave(true);
        const formData = new FormData();
        formData.append('title_en', footer?.title_en ?? '');
        formData.append('title_tc', footer?.title_tc ?? '');
        formData.append('title_sc', footer?.title_sc ?? '');
        formData.append('link_en', footer?.link_en ?? '');
        formData.append('link_tc', footer?.link_tc ?? '');
        formData.append('link_sc', footer?.link_sc ?? '');
        formData.append('id', footer?.id.toString() ?? '0');
        if (footer?.title_en && footer?.title_sc && footer?.title_sc && footer?.link_en && footer?.link_tc && footer?.link_sc) {
            const url=footer?.id?'update-footer-link':'create-footer-link';
            var getUser = getAuthUser();
            await axios(endpointUrl + url, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${getUser.token}`,
                },
                data: formData,
            })
                .then((res: any) => {
                    if (res?.data?.status == true) {
                        getFooterData({
                            search:'',
                            page_type:'footer_links'
                        }).then((res:any)=>{
                            console.log("res ",res)
                            setFooterData(res?.data?.footer_links);
                        })
                        toast(res?.data?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            type: "success",
                        });
                    } else {
                        setErrorPopup(true);
                        setMessage(res?.data?.message);
                    }
                    setIsSave(false);
                })
                .catch((err) => {
                    setIsSave(false);
                    setErrorPopup(true);
                    setMessage(err?.data?.message);
                    console.log('error ', err)
                });
            setShow(false);
        }

    }
    useEffect(()=>{
        if(footerData){
            setData({
                id:footerData?.id,
                title_en:footerData?.title_en,
                title_tc:footerData?.title_tc,
                title_sc:footerData?.title_sc,
                link_en:footerData?.link_en,
                link_tc:footerData?.link_tc,
                link_sc:footerData?.link_sc,
            })
        }
    },[footerData])
    return <div>
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
        />
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div>
                    <LabelAndTextbox label="Title (EN)" setValue={(value: any) => handle_change(value, 'title_en')} value={data?.title_en} />
                    {(!data?.title_en && isSave) ? <span className="text-12 text-red-500 font-normal">Title is required</span> : ''}
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Link (EN)" setValue={(value: any) => handle_change(value, 'link_en')} value={data?.link_en} />
                    {(!data?.link_en && isSave) ? <span className="text-12 text-red-500 font-normal">Link is required</span> : ''}
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div>
                    <LabelAndTextbox label="Title (TC)" setValue={(value: any) => handle_change(value, 'title_tc')} value={data?.title_tc} />
                    {(!data?.title_tc && isSave) ? <span className="text-12 text-red-500 font-normal">Title is required</span> : ''}
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Link (TC)" setValue={(value: any) => handle_change(value, 'link_tc')} value={data?.link_tc} />
                    {(!data?.link_tc && isSave) ? <span className="text-12 text-red-500 font-normal">Link is required</span> : ''}
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div>
                    <LabelAndTextbox label="Title (SC)" setValue={(value: any) => handle_change(value, 'title_sc')} value={data?.title_sc} />
                    {(!data?.title_sc && isSave) ? <span className="text-12 text-red-500 font-normal">Title is required</span> : ''}
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Link (SC)" setValue={(value: any) => handle_change(value, 'link_sc')} value={data?.link_sc} />
                    {(!data?.link_sc && isSave) ? <span className="text-12 text-red-500 font-normal">Link is required</span> : ''}
                </div>
            </div>
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave==true?'opacity-50 pointer-events-none':'',
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(false);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default FooterBottomForm; 