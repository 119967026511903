import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { withErrorBoundary } from "./layouts/RoutedErrorBoundary";
import LoginRoute from "./login";
import ForgetPasswordRoute from "./forgetpassword";
import EmailSentRoute from "./emailsent";
import SectionRoute from "./auth/components/SectionRoute";
import UsersRoute, { userRoutes } from "./userProfile";
import DashboardRoute from "./dashboard";
import BackendUserRoute, { backendUserRoutes } from "./backendUsers";
import jwt_decode from "jwt-decode";
import { employeeRoutes } from "./employee-list";
import AnnouncementRoute, { announcementRoutes } from "./announcements";
import { logout } from "./app/services/auth.service";
import ResetPasswordRoute from "./reset-password";
import PasswordResetSuccessRoute from "./password-reset-success";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import ActivityLogRoute from "./activity-log";
import { lazy, Suspense, useState,useEffect } from "react";
import BasicLayout from "./layouts/BasicLayout";
import AnnoucementListRoute from "./annoucementlist";
import Department from "./department/view/Department";
import {
  DashBoardPermis,
  MediaPermis,
  ActivityPermis,
  UserPemis,
  EmployeePermis,
  AnnouncPermissions,
  NoticeBoardPermissions,
  DepartmentPermis,
  CategoryPermis,
  FAQPermis,
  ManualPermis,
  CMSPermis,
  ReminderPermis,
} from "./types/permissions";
import NoticeBoardRoute from "./notice_board";
import EmployeeRoute from "./employees";
import NoticeBoardCategory from "./noticeboard-category/view/NoticeBoardCategory";
import FAQ from "./faq/view/FAQ";
import FAQType from "./faqType/view/FAQType";
import Manuals from "./manuals/view/Manuals";
import UsefulLine from "./usefulline/view/UsefulLine";
import CMS from "./cms/view/CMS";
import EventType from "./eventtype/view/EventType";
import EventRemainder from "./eventRemainder/view/EventRemainder";
import MobileLogo from "./mobileLogo/views/MobileLogo";
import Workgroup from "./workgroup/view/Workgroup";
import AnnoucementType from "./annoucement-type/view/AnnoucementType";
import HomePageRoute, { pagesList } from "./homepage";
import { newsRoutes } from "./news";
import HeaderRoute, { headerRoutes } from "./header";
import FooterRoute, { footerRoutes } from "./footer";
import CompanyProfilePageRoute, { companyProfileList } from "./company-profile";
import ServicePageRoute, { servicePagesList } from "./services";
import LatestNewsPageRoute, { latestNewsPagesList } from "./latest-news";
import MediaReportsPageRoute, { mediaReportsPagesList } from "./media-report";
import MediaResourcesPageRoute, {
  MediaResourcesPagesList,
} from "./media-resources";
import JobOpportunitiesPageRoute, {
  jobOpportunitiesPagesList,
} from "./job-opportunities";
import CareerPathPageRoute, { careerPathPagesList } from "./career-path-development";
import ContactUsPageRoute, { contactUsRoute } from "./contact-us";
import PrivacyRoute from "./contact-us/Privacy/PrivacyRoute";
import TermsRoute from "./contact-us/Privacy/TermsRoute";
import BaggageRoute from "./contact-us/Privacy/BaggageRoute";
import BaggageListRoute from "./contact-us/Privacy/BaggageListRoute";
import ResetPasswordSuccess from "./reset-password/components/ResetPasswordSuccess";
import SitemapRoute from "./contact-us/Privacy/SitemapRoute";

export interface userData {
  email: string;
  token: string;
  role: string;
}

export const checkToken = (route: any) => {
  var userData = localStorage.getItem("userData");
  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var isValid = false;
  if (userJson.token !== undefined && userJson.token !== null) {
    try {
      const decodedToken: { sub: string; role: string; exp: number } =
        jwt_decode(userJson.token);
      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
        route("/login");
      }
      isValid = true;
    } catch (e) {
      isValid = false;
    }
  }
  return isValid;
};

const MainRouter = () => {
  //const [getLoginData] = useGetLoginDataMutation();
  //var pathData = new URLSearchParams(window.location.search);

  //var userData = localStorage.getItem("userData");
  // useEffect(() => {
  //   var code = pathData.get("code");
  //   var state = pathData.get("state");
  //   if (code && state) {
  //     var data = {
  //       code: code,
  //       state: state,
  //     };
  //     getLoginData(data).then((res: any) => {
  //       if (res.data) {
  //         console.log("res ",res.data)
  //         // userData = localStorage.getItem("userData");
  //       }
  //     });
  //   }
  // }, []);
  const navigate = useNavigate();

  // var userJson: any = {};
  // if (userData) {
  //   userJson = JSON.parse(userData);
  //   userJson.token = userJson.accessToken;
  // }
  // var data = userJson;
  const [data, setData] = useState<any>({});

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        parsedData.token = parsedData.accessToken;
        setData(parsedData);
      } catch (error) {
        console.error("Invalid JSON:", error);
        setData({});
      }
    }
  }, []);
  var isValid = checkToken(navigate);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <LoginRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ForgetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ResetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/resetpassword-success"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <PasswordResetSuccessRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/reset-success"
          element={
            <ResetPasswordSuccess />
          }
        />
        <Route
          path="/emailsent"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <EmailSentRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/"
          element={
            <SectionRoute
              loginData={data}
              permissions={DashBoardPermis}
              isValid={isValid}
            >
              <DashboardRoute loginData={data} permissions={DashBoardPermis} />
            </SectionRoute>
          }
        />

        <Route
          path="/activity_log"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ActivityPermis}
            >
              <ActivityLogRoute loginData={data} permissions={ActivityPermis} />
            </SectionRoute>
          }
        />

        {/* Activity log */}



        {/* project board route */}



        <Route
          path="/dashboard"
          element={
            <SectionRoute
              loginData={data}
              permissions={UserPemis}
              isValid={isValid}
            >
              <DashboardRoute loginData={data} />
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/profile"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <UsersRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {userRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/employeeprofile/:id"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <UsersRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {userRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/users"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <BackendUserRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {backendUserRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        {/* employee  list */}
        <Route
          path="/employeeList"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <EmployeeRoute loginData={data} permissions={EmployeePermis} />
              </Suspense>
            </SectionRoute>
          }
        >
          {employeeRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/pages"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <HomePageRoute loginData={data} permissions={EmployeePermis} />
              </Suspense>
            </SectionRoute>
          }
        >
          {pagesList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/company-profile"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <CompanyProfilePageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {companyProfileList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/services"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ServicePageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {servicePagesList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/latest-news"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <LatestNewsPageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {latestNewsPagesList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/media-reports"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <MediaReportsPageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {mediaReportsPagesList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/media-resources"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <MediaResourcesPageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {MediaResourcesPagesList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/job-opportunities"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <JobOpportunitiesPageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {jobOpportunitiesPagesList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/cares"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <CareerPathPageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {careerPathPagesList.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/footer/baggage"
          element={
            <SectionRoute
              loginData={data}
              permissions={AnnouncPermissions}
              isValid={isValid}
            >
              <BaggageRoute loginData={data} permissions={AnnouncPermissions} />
            </SectionRoute>
          }
        />
        <Route
          path="/footer/baggage-list"
          element={
            <SectionRoute
              loginData={data}
              permissions={AnnouncPermissions}
              isValid={isValid}
            >
              <BaggageListRoute loginData={data} permissions={AnnouncPermissions} />
            </SectionRoute>
          }
        />
        <Route
          path="/footer/privacy"
          element={
            <SectionRoute
              loginData={data}
              permissions={AnnouncPermissions}
              isValid={isValid}
            >
              <PrivacyRoute loginData={data} permissions={AnnouncPermissions} />
            </SectionRoute>
          }
        />
        <Route
          path="/footer/terms"
          element={
            <SectionRoute
              loginData={data}
              permissions={AnnouncPermissions}
              isValid={isValid}
            >
              <TermsRoute loginData={data} permissions={AnnouncPermissions} />
            </SectionRoute>
          }
        />
        <Route
          path="/footer/sitemap"
          element={
            <SectionRoute
              loginData={data}
              permissions={AnnouncPermissions}
              isValid={isValid}
            >
              <SitemapRoute loginData={data} permissions={AnnouncPermissions} />
            </SectionRoute>
          }
        />
        <Route
          path="/contact-us"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ContactUsPageRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {contactUsRoute.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/announcement"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={AnnouncPermissions}
            >
              <AnnouncementRoute
                loginData={data}
                permissions={AnnouncPermissions}
              />
            </SectionRoute>
          }
        >
          {announcementRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/news"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={AnnouncPermissions}
            >
              <AnnouncementRoute
                loginData={data}
                permissions={AnnouncPermissions}
              />
            </SectionRoute>
          }
        >
          {newsRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/header"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={AnnouncPermissions}
            >
              <HeaderRoute loginData={data} permissions={AnnouncPermissions} />
            </SectionRoute>
          }
        >
          {headerRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/footer"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={AnnouncPermissions}
            >
              <FooterRoute loginData={data} permissions={AnnouncPermissions} />
            </SectionRoute>
          }
        >
          {footerRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/notice_board"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={NoticeBoardPermissions}
            >
              <NoticeBoardRoute
                loginData={data}
                permissions={NoticeBoardPermissions}
              />
            </SectionRoute>
          }
        ></Route>

        {/* end crm */}
        <Route
          path="/profile/announcement"
          element={
            <AnnoucementListRoute
              loginData={data}
              permissions={EmployeePermis}
            />
          }
        ></Route>

        <Route
          path="*"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <PageNotFound />
            </SectionRoute>
          }
        ></Route>
        {/* ----------------for department list-------------------- */}
        <Route
          path="/departments"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={DepartmentPermis}
            >
              <BasicLayout userData={data} permissions={DepartmentPermis}>
                <Department />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/workgroups"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={DepartmentPermis}
            >
              <BasicLayout userData={data} permissions={DepartmentPermis}>
                <Workgroup />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end department list-------------------- */}
        {/* ----------------for category list-------------------- */}
        <Route
          path="/category"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CategoryPermis}
            >
              <BasicLayout userData={data} permissions={CategoryPermis}>
                <NoticeBoardCategory />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/eventtype"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CategoryPermis}
            >
              <BasicLayout userData={data} permissions={CategoryPermis}>
                <EventType />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/announcement-type"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CategoryPermis}
            >
              <BasicLayout userData={data} permissions={CategoryPermis}>
                <AnnoucementType />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/event-reminder"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ReminderPermis}
            >
              <BasicLayout userData={data} permissions={ReminderPermis}>
                <EventRemainder />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/custom_logo"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CategoryPermis}
            >
              <BasicLayout userData={data} permissions={CategoryPermis}>
                <MobileLogo />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/faq"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={FAQPermis}
            >
              <BasicLayout userData={data} permissions={FAQPermis}>
                <FAQ />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/manuals"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ManualPermis}
            >
              <BasicLayout userData={data} permissions={ManualPermis}>
                <Manuals />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/usefullinks"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CMSPermis}
            >
              <BasicLayout userData={data} permissions={CMSPermis}>
                <UsefulLine />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/cms"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CMSPermis}
            >
              <BasicLayout userData={data} permissions={CMSPermis}>
                <CMS />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/faq_types"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={FAQPermis}
            >
              <BasicLayout userData={data} permissions={FAQPermis}>
                <FAQType />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
      </Routes>
    </>
  );
};

export default withErrorBoundary(MainRouter);
