import { FC, ReactNode } from "react";
interface IUserLayout {
  children: ReactNode;
}

const UserLayout: FC<IUserLayout> = () => {

  return (
    <>
     <div></div>
    </>
  );
};

export default UserLayout;
