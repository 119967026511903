
import { twJoin } from "tailwind-merge";
import { useState } from "react";
import { CategoryType } from "../../../latest-news/components/Category";
import GoodPeopleCategoryList from "./GoodPeopleCategoryList";

const GoodPeopleCategoryPage = () => {
  const [data, setData] = useState<CategoryType>({
    id: 0,
    title_en: "",
    title_sc: "",
    title_tc: "",
  });
  const [showList, setShowList] = useState<boolean>(true);

  return (
    <GoodPeopleCategoryList/>
  );
};
export default GoodPeopleCategoryPage;