import { useEffect, useRef, useState } from "react";
import SeoData from "../../components/SeoData/SeoData";
import { twJoin } from "tailwind-merge";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import BannerComponent from "../../components/SeoData/BannerComponent";
import CommonLanguage from "../../components/common/CommonLanguage";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, { IBannerData } from "../../components/SeoData/BannerSingle";
import { useGetCompanyDataQuery } from "../../app/services/company/company";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import CompanyProfileSection, { ICompanyProfile } from "../../company-profile/Components/CompanyProfileSection";
import { useGetTermsPageQuery } from "../../app/services/footer";
import UndoChanges from "../../components/UndoChanges/UndoChanges";
import { getAuthUser } from "../../app/services/dashboard";
export interface IDescription {
    description_en: string,
    description_tc: string,
    description_sc: string,
    content_en?: string,
    content_tc?: string,
    content_sc?: string,
}
const Terms = () => {
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const description_en_Ref = useRef();
    const description_tc_Ref = useRef();
    const description_sc_Ref = useRef();
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [banner, setBanner] = useState<IBannerData | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: ''
    });
    const [company_profile, setCompanyProfile] = useState<ICompanyProfile | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        description_en: '',
        description_tc: '',
        description_sc: '',
        image: undefined,
        image_url: '',
        subtitle_en: '',
        subtitle_tc: '',
        subtitle_sc: '',
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [data, setData] = useState<IDescription>({
        description_en: '',
        description_tc: '',
        description_sc: '',
    });
    const { data: companyData } = useGetTermsPageQuery();
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const change_data = (value: any, key: string) => {
        if (company_profile) {
            setData({ ...data, [key]: value })
        }
    }
    const set_initial_data = () => {
        if (companyData) {
            const overview = companyData?.terms_page;
            if (overview) {
                setMetaTitleEn(overview?.meta_title_en);
                setMetaTitleZh(overview?.meta_title_tc);
                setMetaTitleCn(overview?.meta_title_sc);
                setMetaDescriptionEn(overview?.meta_description_en);
                setMetaDescriptionZh(overview?.meta_description_tc);
                setMetaDescriptionCn(overview?.meta_description_sc);
                setMetaImage(overview?.meta_image);
                setBanner({
                    title_en: overview?.page_title_en,
                    title_tc: overview?.page_title_tc,
                    title_sc: overview?.page_title_sc,
                    image_url: overview?.banner_desktop_image,
                    image: undefined
                });
                setData({
                    description_en: overview?.content_en,
                    description_tc: overview?.content_tc,
                    description_sc: overview?.content_sc,
                })
            }
        }
    }
    useEffect(() => {
        set_initial_data();
    }, [companyData]);
    const handle_submit = async () => {
        const formData = new FormData();
        const meta_image: any = metaImageFile;
        const banner_image: any = banner?.image;
        const overview_image: any = company_profile?.image;
        formData.append('meta_title_en', metaTitle_en ?? '');
        formData.append('meta_title_tc', metaTitle_zh ?? '');
        formData.append('meta_title_sc', metaTitle_cn ?? '');
        formData.append('meta_description_en', metaDescription_en ?? '');
        formData.append('meta_description_tc', metaDescription_zh ?? '');
        formData.append('meta_description_sc', metaDescription_cn ?? '');
        formData.append('meta_image', meta_image);

        formData.append('content_en', data?.description_en ?? '');
        formData.append('content_tc', data?.description_tc ?? '');
        formData.append('content_sc', data?.description_sc ?? '');

        formData.append('page_title_en', banner?.title_en ?? '');
        formData.append('page_title_tc', banner?.title_tc ?? '');
        formData.append('page_title_sc', banner?.title_sc ?? '');
        formData.append('overview_image', overview_image);
        formData.append('banner_desktop_image', banner_image);
        var getUser = getAuthUser();
        await axios(endpointUrl + "update-terms", {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getUser.token}`,
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                } else {
                    setErrorPopup(true);
                    setMessage(res?.data?.message);
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(err?.data?.message);
            });
    }
    const undoChanges = () => {
        set_initial_data();
        setIsCancel(false);
    }
    return (
        <div
            className={twJoin(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />
            <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={() => undoChanges()} />
            <div className="text-left">
                <CommonLanguage
                    changeLanguage={changeLanguage}
                    activeLang={activeLang}
                />
                <div className="mt-5 mb-5 text-left">
                    <div
                        className={twJoin(
                            activeLang == "en"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndEditor
                            refId={description_en_Ref}
                            label="Description (EN)"
                            value={data?.description_en}
                            setValue={(value) => change_data(value, 'description_en')}
                        />
                    </div>
                    <div
                        className={twJoin(
                            activeLang == "tc"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndEditor
                            refId={description_tc_Ref}
                            label="Description (TC)"
                            value={data?.description_tc}
                            setValue={(value) => change_data(value, 'description_tc')}
                        />
                    </div>
                    <div
                        className={twJoin(
                            activeLang == "sc"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndEditor
                            refId={description_sc_Ref}
                            label="Description (SC)"
                            value={data?.description_sc}
                            setValue={(value) => change_data(value, 'description_sc')}
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col">
                <div className="text-left md:w-3/5 w-full md:mr-5 md:mb-0 mb-5">
                    <SeoData
                        showPageTitle={false}
                        setPageTitleEn={() => 1}
                        setPageTitleZh={() => 1}
                        setPageTitleCn={() => 1}
                        metatitle_en={metaTitle_en}
                        metatitle_zh={metaTitle_zh}
                        metatitle_cn={metaTitle_cn}
                        setMetaTitleEn={setMetaTitleEn}
                        setMetaTitleZh={setMetaTitleZh}
                        setMetaTitleCn={setMetaTitleCn}
                        metadescription_en={metaDescription_en}
                        metadescription_zh={metaDescription_zh}
                        metadescription_cn={metaDescription_cn}
                        setMetaDescriptionEn={setMetaDescriptionEn}
                        setMetaDescriptionZh={setMetaDescriptionZh}
                        setMetaDescriptionCn={setMetaDescriptionCn}
                        meta_image={metaImage}
                        setMetaImage={setMetaImage}
                        setMetaImageFile={setMetaImageFile}
                    />

                </div>
                <div className="md:w-2/5 w-full text-left">
                    <BannerSingle banner={banner} setBanner={setBanner} />
                </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
                <button
                    className={classNames(
                        isSave ? "opacity-50 pointer-events-none" : "",
                        "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                    )}
                    onClick={() => handle_submit()}
                >
                    <SaveIcon className="mr-2" /> Save
                </button>
                <button
                    className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                    onClick={() => {
                        setIsCancel(true);
                    }}
                >
                    <CancelIcon className="mr-2" color="#9E3039" />
                    Cancel
                </button>
            </div>
        </div>
    );
};
export default Terms;
