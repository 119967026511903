import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import UserLayout from "./views/UserLayout";
export const userRoutes = [
];
export const empRoutes = [
];
const UserRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
      <UserLayout>
        <Outlet />
      </UserLayout>
    </BasicLayout>
  );
};
export default UserRoute;
