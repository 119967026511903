import { useEffect, useRef, useState } from "react";
import SeoData from "../../components/SeoData/SeoData";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import CookiePopupData from "./CookiePopupData";
import TitleDescriptionBox from "./TitleDescriptionBox";
import FooterBanner, { IFooterBanner } from "./FooterBanner";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import { HomeData, IAirlineInfo, ICookie, IFooterCareer, IHomeNews, ITitleDescription, IWhyChoose, useGetHomeDataQuery } from "../../app/services/home/home";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import UndoChanges from "../../components/UndoChanges/UndoChanges";
import TitleDescriptionBoxForNews from "./TitleDescriptionBoxForNews";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import { getAuthUser } from "../../app/services/dashboard";

const HomePage = () => {
  const [pageTitle_en, setPageTitleEn] = useState<string>("");
  const [pageTitle_zh, setPageTitleZh] = useState<string>("");
  const [pageTitle_cn, setPageTitleCn] = useState<string>("");
  const [pageDescription_en, setPageDescriptionEn] = useState<string>("");
  const [pageDescription_zh, setPageDescriptionZh] = useState<string>("");
  const [pageDescription_cn, setPageDescriptionCn] = useState<string>("");
  const [metaTitle_en, setMetaTitleEn] = useState<string>("");
  const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
  const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
  const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
  const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
  const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [metaImage, setMetaImage] = useState<string>("");
  const [metaImageFile, setMetaImageFile] = useState<File | undefined>();  
  const [airlineData, setAirlineData] = useState<ITitleDescription>({
    sub_title_en: '',
    sub_title_tc: '',
    sub_title_sc: '',
    title_en: '',
    title_tc: '',
    title_sc: '',
    description_en: '',
    description_tc: '',
    description_sc: '',
    btn_text_en: '',
    btn_text_tc: '',
    btn_text_sc: '',
    btn_link_en: '',
    btn_link_tc: '',
    btn_link_sc: '',
  });
  const [cookiesData, setCookieData] = useState<ICookie>({
    cookie_btn_text_en: '',
    cookie_btn_text_tc: '',
    cookie_btn_text_sc: '',
    cookie_description_en: '',
    cookie_description_tc: '',
    cookie_description_sc: '',
  });
  const [whyChooseData, setWhyChooseData] = useState<ITitleDescription>({
    title_en: '',
    title_tc: '',
    title_sc: '',
    sub_title_en: '',
    sub_title_tc: '',
    sub_title_sc: '',
    description_en: '',
    description_tc: '',
    description_sc: '',
  });
  const [careerData, setCareerData] = useState<IFooterBanner>({
    description_en: '',
    description_zh: '',
    description_cn: '',
    button1_en: '',
    button1_zh: '',
    button1_cn: '',
    button2_en: '',
    button2_zh: '',
    button2_cn: '',
    button1_en_link: '',
    button1_zh_link: '',
    button1_cn_link: '',
    button2_en_link: '',
    button2_zh_link: '',
    button2_cn_link: '',
  });
  const [newsData, setNewsData] = useState<ITitleDescription>({
    sub_title_en: '',
    sub_title_tc: '',
    sub_title_sc: '',
    title_en: '',
    title_tc: '',
    title_sc: '',
    description_en: '',
    description_tc: '',
    description_sc: '',
    btn_text_en: '',
    btn_text_tc: '',
    btn_text_sc: '',
    btn_link_en: '',
    btn_link_tc: '',
    btn_link_sc: '',
    news_btn_link_en:'',
    news_btn_link_tc:'',
    news_btn_link_sc:'',
  });
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [activeLang, setActiveLang] = useState<string>("en");
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const [homeData, setHomeData] = useState<HomeData>();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setHomeData((prevState: any) => ({
      ...prevState,
      [target.name]: target.value
    }));
  };
  const { data: home } = useGetHomeDataQuery();
  const change_data=()=>{
    if (home) {
      setHomeData(home);
      setCareerData({
        description_en: home?.footer_career?.description_en,
        description_zh: home?.footer_career?.description_tc,
        description_cn: home?.footer_career?.description_sc,
        button1_en: home?.footer_career?.additional_data_en?.button1?.text,
        button1_zh: home?.footer_career?.additional_data_en?.button1?.text,
        button1_cn: home?.footer_career?.additional_data_en?.button1?.text,
        button1_en_link: home?.footer_career?.additional_data_en?.button1?.link,
        button1_zh_link: home?.footer_career?.additional_data_en?.button1?.link,
        button1_cn_link: home?.footer_career?.additional_data_en?.button1?.link,
        button2_en: home?.footer_career?.additional_data_en?.button2?.text,
        button2_zh: home?.footer_career?.additional_data_en?.button2?.text,
        button2_cn: home?.footer_career?.additional_data_en?.button2?.text,
        button2_en_link: home?.footer_career?.additional_data_en?.button2?.link,
        button2_zh_link: home?.footer_career?.additional_data_en?.button2?.link,
        button2_cn_link: home?.footer_career?.additional_data_en?.button2?.link,
      })
      setCookieData({
        cookie_btn_text_en: home?.cookie?.additional_data_en?.btn_text??'',
        cookie_btn_text_tc: home?.cookie?.additional_data_tc?.btn_text??'',
        cookie_btn_text_sc: home?.cookie?.additional_data_sc?.btn_text??'',
        cookie_description_en: home?.cookie?.description_en??'',
        cookie_description_tc: home?.cookie?.description_tc??'',
        cookie_description_sc: home?.cookie?.description_sc??''
      });
      setWhyChooseData({
        title_en: home?.choose_us?.title_en??'',
        title_tc: home?.choose_us?.title_tc??'',
        title_sc: home?.choose_us?.title_sc??'',
        description_en: home?.choose_us?.description_en??'',
        description_tc: home?.choose_us?.description_tc??'',
        description_sc: home?.choose_us?.description_sc??'',
        sub_title_en: home?.choose_us?.sub_title_en??'',
        sub_title_tc: home?.choose_us?.sub_title_tc??'',
        sub_title_sc: home?.choose_us?.sub_title_sc??'',
        
      });
      setAirlineData({
        title_en: home?.airline?.title_en,
        title_tc: home?.airline?.title_tc,
        title_sc: home?.airline?.title_sc,
        description_en: home?.airline?.description_en,
        description_tc: home?.airline?.description_tc,
        description_sc: home?.airline?.description_sc,
        sub_title_en: home?.airline?.sub_title_en,
        sub_title_tc: home?.airline?.sub_title_tc,
        sub_title_sc: home?.airline?.sub_title_sc,
        btn_link_en:home?.airline?.additional_data_en?.btn_link,
        btn_link_tc:home?.airline?.additional_data_tc?.btn_link,
        btn_link_sc:home?.airline?.additional_data_sc?.btn_link,
        btn_text_tc:home?.airline?.additional_data_tc?.btn_text,
        btn_text_en:home?.airline?.additional_data_en?.btn_text,
        btn_text_sc:home?.airline?.additional_data_sc?.btn_text,
      });
      setNewsData({
        title_en:home?.news_updates?.title_en,
        title_tc:home?.news_updates?.title_tc,
        title_sc:home?.news_updates?.title_sc,
        description_en:home?.news_updates?.description_en,
        description_tc:home?.news_updates?.description_tc,
        description_sc:home?.news_updates?.description_sc,
        sub_title_en:home?.news_updates?.sub_title_en,
        sub_title_tc:home?.news_updates?.sub_title_tc,
        sub_title_sc:home?.news_updates?.sub_title_sc,
        news_btn_link_en:home?.news_updates?.additional_data_en?.button1?.link,
        news_btn_link_tc:home?.news_updates?.additional_data_tc?.button1?.link,
        news_btn_link_sc:home?.news_updates?.additional_data_sc?.button1?.link,
        news_btn_text_en:home?.news_updates?.additional_data_en?.button1?.text,
        news_btn_text_tc:home?.news_updates?.additional_data_tc?.button1?.text,
        news_btn_text_sc:home?.news_updates?.additional_data_sc?.button1?.text,
      })
      setPageTitleEn(home?.home?.meta_title_en);
      setPageTitleZh(home?.home?.meta_title_tc);
      setPageTitleCn(home?.home?.meta_title_sc);
      setMetaDescriptionEn(home?.home?.meta_description_en);
      setMetaDescriptionZh(home?.home?.meta_description_tc);
      setMetaDescriptionCn(home?.home?.meta_description_sc);
      setMetaTitleEn(home?.home?.meta_title_en);
      setMetaTitleZh(home?.home?.meta_title_tc);
      setMetaTitleCn(home?.home?.meta_title_sc);
      setMetaImage(home?.home?.meta_image);
    }
  }
  useEffect(
    () => {
     change_data();
    },
    [home]
  );
  const handle_submit = async () => {
    const formData = new FormData();
    const image: any = metaImageFile;
    console.log("image ",image)
    formData.append('meta_title_en', metaTitle_en);
    formData.append('meta_title_tc', metaTitle_zh);
    formData.append('meta_title_sc', metaTitle_cn);
    formData.append('meta_description_en', metaDescription_en);
    formData.append('meta_description_tc', metaDescription_zh);
    formData.append('meta_description_sc', metaDescription_cn);
    formData.append('meta_image', image);
    formData.append('cookie_description_en', cookiesData.cookie_description_en);
    formData.append('cookie_description_tc', cookiesData.cookie_description_tc);
    formData.append('cookie_description_sc', cookiesData.cookie_description_sc);
    formData.append('cookie_btn_text_en', cookiesData.cookie_btn_text_en);
    formData.append('cookie_btn_text_tc', cookiesData.cookie_btn_text_tc);
    formData.append('cookie_btn_text_sc', cookiesData.cookie_btn_text_sc);
    formData.append('choose_title_en', whyChooseData.title_en);
    formData.append('choose_title_tc', whyChooseData.title_tc);
    formData.append('choose_title_sc', whyChooseData.title_sc);
    formData.append('choose_sub_title_en', whyChooseData.sub_title_en);
    formData.append('choose_sub_title_tc', whyChooseData.sub_title_tc);
    formData.append('choose_sub_title_sc', whyChooseData.sub_title_sc);
    formData.append('choose_description_en', whyChooseData.description_en);
    formData.append('choose_description_tc', whyChooseData.description_tc);
    formData.append('choose_description_sc', whyChooseData.description_sc);
    formData.append('airline_sub_title_en', airlineData.sub_title_en);
    formData.append('airline_sub_title_tc', airlineData.sub_title_tc);
    formData.append('airline_sub_title_sc', airlineData.sub_title_sc);
    formData.append('airline_title_en', airlineData.title_en);
    formData.append('airline_title_tc', airlineData.title_tc);
    formData.append('airline_title_sc', airlineData.title_sc);
    formData.append('airline_description_en', airlineData.description_en);
    formData.append('airline_description_tc', airlineData.description_tc);
    formData.append('airline_description_sc', airlineData.description_sc);
    formData.append('airline_btn_text_en', airlineData.btn_text_en ?? '');
    formData.append('airline_btn_text_tc', airlineData.btn_text_tc ?? '');
    formData.append('airline_btn_text_sc', airlineData.btn_text_sc ?? '');
    formData.append('airline_btn_link_en', airlineData.btn_link_en ?? '');
    formData.append('airline_btn_link_tc', airlineData.btn_link_tc ?? '');
    formData.append('airline_btn_link_sc', airlineData.btn_link_sc ?? '');
    formData.append('career_description_en', careerData.description_en ?? '');
    formData.append('career_description_tc', careerData.description_zh ?? '');
    formData.append('career_description_sc', careerData.description_cn ?? '');
    formData.append('career_btn_text_one_en', careerData.button1_en ?? '');
    formData.append('career_btn_text_one_tc', careerData.button1_zh ?? '');
    formData.append('career_btn_text_one_sc', careerData.button1_cn ?? '');
    formData.append('career_btn_link_one_en', careerData.button1_en_link ?? '');
    formData.append('career_btn_link_one_tc', careerData.button1_zh_link ?? '');
    formData.append('career_btn_link_one_sc', careerData.button1_cn_link ?? '');
    formData.append('career_btn_text_two_en', careerData.button2_en ?? '');
    formData.append('career_btn_text_two_tc', careerData.button2_zh ?? '');
    formData.append('career_btn_text_two_sc', careerData.button2_cn ?? '');
    formData.append('career_btn_link_two_en', careerData.button2_en_link ?? '');
    formData.append('career_btn_link_two_tc', careerData.button2_zh_link ?? '');
    formData.append('career_btn_link_two_sc', careerData.button2_cn_link ?? '');   
    formData.append('news_title_en', newsData?.title_en ?? '');    
    formData.append('news_title_tc', newsData?.title_tc ?? '');    
    formData.append('news_title_sc', newsData?.title_sc ?? '');    
    formData.append('news_sub_title_en', newsData?.sub_title_en ?? '');    
    formData.append('news_sub_title_tc', newsData?.sub_title_tc ?? '');    
    formData.append('news_sub_title_sc', newsData?.sub_title_sc ?? '');  
    formData.append('news_description_en', newsData?.description_en ?? '');    
    formData.append('news_description_tc', newsData?.description_tc ?? '');    
    formData.append('news_description_sc', newsData?.description_sc ?? '');  
    formData.append('news_btn_text_en', newsData?.news_btn_text_en ?? '');    
    formData.append('news_btn_text_tc', newsData?.news_btn_text_tc ?? '');    
    formData.append('news_btn_text_sc', newsData?.news_btn_text_sc ?? '');    
    formData.append('news_btn_link_en', newsData?.news_btn_link_en ?? '');    
    formData.append('news_btn_link_tc', newsData?.news_btn_link_tc ?? '');    
    formData.append('news_btn_link_sc', newsData?.news_btn_link_sc ?? '');  
    var getUser = getAuthUser();
    await axios(endpointUrl + "update-home", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {
          setErrorPopup(true);
          setMessage(res?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        console.log('error ', err);
        setErrorPopup(true);
        setMessage(err?.data?.message);
      });
  }
  const undoChanges=()=>{
    change_data();   
    setIsCancel(false);
  }
  return (
    <div
      className={twJoin(
        "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={()=>undoChanges()}/>
      <div className="flex">
        <div className="text-left w-full">          
          <div className="home-page-input-list">
            <h2 className="text-lg text-left font-bold mb-5 mt-5 text-black">
              Home Page Data
            </h2>
            <div className="mt-5">
              <CommonLanguage
                changeLanguage={changeLanguage}
                activeLang={activeLang}
              />
              <CookiePopupData
                activeLang={activeLang}
                initialData={cookiesData}
                setCookieData={setCookieData}
              />
              <TitleDescriptionBox
                title="Why Choose Us"
                activeLang={activeLang}
                initialData={whyChooseData}
                setValue={setWhyChooseData}
                isShowButtonText={false}
                isShowButtonLink={false}
              />
              <TitleDescriptionBox
                title="Customer Airline"
                activeLang={activeLang}
                initialData={airlineData}
                setValue={setAirlineData}
              />
              <TitleDescriptionBoxForNews
                title="News & Annoucements"
                activeLang={activeLang}
                initialData={newsData}
                setValue={setNewsData}
                isCancel={isCancel}
              />
              <FooterBanner
                activeLang={activeLang}
                initialData={careerData}
                setValue={setCareerData}
              />
            </div>
          </div>
          <div>
          <SeoData
            showPageTitle={false}
            setPageTitleEn={setPageTitleEn}
            setPageTitleZh={setPageTitleZh}
            setPageTitleCn={setPageTitleCn}
            metatitle_en={metaTitle_en}
            metatitle_zh={metaTitle_zh}
            metatitle_cn={metaTitle_cn}
            setMetaTitleEn={setMetaTitleEn}
            setMetaTitleZh={setMetaTitleZh}
            setMetaTitleCn={setMetaTitleCn}
            metadescription_en={metaDescription_en}
            metadescription_zh={metaDescription_zh}
            metadescription_cn={metaDescription_cn}
            setMetaDescriptionEn={setMetaDescriptionEn}
            setMetaDescriptionZh={setMetaDescriptionZh}
            setMetaDescriptionCn={setMetaDescriptionCn}
            meta_image={metaImage}
            setMetaImage={setMetaImage}
            setMetaImageFile={setMetaImageFile}
          />
          </div>
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            // saving ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => setIsCancel(true)}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default HomePage;
