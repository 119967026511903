import { useEffect, useRef, useState } from "react";
import SeoData from "../../components/SeoData/SeoData";
import { twJoin } from "tailwind-merge";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import BannerComponent from "../../components/SeoData/BannerComponent";
import CommonLanguage from "../../components/common/CommonLanguage";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, { IBannerData } from "../../components/SeoData/BannerSingle";
import { useGetCompanyDataQuery } from "../../app/services/company/company";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import CompanyProfileSection, { ICompanyProfile } from "../../company-profile/Components/CompanyProfileSection";
import { useFetch_baggage_pageQuery } from "../../app/services/footer";
import UndoChanges from "../../components/UndoChanges/UndoChanges";
import { getAuthUser } from "../../app/services/dashboard";
export interface IBaggagePageData {
    baggage_title_en: string,
    baggage_title_tc: string,
    baggage_title_sc: string,
    baggage_sub_title_en: string,
    baggage_sub_title_tc: string,
    baggage_sub_title_sc: string,
    baggage_description_en: string,
    baggage_description_tc: string,
    baggage_description_sc: string,
    baggage_btn_text_en: string,
    baggage_btn_text_tc: string,
    baggage_btn_text_sc: string,
    baggage_btn_link_en: string,
    baggage_btn_link_tc: string,
    baggage_btn_link_sc: string,
    world_tracer_title_en: string,
    world_tracer_title_tc: string,
    world_tracer_title_sc: string,
    world_tracer_description_en: string,
    world_tracer_description_tc: string,
    world_tracer_description_sc: string,
    baggage_image: File | undefined,
    baggage_image_url?: string,
}
const BaggagePage = () => {
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const description_en_Ref = useRef();
    const description_tc_Ref = useRef();
    const description_sc_Ref = useRef();
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [banner, setBanner] = useState<IBannerData | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: ''
    });
    const [company_profile, setCompanyProfile] = useState<IBaggagePageData | undefined>({
        baggage_title_en: '',
        baggage_title_tc: '',
        baggage_title_sc: '',
        baggage_sub_title_en: '',
        baggage_sub_title_tc: '',
        baggage_sub_title_sc: '',
        baggage_description_en: '',
        baggage_description_tc: '',
        baggage_description_sc: '',
        baggage_btn_text_en: '',
        baggage_btn_text_tc: '',
        baggage_btn_text_sc: '',
        baggage_btn_link_en: '',
        baggage_btn_link_tc: '',
        baggage_btn_link_sc: '',
        world_tracer_title_en: '',
        world_tracer_title_tc: '',
        world_tracer_title_sc: '',
        world_tracer_description_en: '',
        world_tracer_description_tc: '',
        world_tracer_description_sc: '',
        baggage_image: undefined
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [data, setData] = useState<IBaggagePageData>({
        baggage_title_en: '',
        baggage_title_tc: '',
        baggage_title_sc: '',
        baggage_sub_title_en: '',
        baggage_sub_title_tc: '',
        baggage_sub_title_sc: '',
        baggage_description_en: '',
        baggage_description_tc: '',
        baggage_description_sc: '',
        baggage_btn_text_en: '',
        baggage_btn_text_tc: '',
        baggage_btn_text_sc: '',
        baggage_btn_link_en: '',
        baggage_btn_link_tc: '',
        baggage_btn_link_sc: '',
        world_tracer_title_en: '',
        world_tracer_title_tc: '',
        world_tracer_title_sc: '',
        world_tracer_description_en: '',
        world_tracer_description_tc: '',
        world_tracer_description_sc: '',
        baggage_image: undefined
    });
    const { data: companyData } = useFetch_baggage_pageQuery();
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const change_data = (value: any, key: string) => {
        if (company_profile) {
            if (key == "baggage_image") {
                setData((prev) => {
                    return {
                        ...prev,
                        baggage_image: value,
                        baggage_image_url: value ? URL.createObjectURL(value) : ''
                    }
                });
                return;
            }
            setData({ ...data, [key]: value })
        }
    }
    const set_initial_data = () => {
        if (companyData) {
            const page = companyData?.baggage_tracing;
            const baggage = companyData?.located_baggage;
            const world = companyData?.world_tracer;
            setData({
                baggage_title_en: baggage?.title_en,
                baggage_title_tc: baggage?.title_tc,
                baggage_title_sc: baggage?.title_sc,
                baggage_sub_title_en: baggage?.sub_title_en,
                baggage_sub_title_tc: baggage?.sub_title_tc,
                baggage_sub_title_sc: baggage?.sub_title_sc,
                baggage_description_en: baggage?.description_en,
                baggage_description_tc: baggage?.description_tc,
                baggage_description_sc: baggage?.description_sc,
                baggage_btn_text_en: baggage?.additional_data_en?.button1?.text,
                baggage_btn_text_tc: baggage?.additional_data_tc?.button1?.text,
                baggage_btn_text_sc: baggage?.additional_data_sc?.button1?.text,
                baggage_btn_link_en: baggage?.additional_data_en?.button1?.link,
                baggage_btn_link_tc: baggage?.additional_data_tc?.button1?.link,
                baggage_btn_link_sc: baggage?.additional_data_sc?.button1?.link,
                world_tracer_title_en: world?.title_en,
                world_tracer_title_tc: world?.title_tc,
                world_tracer_title_sc: world?.title_sc,
                world_tracer_description_en: world?.description_en,
                world_tracer_description_tc: world?.description_tc,
                world_tracer_description_sc: world?.description_sc,
                baggage_image: undefined,
                baggage_image_url: baggage?.image
            })
            if (page) {
                setMetaTitleEn(page?.meta_title_en);
                setMetaTitleZh(page?.meta_title_tc);
                setMetaTitleCn(page?.meta_title_sc);
                setMetaDescriptionEn(page?.meta_description_en);
                setMetaDescriptionZh(page?.meta_description_tc);
                setMetaDescriptionCn(page?.meta_description_sc);
                setMetaImage(page?.meta_image);
                setBanner({
                    title_en: page?.title_en,
                    title_tc: page?.title_tc,
                    title_sc: page?.title_sc,
                    image_url: page?.banner_desktop_image,
                    image: undefined
                });
            }
        }
    }
    useEffect(() => {
        set_initial_data();
    }, [companyData]);
    const handle_submit = async () => {
        const formData = new FormData();
        const meta_image: any = metaImageFile;
        const banner_image: any = banner?.image;
        const overview_image: any = data?.baggage_image;
        console.log("data ", data)
        formData.append('meta_title_en', metaTitle_en ?? '');
        formData.append('meta_title_tc', metaTitle_zh ?? '');
        formData.append('meta_title_sc', metaTitle_cn ?? '');
        formData.append('meta_description_en', metaDescription_en ?? '');
        formData.append('meta_description_tc', metaDescription_zh ?? '');
        formData.append('meta_description_sc', metaDescription_cn ?? '');
        formData.append('meta_image', meta_image);

        formData.append('baggage_title_en', data?.baggage_title_en ?? '');
        formData.append('baggage_title_tc', data?.baggage_title_tc ?? '');
        formData.append('baggage_title_sc', data?.baggage_title_sc ?? '');
        formData.append('baggage_sub_title_en', data?.baggage_sub_title_en ?? '');
        formData.append('baggage_sub_title_tc', data?.baggage_sub_title_tc ?? '');
        formData.append('baggage_sub_title_sc', data?.baggage_sub_title_sc ?? '');
        formData.append('baggage_description_en', data?.baggage_description_en ?? '');
        formData.append('baggage_description_tc', data?.baggage_description_tc ?? '');
        formData.append('baggage_description_sc', data?.baggage_description_sc ?? '');
        formData.append('baggage_btn_text_en', data?.baggage_btn_text_en ?? '');
        formData.append('baggage_btn_text_tc', data?.baggage_btn_text_tc ?? '');
        formData.append('baggage_btn_text_sc', data?.baggage_btn_text_sc ?? '');
        formData.append('baggage_btn_link_en', data?.baggage_btn_link_en ?? '');
        formData.append('baggage_btn_link_tc', data?.baggage_btn_link_tc ?? '');
        formData.append('baggage_btn_link_sc', data?.baggage_btn_link_sc ?? '');

        formData.append('world_tracer_title_en', data?.world_tracer_title_en ?? '');
        formData.append('world_tracer_title_tc', data?.world_tracer_title_tc ?? '');
        formData.append('world_tracer_title_sc', data?.world_tracer_title_sc ?? '');
        formData.append('world_tracer_description_en', data?.world_tracer_description_en ?? '');
        formData.append('world_tracer_description_tc', data?.world_tracer_description_tc ?? '');
        formData.append('world_tracer_description_sc', data?.world_tracer_description_sc ?? '');

        formData.append('page_title_en', banner?.title_en ?? '');
        formData.append('page_title_tc', banner?.title_tc ?? '');
        formData.append('page_title_sc', banner?.title_sc ?? '');
        formData.append('baggage_image', overview_image);
        formData.append('banner_desktop_image', banner_image);
        var getUser = getAuthUser();
        await axios(endpointUrl + "update-baggage-tracing-page", {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getUser.token}`,
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                } else {
                    setErrorPopup(true);
                    setMessage(res?.data?.message);
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(err?.data?.message);
            });
    }
    const undoChanges = () => {
        set_initial_data();
        setIsCancel(false);
    }
    return (
        <div
            className={twJoin(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />
            <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={() => undoChanges()} />
            <div className="text-left">
                <h2 className="text-lg text-left font-bold mb-5 mt-5 text-black">
                    Overview
                </h2>
                <div className="mt-5">
                    <CommonLanguage
                        changeLanguage={changeLanguage}
                        activeLang={activeLang}
                    />
                    <div className="mt-5">
                        <div
                            className={twJoin(
                                activeLang == "en"
                                    ? ""
                                    : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                            )}
                        >
                            <div>
                                <LabelAndTextbox label="Title (EN)" value={data?.baggage_title_en} setValue={(value) => change_data(value, 'baggage_title_en')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Subtitle (EN)" value={data?.baggage_sub_title_en} setValue={(value) => change_data(value, 'baggage_sub_title_en')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Button Text (EN)" value={data?.baggage_btn_text_en} setValue={(value) => change_data(value, 'baggage_btn_text_en')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Button Link (EN)" value={data?.baggage_btn_link_en} setValue={(value) => change_data(value, 'baggage_btn_link_en')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndEditor refId={description_en_Ref} label="Description (EN)" value={data?.baggage_description_en} setValue={(value) => change_data(value, 'baggage_description_en')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="World Tracer Title (EN)" value={data?.world_tracer_title_en} setValue={(value) => change_data(value, 'world_tracer_title_en')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndEditor refId={description_en_Ref} label="World Tracer Description (EN)" value={data?.world_tracer_description_en} setValue={(value) => change_data(value, 'world_tracer_description_en')} />
                            </div>
                        </div>
                        <div
                            className={twJoin(
                                activeLang == "tc"
                                    ? ""
                                    : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                            )}
                        >
                            <div>
                                <LabelAndTextbox label="Title (TC)" value={data?.baggage_title_tc} setValue={(value) => change_data(value, 'baggage_title_tc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Subtitle (TC)" value={data?.baggage_sub_title_tc} setValue={(value) => change_data(value, 'baggage_sub_title_tc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Button Text (TC)" value={data?.baggage_btn_text_tc} setValue={(value) => change_data(value, 'baggage_btn_text_tc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Button Link (TC)" value={data?.baggage_btn_link_tc} setValue={(value) => change_data(value, 'baggage_btn_link_tc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndEditor refId={description_en_Ref} label="Description (TC)" value={data?.baggage_description_tc} setValue={(value) => change_data(value, 'baggage_description_tc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="World Tracer Title (TC)" value={data?.world_tracer_title_tc} setValue={(value) => change_data(value, 'world_tracer_title_tc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndEditor refId={description_en_Ref} label="World Tracer Description (TC)" value={data?.world_tracer_description_tc} setValue={(value) => change_data(value, 'world_tracer_description_tc')} />
                            </div>
                        </div>
                        <div
                            className={twJoin(
                                activeLang == "sc"
                                    ? ""
                                    : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                            )}
                        >
                            <div>
                                <LabelAndTextbox label="Title (SC)" value={data?.baggage_title_sc} setValue={(value) => change_data(value, 'baggage_title_sc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Subtitle (SC)" value={data?.baggage_sub_title_sc} setValue={(value) => change_data(value, 'baggage_sub_title_sc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Button Text (SC)" value={data?.baggage_btn_text_sc} setValue={(value) => change_data(value, 'baggage_btn_text_sc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="Button Link (SC)" value={data?.baggage_btn_link_sc} setValue={(value) => change_data(value, 'baggage_btn_link_sc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndEditor refId={description_en_Ref} label="Description (SC)" value={data?.baggage_description_sc} setValue={(value) => change_data(value, 'baggage_description_sc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndTextbox label="World Tracer Title (SC)" value={data?.world_tracer_title_sc} setValue={(value) => change_data(value, 'world_tracer_title_sc')} />
                            </div>
                            <div className="mt-5">
                                <LabelAndEditor refId={description_en_Ref} label="World Tracer Description (SC)" value={data?.world_tracer_description_sc} setValue={(value) => change_data(value, 'world_tracer_description_sc')} />
                            </div>
                        </div>
                        <LabelAndImage label="Image" value={data?.baggage_image_url} setValue={(value) => change_data(value, 'baggage_image')} setFile={(value) => change_data(value, 'baggage_image')} />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col">
                <div className="text-left md:w-3/5 w-full md:mr-5 md:mb-0 mb-5">
                    <SeoData
                        showPageTitle={false}
                        setPageTitleEn={() => 1}
                        setPageTitleZh={() => 1}
                        setPageTitleCn={() => 1}
                        metatitle_en={metaTitle_en}
                        metatitle_zh={metaTitle_zh}
                        metatitle_cn={metaTitle_cn}
                        setMetaTitleEn={setMetaTitleEn}
                        setMetaTitleZh={setMetaTitleZh}
                        setMetaTitleCn={setMetaTitleCn}
                        metadescription_en={metaDescription_en}
                        metadescription_zh={metaDescription_zh}
                        metadescription_cn={metaDescription_cn}
                        setMetaDescriptionEn={setMetaDescriptionEn}
                        setMetaDescriptionZh={setMetaDescriptionZh}
                        setMetaDescriptionCn={setMetaDescriptionCn}
                        meta_image={metaImage}
                        setMetaImage={setMetaImage}
                        setMetaImageFile={setMetaImageFile}
                    />

                </div>
                <div className="md:w-2/5 w-full text-left">
                    <BannerSingle banner={banner} setBanner={setBanner} />
                </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
                <button
                    className={classNames(
                        isSave ? "opacity-50 pointer-events-none" : "",
                        "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                    )}
                    onClick={() => handle_submit()}
                >
                    <SaveIcon className="mr-2" /> Save
                </button>
                <button
                    className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                    onClick={() => {
                        setIsCancel(true);
                    }}
                >
                    <CancelIcon className="mr-2" color="#9E3039" />
                    Cancel
                </button>
            </div>
        </div>
    );
};
export default BaggagePage;
