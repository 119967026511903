import { FC, useEffect, useMemo, useState } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew } from "../../app/services/employee";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSpring, animated } from "react-spring";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import UsefulLineTable from "../../components/UsefulLineTable/UsefulLineTable";
import CreatePopupNameLink from "../../components/ProjectStatusPopup/CreatePopupNameLink";
import {
  useGetUsefulLinesQuery,
  useUpdateUsefulLineMutation,
  useCreateUsefulLineMutation,
  useDeleteUsefulLineMutation,
} from "../../app/services/usefulline";
import { NumberDropdownList } from "../../components/common/DropdownList";

interface IOT {}

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

// const defaultFields = ["name", "order", "actions"];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const UsefulLine: FC<IOT> = () => {
  const [editedData, setEditData] = useState<any>("");
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [message, setMessage] = useState<any>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  var disabledData = checkedIsNew(data ? data : []);
  const { data: usefulLines } = useGetUsefulLinesQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    // sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
  });
  const [deleteUsefulLine] = useDeleteUsefulLineMutation();
  const [saveUsefulLineData] = useCreateUsefulLineMutation();
  const [updateUsefulLineData] = useUpdateUsefulLineMutation();

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changePage = (pageNo: number) => {};

  useEffect(() => {
    if (usefulLines) {
      setData(usefulLines?.usefulLinkTableData?.data);
      setItemCount(usefulLines?.usefulLinkTableData?.total);
    }
  }, [usefulLines]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pagination.pageSize, globalSearch]);

  const createUsefulLinks = (data: any) => {
    const saveData = {
      name_en: data?.name_en,
      name_tc: data?.name_tc,
      name_sc: data?.name_sc,
      link_en: data?.link_en,
      link_tc: data?.link_tc,
      link_sc: data?.link_sc,
      description_en: data?.description_en,
      description_tc: data?.description_tc,
      description_sc: data?.description_sc,
      type: data.linktype,
      id: 0,
    };
    saveUsefulLineData(saveData)
      .unwrap()
      .then((payload: any) => {
        setEditData("");
        toast("Successfully Saved.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setIsSubmit(false);
        setUploading(false);
        setData((prev: any) => [payload.usefullink, ...prev]);
        setIsOpenPopup(false);
      })
      .catch((error: any) => {
        setError(error?.data?.message);
        setIsSubmit(false);
        setUploading(false);
        setIsOpenPopup(false);
      });
  };
  const updateUsefulLinks = (data: any) => {
    const saveData = {
      name_en: data?.name_en,
      name_tc: data?.name_tc,
      name_sc: data?.name_sc,
      link_en: data?.link_en,
      link_tc: data?.link_tc,
      link_sc: data?.link_sc,
      description_en: data?.description_en,
      description_tc: data?.description_tc,
      description_sc: data?.description_sc,
      type: data.linktype,
      id: data?.id,
    };
    updateUsefulLineData(saveData)
      .unwrap()
      .then((payload: any) => {
        if (payload.status == true) {
          setEditData("");
          toast("Successfully Saved.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
          setIsSubmit(false);
          setUploading(false);
          setData((prev) =>
            prev.map((item) => {
              if (item.id === data.id) {
                return payload.usefulLink;
              } else {
                return item;
              }
            })
          );

          setError("");
          setIsOpenPopup(false);
        }
      })
      .catch((error: any) => {
        setError(error?.data?.message);
        setIsSubmit(false);
        setUploading(false);
        setIsOpenPopup(false);
      });
  };
  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    id="message"
                    autoComplete="off"
                    name="search-off"
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className={classNames("mr-2")} />
                </div>
              )}
            </DebounceSearchInput>
          </div>

          <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            <div className="">
              <Button
                disabled={disabledData?.disabled}
                label={<span className="flex items-center">+ Add New</span>}
                onClick={() => {
                  setIsSuccess(false);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                  setEditData(null);
                  setIsOpenPopup(true);
                }}
                type="button"
                variant="primary"
                customClass={
                  disabledData?.disabledClass +
                  " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                }
                size="base"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
          <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
            <p className="base-label text-12 xl:text-14">DISPLAY</p>
            <NumberDropdownList
              state={pagination.pageSize}
              setState={(value) =>
                setPagination((prev) => ({
                  ...prev,
                  pageSize: value as number,
                }))
              }
              dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                let display = (index + 1) * 20;
                return {
                  value: display,
                  label: String(display),
                };
              })}
              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
              textAlign="text-left pl-3"
              paddingClass="py-[2px]"
              placeholder="20"
            />
          </div>
        </div>
        <animated.div style={props}>
          <UsefulLineTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            sorting={sorting}
            setSorting={setSorting}
            deleteCategory={deleteUsefulLine}
            setEditData={setEditData}
            setIsOpenPopup={setIsOpenPopup}
          />
        </animated.div>
        <ToastContainer className={"text-12 font-poppins font-normal"} />
      </div>
      <CreatePopupNameLink
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        editedData={editedData}
        setEditData={setEditData}
        savePopupData={createUsefulLinks}
        updatePopupData={updateUsefulLinks}
        title="Useful Line"
        isSubmit={isSubmit}
        setIsSubmit={setIsSubmit}
        uploading={uploading}
        setUploading={setUploading}
      />
      <CommonErrorPopup
        show={error != "" ? true : false}
        setShow={() => setError("")}
        message={error}
      />
    </div>
  );
};
export default UsefulLine;
