import { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, {
  IBannerData,
} from "../../components/SeoData/BannerSingle";
import SeoData from "../../components/SeoData/SeoData";
import OverviewSection, { IOverview } from "../components/Overview";
import { useGetJobsPageQuery } from "../../app/services/job/job";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../axios";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import UndoChanges from "../../components/UndoChanges/UndoChanges";
import { getAuthUser } from "../../app/services/dashboard";

const OverViewPage = () => {
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [metaTitle_en, setMetaTitleEn] = useState<string>("");
  const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
  const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
  const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
  const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
  const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
  const [metaImage, setMetaImage] = useState<string>("");
  const [overview, setOverview] = useState<IOverview>({
    title_en: '',
    title_tc: '',
    title_sc: '',
    description_en: '',
    description_tc: '',
    description_sc: '',
    competitive_compensation_icon_file: undefined,
    competitive_compensation_icon: '',
    competitive_compensation_title_en: '',
    competitive_compensation_title_tc: '',
    competitive_compensation_title_sc: '',
    competitive_compensation_text_en: '',
    competitive_compensation_text_tc: '',
    competitive_compensation_text_sc: '',
    amazing_coworkers_icon_file: undefined,
    amazing_coworkers_icon: '',
    amazing_coworkers_title_en: '',
    amazing_coworkers_title_tc: '',
    amazing_coworkers_title_sc: '',
    amazing_coworkers_description_en: '',
    amazing_coworkers_description_tc: '',
    amazing_coworkers_description_sc: '',
    contact_and_recruitment_title_en: '',
    contact_and_recruitment_title_tc: '',
    contact_and_recruitment_title_sc: '',
    contact_and_recruitment_email: '',
    contact_and_recruitment_phone: '',
    contact_and_recruitment_address: '',
    image_url: '',
    image: undefined,
  });

  const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
  const [banner, setBanner] = useState<IBannerData | undefined>();
  const [activeLang, setActiveLang] = useState<string>("en");
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isSave, setIsSave] = useState<boolean>(false);
  const { data: jobPage } = useGetJobsPageQuery('job-opportunities');
  const [homeData, setHomeData] = useState<any>();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setHomeData((prevState: any) => ({
      ...prevState,
      [target.name]: target.value
    }));
  };
  const set_initial_data = () => {
    if (jobPage) {
      const overview = jobPage?.page;
      const opportunities_info = jobPage?.opportunities_info;
      const contact_recruitment_info = jobPage?.contact_recruitment_info;
      const competitive_compensation_info = jobPage?.competitive_compensation_info;
      const amazing_coworkers_info = jobPage?.amazing_coworkers_info;
      if (overview) {
        setMetaTitleEn(overview?.meta_title_en);
        setMetaTitleZh(overview?.meta_title_tc);
        setMetaTitleCn(overview?.meta_title_sc);
        setMetaDescriptionEn(overview?.meta_description_en);
        setMetaDescriptionZh(overview?.meta_description_tc);
        setMetaDescriptionCn(overview?.meta_description_sc);
        setMetaImage(overview?.meta_image);
        setBanner({
          title_en: overview?.title_en,
          title_tc: overview?.title_tc,
          title_sc: overview?.title_sc,
          image_url: overview?.banner_desktop_image,
          image: undefined
        });
      }
      setOverview({
        title_en: opportunities_info?.title_en ?? '',
        title_tc: opportunities_info?.title_tc ?? '',
        title_sc: opportunities_info?.title_sc ?? '',
        description_en: opportunities_info?.description_en ?? '',
        description_tc: opportunities_info?.description_tc ?? '',
        description_sc: opportunities_info?.description_sc ?? '',
        competitive_compensation_icon_file: undefined,
        competitive_compensation_icon: competitive_compensation_info?.image,
        competitive_compensation_title_en: competitive_compensation_info?.title_en,
        competitive_compensation_title_tc: competitive_compensation_info?.title_tc,
        competitive_compensation_title_sc: competitive_compensation_info?.title_sc,
        competitive_compensation_text_en: competitive_compensation_info?.description_en,
        competitive_compensation_text_tc: competitive_compensation_info?.description_tc,
        competitive_compensation_text_sc: competitive_compensation_info?.description_sc,
        amazing_coworkers_icon: amazing_coworkers_info?.image,
        amazing_coworkers_icon_file: undefined,
        amazing_coworkers_title_en: amazing_coworkers_info?.title_en,
        amazing_coworkers_title_tc: amazing_coworkers_info?.title_tc,
        amazing_coworkers_title_sc: amazing_coworkers_info?.title_sc,
        amazing_coworkers_description_en: amazing_coworkers_info?.description_en,
        amazing_coworkers_description_tc: amazing_coworkers_info?.description_tc,
        amazing_coworkers_description_sc: amazing_coworkers_info?.description_sc,
        contact_and_recruitment_title_en: contact_recruitment_info?.title_en,
        contact_and_recruitment_title_tc: contact_recruitment_info?.title_tc,
        contact_and_recruitment_title_sc: contact_recruitment_info?.title_sc,
        contact_and_recruitment_email: contact_recruitment_info?.additional_data_en?.contact_recruitment_email,
        contact_and_recruitment_phone: contact_recruitment_info?.additional_data_en?.contact_recruitment_phone,
        contact_and_recruitment_address: contact_recruitment_info?.additional_data_en?.contact_recruitment_address,
        image: undefined,
        image_url: opportunities_info?.image,
      })
    }
  }
  useEffect(() => {
    set_initial_data();
  }, [jobPage]);
  const handle_submit = async () => {
    const formData = new FormData();
    const meta_image: any = metaImageFile;
    formData.append('meta_title_en', metaTitle_en ?? '');
    formData.append('meta_title_tc', metaTitle_zh ?? '');
    formData.append('meta_title_sc', metaTitle_cn ?? '');
    formData.append('meta_description_en', metaDescription_en ?? '');
    formData.append('meta_description_tc', metaDescription_zh ?? '');
    formData.append('meta_description_sc', metaDescription_cn ?? '');
    if (meta_image)
      formData.append('meta_image', meta_image);

    formData.append('page_title_en', banner?.title_en ?? '');
    formData.append('page_title_tc', banner?.title_tc ?? '');
    formData.append('page_title_sc', banner?.title_sc ?? '');
    if (banner?.image)
      formData.append('banner_desktop_image', banner?.image ?? '');

    formData.append('page_type', 'job-opportunities');
    formData.append('opportunities_title_en', overview?.title_en ?? '');
    formData.append('opportunities_title_tc', overview?.title_tc ?? '');
    formData.append('opportunities_title_sc', overview?.title_sc ?? '');
    formData.append('opportunities_description_en', overview?.description_en ?? '');
    formData.append('opportunities_description_tc', overview?.description_tc ?? '');
    formData.append('opportunities_description_sc', overview?.description_sc ?? '');
    formData.append('competitive_title_en', overview?.competitive_compensation_title_en ?? '');
    formData.append('competitive_title_tc', overview?.competitive_compensation_title_tc ?? '');
    formData.append('competitive_title_sc', overview?.competitive_compensation_title_sc ?? '');
    formData.append('competitive_description_en', overview?.competitive_compensation_text_en ?? '');
    formData.append('competitive_description_tc', overview?.competitive_compensation_text_tc ?? '');
    formData.append('competitive_description_sc', overview?.competitive_compensation_text_sc ?? '');

    formData.append('contact_recruitment_title_en', overview?.contact_and_recruitment_title_en ?? '');
    formData.append('contact_recruitment_title_tc', overview?.contact_and_recruitment_title_tc ?? '');
    formData.append('contact_recruitment_title_sc', overview?.contact_and_recruitment_title_sc ?? '');
    formData.append('amazing_coworkers_title_en', overview?.amazing_coworkers_title_en ?? '');
    formData.append('amazing_coworkers_title_tc', overview?.amazing_coworkers_title_tc ?? '');
    formData.append('amazing_coworkers_title_sc', overview?.amazing_coworkers_title_sc ?? '');
    formData.append('amazing_coworkers_description_en', overview?.amazing_coworkers_description_en ?? '');
    formData.append('amazing_coworkers_description_tc', overview?.amazing_coworkers_description_tc ?? '');
    formData.append('amazing_coworkers_description_sc', overview?.amazing_coworkers_description_sc ?? '');
    formData.append('contact_recruitment_email', overview?.contact_and_recruitment_email ?? '');
    formData.append('contact_recruitment_phone', overview?.contact_and_recruitment_email ?? '');
    formData.append('contact_recruitment_address', overview?.contact_and_recruitment_address ?? '');
    if (overview?.image)
      formData.append('opportunities_image', overview?.image ?? '');

    if (overview?.competitive_compensation_icon_file)
      formData.append('competitive_image', overview?.competitive_compensation_icon_file as any ?? '');

    if (overview?.amazing_coworkers_icon_file)
      formData.append('amazing_coworkers_image', overview?.amazing_coworkers_icon_file as any ?? '');
    var getUser = getAuthUser();
    await axios(endpointUrl + "update-join-sats-page", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {
          setErrorPopup(true);
          setMessage(res?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        setErrorPopup(true);
        setMessage(err?.data?.message);
      });
  }
  const undoChanges = () => {
    set_initial_data();
    setIsCancel(false);
  }
  return (
    <div
      className={twJoin(
        "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={() => undoChanges()} />
      <div className="text-left">
        <h2 className="text-lg text-left font-bold mb-5 mt-5 text-black">
          Overview
        </h2>
        <div className="mt-5">
          <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
          />
          <OverviewSection
            setOverview={setOverview}
            overview={overview}
            activeLang={activeLang}
            setActiveLang={setActiveLang}
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col">
        <div className="text-left md:w-1/2 w-full md:mr-5 mr-0 md:mb-0 mb-5">
          <SeoData
            showPageTitle={false}
            setPageTitleEn={() => 1}
            setPageTitleZh={() => 1}
            setPageTitleCn={() => 1}
            metatitle_en={metaTitle_en}
            metatitle_zh={metaTitle_zh}
            metatitle_cn={metaTitle_cn}
            setMetaTitleEn={setMetaTitleEn}
            setMetaTitleZh={setMetaTitleZh}
            setMetaTitleCn={setMetaTitleCn}
            metadescription_en={metaDescription_en}
            metadescription_zh={metaDescription_zh}
            metadescription_cn={metaDescription_cn}
            setMetaDescriptionEn={setMetaDescriptionEn}
            setMetaDescriptionZh={setMetaDescriptionZh}
            setMetaDescriptionCn={setMetaDescriptionCn}
            meta_image={metaImage}
            setMetaImage={setMetaImage}
            setMetaImageFile={setMetaImageFile}
          />
        </div>
        <div className="md:w-1/2 w-full text-left">
          <BannerSingle banner={banner} setBanner={setBanner} />
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => setIsCancel(true)}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default OverViewPage;
