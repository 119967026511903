import { FC, useEffect, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";
import SeoData from "../../components/SeoData/SeoData";
import CommonLanguage from "../../components/common/CommonLanguage";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import BannerSingle, { IBannerData } from "../../components/SeoData/BannerSingle";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import { getAuthUser } from "../../app/services/dashboard";
import { useGetMediaInfoMutation } from "../../app/services/services/media";

interface IData {
  initialData: MediaReportType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (formData: any, id: number) => Promise<void>;
}


export interface MediaReportType {
  id: number;
  meta_title_en: string;
  meta_title_tc: string;
  meta_title_sc: string;
  meta_description_en: string;
  meta_description_tc: string;
  meta_description_sc: string;
  meta_image: string;
  category: string;
  title_en: string;
  title_sc: string;
  title_tc: string;
  description_en: string;
  description_sc: string;
  description_tc: string;
  image: File | undefined;
  image_url: string;
}

const options = [
  {
    label: "Passenger services",
    value: "1",
  },
  {
    label: "Ramp Services",
    value: "2",
  },
  {
    label: "Load Control and Flight Operations ",
    value: "3",
  },
];

const MediaReport: FC<IData> = ({ setShowList, initialData,handle_submit }) => {
  
  
  const [isSave, setIsSave] = useState<boolean>(false);
  const [activeLang, setActiveLang] = useState<string>("en");
  const [data, setData] = useState<MediaReportType>(initialData);
  const [metaTitle_en, setMetaTitleEn] = useState<string>(initialData.meta_title_en || "");
  const [metaTitle_zh, setMetaTitleZh] = useState<string>(
    initialData.meta_title_sc || ""
  );
  const [metaTitle_cn, setMetaTitleCn] = useState<string>(initialData.meta_title_tc || "");
  const [metaDescription_en, setMetaDescriptionEn] = useState<string>(initialData.meta_description_en || "");
  const [metaDescription_zh, setMetaDescriptionZh] = useState<string>(initialData.meta_description_sc || "");
  const [metaDescription_cn, setMetaDescriptionCn] = useState<string>(initialData.meta_description_tc || "");
  const [metaImage, setMetaImage] = useState<string>(initialData.meta_image || "");
  const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
  const description_en_Ref = useRef();

  const change_data = (id: number, value: any, key: string) => {
    if (data) {
      if (key == 'image') {
        const url = value ? URL.createObjectURL(value) : '';
        setData((prev) => {
          return {
            ...prev,
            image: value,
            image_url: url
          }
        });
        return;
      }
      setData({ ...data, [key]: value });
    }
  };
  useEffect(() => {
    if (initialData) {
      setData({
        title_en: initialData.title_en,
        title_tc: initialData.title_tc,
        title_sc: initialData.title_sc,
        description_en: initialData.description_en,
        description_tc: initialData.description_tc,
        description_sc: initialData.description_sc,
        meta_title_en: initialData.meta_title_en,
        meta_title_tc: initialData.meta_title_tc,
        meta_title_sc: initialData.meta_title_sc,
        meta_description_en: initialData.meta_description_en,
        meta_description_tc: initialData.meta_description_tc,
        meta_description_sc: initialData.meta_description_sc,
        category: initialData.category,
        image: undefined,
        image_url: initialData.image_url,
        meta_image: '',
        id: initialData.id
      });
      setMetaImage(initialData.meta_image);
    }
  }, [initialData]);

  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const handle_submit_data = async () => {
    setIsSave(true);
    const formData = new FormData();
    formData.append('meta_title_en', metaTitle_en ?? '');
    formData.append('meta_title_tc', metaTitle_zh ?? '');
    formData.append('meta_title_sc', metaTitle_cn ?? '');
    formData.append('meta_description_en', metaDescription_en ?? '');
    formData.append('meta_description_tc', metaDescription_zh ?? '');
    formData.append('meta_description_sc', metaDescription_cn ?? '');
    if (metaImageFile) {
      formData.append('meta_image', metaImageFile);
    }
    formData.append('id', data?.id?.toString() ?? '');
    formData.append('title_en', data?.title_en ?? '');
    formData.append('title_tc', data?.title_tc ?? '');
    formData.append('title_sc', data?.title_sc ?? '');
    formData.append('description_en', data?.description_en ?? '');
    formData.append('description_tc', data?.description_tc ?? '');
    formData.append('description_sc', data?.description_sc ?? '');
    formData.append('category_id', data?.category ?? '');
    formData.append('page_type', 'media-report');
    if (data.image) {
      formData.append('image', data.image as any);
    }
    const url = data.id ? 'update-media-content' : 'media-content';
    var getUser = getAuthUser();
    handle_submit(formData,data?.id)
  }
  return (
    <div>
      
      <h2 className="text-lg text-black font-bold text-left my-5">
        Media Report
      </h2>

      <div className="flex flex-col md:flex-row">

        <div className="md:w-3/5 w-full text-left md:mr-5 md:mb-0 mb-5">
          <div className="mb-5">
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
          </div>
          <LabelAndSelect
            label="Category"
            value={data?.category}
            options={options}
            setValue={(value) => change_data(data?.id, value, "category")}
          />

          <div
            className={twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div>
                <div className="grid grid-cols-1">
                  <LabelAndTextbox
                    label="Title (EN)"
                    value={data?.title_en}
                    setValue={(value: any) =>
                      change_data(data?.id, value, "title_en")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div className="grid grid-cols-1">
                <LabelAndTextbox
                  label="Title (TC)"
                  value={data?.title_tc}
                  setValue={(value: any) =>
                    change_data(data?.id, value, "title_tc")
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div className="grid grid-cols-1">
                <LabelAndTextbox
                  label="Title (SC)"
                  value={data?.title_sc}
                  setValue={(value: any) =>
                    change_data(data?.id, value, "title_sc")
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor
                refId={description_en_Ref}
                label="Description (EN)"
                value={data?.description_en}
                setValue={(value) =>
                  change_data(data?.id, value, "description_en")
                }
              />
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor
                refId={description_en_Ref}
                label="Description (TC)"
                value={data?.description_tc}
                setValue={(value) =>
                  change_data(data?.id, value, "description_tc")
                }
              />
            </div>
          </div>

          <div
            className={twJoin(
              activeLang == "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor
                refId={description_en_Ref}
                label="Description (SC)"
                value={data?.description_sc}
                setValue={(value) =>
                  change_data(data?.id, value, "description_sc")
                }
              />
            </div>
          </div>

          <div>
            <LabelAndImage
              margin="mt-5"
              setFile={(value: any) => change_data(data?.id, value, "image")}
              label="Image"
              value={data?.image_url}
              setValue={(value: any) =>
                change_data(data?.id, value, "image_url")
              }
            />
          </div>
        </div>

        <div className="md:w-2/5 w-full text-left">
          <h2 className="text-lg text-black font-bold text-left my-5">SEO</h2>
          <SeoData
            showPageTitle={false}
            setPageTitleEn={() => 1}
            setPageTitleZh={() => 1}
            setPageTitleCn={() => 1}
            metatitle_en={metaTitle_en}
            metatitle_zh={metaTitle_zh}
            metatitle_cn={metaTitle_cn}
            setMetaTitleEn={setMetaTitleEn}
            setMetaTitleZh={setMetaTitleZh}
            setMetaTitleCn={setMetaTitleCn}
            metadescription_en={metaDescription_en}
            metadescription_zh={metaDescription_zh}
            metadescription_cn={metaDescription_cn}
            setMetaDescriptionEn={setMetaDescriptionEn}
            setMetaDescriptionZh={setMetaDescriptionZh}
            setMetaDescriptionCn={setMetaDescriptionCn}
            meta_image={metaImage}
            setMetaImage={setMetaImage}
            setMetaImageFile={setMetaImageFile}
          />
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit_data()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default MediaReport;
