import { FC, useEffect, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";

interface IData {
  activeLang: string;
  initialData: DownloadItemType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (data: DownloadItemType) => Promise<void>;
  isSave:boolean;
}
export interface DownloadItemType {
  id: number;
  category: string;
  title_en: string;
  title_sc: string;
  title_tc: string;
  file: File | undefined;
  image_url: string;
  icon: File | undefined;
  icon_url: string;
}

export const options = [
  {
    label: "Logos",
    value: "logos",
  },
  {
    label: "Imagery",
    value: "imagery",
  },
  {
    label: "Content Orders ",
    value: "contents_orders",
  },
  {
    label: "Filming Licence",
    value: "filming_license",
  },
];

const DownloadItem: FC<IData> = ({ activeLang, setShowList, initialData,handle_submit,isSave }) => {
  const [data, setData] = useState<DownloadItemType>(initialData);
  const change_data = (id: number, value: any, key: string) => {
    if (data) {
      if(key==="file"){
        const url=value?URL.createObjectURL(value):'';
        setData((prev)=>{
          return {
            ...prev,
            file:value,
            image_url:url
          }
        });
        return;
      }
      if(key==="icon"){
        const url=value?URL.createObjectURL(value):'';
        setData((prev)=>{
          return {
            ...prev,
            icon:value,
            icon_url:url
          }
        });
        return;
      }
      setData({ ...data, [key]: value });
    }
  };
  useEffect(()=>{
    if(initialData){
      setData({
        id:initialData.id,
        title_en:initialData.title_en,
        title_tc:initialData.title_tc,
        title_sc:initialData.title_sc,
        category:initialData.category,
        file:undefined,
        image_url:initialData.file as any,
        icon:undefined,
        icon_url:initialData.icon as any,
      })
    }
  },[initialData])
  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">
        Download Item
      </h2>

      <LabelAndSelect
        label="Category"
        value={data?.category}
        options={options}
        setValue={(value) => change_data(data?.id, value, "category")}
      />

      <div
        className={twJoin(
          activeLang === "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (EN)"
          value={initialData?.title_en}
          setValue={(value: any) => change_data(data?.id, value, "title_en")}
        />
      </div>
      <div
        className={twJoin(
          activeLang === "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (TC)"
          value={initialData?.title_tc}
          setValue={(value: any) => change_data(data?.id, value, "title_tc")}
        />
      </div>
      <div
        className={twJoin(
          activeLang === "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (SC)"
          value={initialData?.title_sc}
          setValue={(value: any) => change_data(data?.id, value, "title_sc")}
        />
      </div>

      <div>
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(data?.id, value, "file")}
          label="File"
          value={data?.image_url}
          setValue={(value: any) => change_data(data?.id, value, "image_url")}
        />
      </div>
      <div>
        <LabelAndImage
          margin="mt-5"
          setFile={(value: any) => change_data(data?.id, value, "icon")}
          label="Icon"
          value={data?.icon_url}
          setValue={(value: any) => change_data(data?.id, value, "icon_url")}
        />
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
             isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit(data)}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default DownloadItem;
