export interface IFormField {
  id: string;
  type: string;
  value?: string;
  label?: string;
  name?: string;
  readonly?: string;
  min?: boolean;
  zip?: boolean;
}

export const tagsList = [
  { id: 1, name: "Recent activity" },
  { id: 2, name: "Email" },
  { id: 3, name: "Deal" },
  { id: 4, name: "Note" },
  { id: 5, name: "Chat" },
  { id: 6, name: "Ticket" },
  { id: 7, name: "Portal" },
  { id: 8, name: "Service" },
];



export const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Projects",
    thumb: "/images/gary.png",
    items: [
      {
        id: "quinn0",
        name: "Quinn Ergon1",
        thumb: "/images/quinn.png",
      },
      {
        id: "quinn1",
        name: "Quinn Ergon2",
        thumb: "/images/quinn.png",
      },
      {
        id: "quinn2",
        name: "Quinn Ergon3",
        thumb: "/images/quinn.png",
      },
    ],
  },
  {
    id: "cato",
    name: "Company",
    thumb: "/images/cato.png",
    items: [
      {
        id: "quinn3",
        name: "Quinn Ergon4",
        message: "message1",
        phone: "+95999998988",
        thumb: "/images/quinn.png",
      },
      {
        id: "quinn4",
        name: "Quinn Ergon5",
        message: "message1",
        phone: "+95999998988",
        thumb: "/images/quinn.png",
      },
    ],
  },
  {
    id: "kvn",
    name: "Contacts",
    thumb: "/images/kvn.png",
    items: [
      {
        id: "quinn5",
        name: "Quinn Ergon6",
        thumb: "/images/quinn.png",
      },
      {
        id: "quinn6",
        name: "Quinn Ergon7",
        thumb: "/images/quinn.png",
      },
      {
        id: "quinn7",
        name: "Quinn Ergon8",
        thumb: "/images/quinn.png",
      },
      {
        id: "quinn8",
        name: "Quinn Ergon9",
        thumb: "/images/quinn.png",
      },
      {
        id: "quinn9",
        name: "Quinn Ergon10",
        thumb: "/images/quinn.png",
      },
    ],
  },
  {
    id: "mooncake",
    name: "Quotations",
    thumb: "/images/mooncake.png",
    items: [
      {
        id: "quinn10",
        name: "Quinn Ergon11",
        thumb: "/images/quinn.png",
      },
    ],
  },
];

export const profileDataFormStructure = [
  {
    header: null,
    children: [
      {
        label: "Name",
        formField: [
          {
            id: "name",
            type: "text",
            readonly: "readOnly",
            // name:'name',
            value: "---",
          },
        ],
      },
      {
        label: "Preferred Name",
        formField: [
          {
            id: "preferredName",
            type: "text",
            readonly: "readOnly",
          },
        ],
      },
      {
        label: "Employee ID",
        formField: [
          {
            id: "employeeID",
            type: "text",
            readonly: "readOnly",
          },
        ],
      },
      {
        label: "Work Email",
        formField: [
          {
            id: "workEmail",
            type: "workEmail",
            readonly: "readOnly",
          },
        ],
      },
      {
        label: "Nationality",
        formField: [
          {
            id: "country",
            type: "text",
            readonly: "readOnly",
          },
        ],
      },
      {
        label: "DOB",
        formField: [
          {
            id: "dob",
            type: "text",
            readonly: "readOnly",
          },
        ],
      },
      {
        label: "Identity Card No.",
        formField: [
          {
            id: "nationalityID",
            type: "text",
            readonly: "readOnly",
          },
        ],
      },
      {
        label: "Passport No.",
        formField: [
          {
            id: "passportNo",
            type: "text",
            readonly: "readOnly",
          },
        ],
      },
      {
        label: "Gender",
        formField: [
          {
            id: "male",
            name: "gender",
            value: "male",
            label: "Male",
            type: "radio",
            readonly: "",
          },
          {
            id: "female",
            name: "gender",
            value: "female",
            label: "Female",
            type: "radio",
            readonly: "",
          },
        ],
      },
      {
        label: "Marital Status",
        formField: [
          {
            id: "single",
            name: "maritalStatus",
            value: "Single",
            label: "Single",
            type: "radio",
            readonly: "",
          },
          {
            id: "married",
            name: "maritalStatus",
            value: "Married",
            label: "Married",
            type: "radio",
            readonly: "",
          },
        ],
      },
    ],
  },
  {
    header: "Contact Information",
    children: [
      {
        label: "primaryAddress",
        formField: [
          {
            id: "primaryAddress",
            type: "text",
            readonly: "",
          },
        ],
      },
      {
        label: "secondaryAddress",
        formField: [
          {
            id: "secondaryAddress",
            type: "text",
            readonly: "",
          },
        ],
      },
      {
        label: "City",
        formField: [
          {
            id: "city",
            type: "text",
            readonly: "",
          },
        ],
      },
      {
        label: "State/Province",
        formField: [
          {
            id: "state",
            type: "text",
            readonly: "",
          },
        ],
      },
      {
        label: "ZIP/Postal",
        formField: [
          {
            id: "zip",
            type: "number",
            readonly: "",
            min: true,
            zip: true,
          },
        ],
      },
      {
        label: "Country",
        formField: [
          {
            id: "country",
            type: "select",
            readonly: "",
          },
        ],
      },
      {
        label: "Mobile Phone No.",
        formField: [
          {
            id: "mobile",
            type: "text/mobile",
            readonly: "",
          },
        ],
      },
      {
        label: "Work Telephone",
        formField: [
          {
            id: "workPhone",
            type: "text/mobile",
            readonly: "",
          },
        ],
      },
      {
        label: "Email",
        formField: [
          {
            id: "email",
            type: "email",
            readonly: "",
          },
        ],
      },
    ],
  },
  {
    header: "Emergency Contacts",
    children: [
      {
        label: "Name",
        formField: [
          {
            id: "emergencyContactName",
            type: "text",
            readonly: "",
          },
        ],
      },
      {
        label: "Relationship",
        formField: [
          {
            id: "relationship",
            type: "text",
            readonly: "",
          },
        ],
      },
      {
        label: "Contact No.",
        formField: [
          {
            id: "emergencyContactPhone",
            type: "text/mobile",
            readonly: "",
          },
        ],
      },
    ],
  },
];

export const dummyProfileJson = {
  name: "Sean Dennis",
  preferredName: "Sean",
  employeeID: "MY002",
  nationality: "Malaysia",
  dob: "14 Oct 1997",
  nationalityID: "123456-78-0000",
  passportNo: "A1234567",
  gender: "male",
  maritalStatus: "single",
  address1: "B1-**-**, Permai Prima",
  address2: "Jalan Permai M, Bukit Ampang Permai",
  city: "Ampang",
  state: "Selangor",
  zip: "68000",
  country: "Malaysia",
  mobile: "+60 162875262",
  workPhone: "+852 3001 1433",
  email: "xiiaole1014@gmail.com",
  workEmail: "sandy@visibleone.com",
  emergencyContactName: "Stephen Dennis",
  relationship: "Father",
  emergencyContactPhone: "+60 162086741",
};

export const dummyCountries = [
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Hongkong",
    label: "Hongkong",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
  },
];

export const leaveStatuses = [
  "All",
  "Pending",
  "Approved",
  "Rejected",
  "Completed",
];

export type ILeaveStatus = (typeof leaveStatuses)[number];

export const dummyLeaveData = [
  {
    id: "LA00001",
    type: "Birthday Leave",
    date: "2021-10-01 02:19:21",
    from: "2021-05-28",
    to: "2021-05-28",
    day: "Full",
    total: "1",
    status: "Pending",
  },
  {
    id: "LA00002",
    type: "Annual Leave",
    date: "2021-10-01 02:19:21",
    from: "2021-05-28",
    to: "2021-05-28",
    day: "Half (am)",
    total: "0.5",
    status: "Pending",
  },
  {
    id: "LA00003",
    type: "Sick Leave",
    date: "2021-10-01 02:19:21",
    from: "2021-05-28",
    to: "2021-05-28",
    day: "Half (pm)",
    total: "0.5",
    status: "Approved",
  },
  {
    id: "LA00004",
    type: "Annual Leave",
    date: "2021-10-01 02:19:21",
    from: "2021-05-28",
    to: "2021-05-28",
    day: "Full",
    total: "1",
    status: "Rejected",
  },
  {
    id: "LA00005",
    type: "Sick Leave",
    date: "2021-10-01 02:19:21",
    from: "2021-05-28",
    to: "2021-05-28",
    day: "Half (pm)",
    total: "0.5",
    status: "Completed",
  },
];

export const leaveTypes = [
  {
    label: "Annual Leave",
    info: "Annual leave is a period of paid time off work granted by employers to employees to be used for whatever the employee wishes. AL should be applied 2 weeks in advanced.",
    availableCount: 0,
  },
  {
    label: "Sick Leave",
    info: "Sick leave is paid time off from work that workers can use to stay home to address their health needs without losing pay. It differs from paid vacation time or time off work to deal with personal matters, because sick leave is intended for health-related purposes.",
    availableCount: 2,
  },
  {
    label: "Birthday Leave",
    info: "Birthday leave refers to paid time off work granted by VO employees to be used to celebrate ownself birthday or the dearest ones.",
    availableCount: 0,
  },
  {
    label: "Unpaid Leave",
    info: "Unpaid leave refers to time off from work during which an employee does not receive a salary for the length of their absence.",
    availableCount: 2,
  },
];

export const dummyLeaveBalanceData = [
  {
    id: 1,
    leaveType: "Annual Leave",
    totalAllowance: 7,
    taken: 2,
    available: 5,
  },
  {
    id: 2,
    leaveType: "Birthday Leave",
    totalAllowance: 2,
    taken: 0,
    available: 2,
  },
  {
    id: 3,
    leaveType: "Sick Leave",
    totalAllowance: 2,
    taken: 1,
    available: 1,
  },
  {
    id: 4,
    leaveType: "Unpaid Leave",
    totalAllowance: 0,
    taken: 1,
    available: 0,
  },
];

export const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

export const dummyDocumentTableData = [
  {
    id: "GE210001",
    filepath:
      "http://localhost:3000/static/media/user2.657d2ff7fe40958e09c8e2f0f6aee436.svg",
    name: "js.svg",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Tina",
    path: "/HrManagement/.",
    lastModified: "2022-01-14T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: true,
  },
  {
    id: "FE210001",
    filepath: "",
    name: "ecommerce.xml",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Stan",
    path: "/HrManagement/.",
    lastModified: "2022-01-16T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "LA210001",
    filepath: "",
    name: "font.tif",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Zinnia",
    path: "/HrManagement/.",
    lastModified: "2022-01-17T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "WP210001",
    name: "vuejs.doc",
    filepath: "",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Nang",
    path: "/HrManagement/.",
    lastModified: "2022-01-18T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "DP210001",
    filepath: "",
    name: "w3school.css",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Max",
    path: "/HrManagement/.",
    lastModified: "2022-01-14T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "AM210001",
    filepath: "",
    name: "Vue JS Documentation",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Phyllis",
    path: "/HrManagement/.",
    lastModified: "2022-01-16T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "HR210001",
    filepath: "",
    name: "Vue JS Documentation",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Phyllis",
    path: "/HrManagement/.",
    lastModified: "2022-01-16T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "AM210002",
    filepath: "",
    name: "font.tif",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Phyllis",
    path: "/HrManagement/.",
    lastModified: "2022-01-14T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "HR210002",
    filepath: "",
    name: "ecommerce.xml",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Tina",
    path: "/HrManagement/.",
    lastModified: "2022-01-16T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "HR210003",
    filepath: "",
    name: "Vue JS Documentation",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Phyllis",
    path: "/HrManagement/.",
    lastModified: "2022-01-16T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "DS210001",
    filepath: "",
    name: "LOGO.ai",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Sandy",
    path: "/HrManagement/.",
    lastModified: "2022-01-17T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "PJ210001",
    filepath: "",
    name: "Js.pdf",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Phyllis",
    path: "/HrManagement/.",
    lastModified: "2022-01-18T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "CS210001",
    filepath: "",
    name: "font.tif",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Phyllis",
    path: "/HrManagement/.",
    lastModified: "2022-01-13T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "DS210002",
    filepath: "",
    name: "DummyIcon.ai",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Sandy",
    path: "/HrManagement/.",
    lastModified: "2022-01-14T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "PJ210002",
    filepath: "",
    name: " Dummy.pdf",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Phyllis",
    path: "/HrManagement/.",
    lastModified: "2022-01-14T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
  {
    id: "CS210002",
    filepath: "",
    name: "DummyFont.tif",
    tags: [{ name: "Company", badgeColor: "#E5EAEE" }],
    owner: "Sandy",
    path: "/HrManagement/.",
    lastModified: "2022-01-14T21:49:21.000Z",
    postedBy: "HR Manager",
    isImportant: false,
  },
];

export const dummyFileTypes = [
  "All",
  "pdf",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "txt",
  "csv",
  "xls",
  "xlsx",
  "xml",
  "json",
  "md",
  "html",
  "csr",
  "cer",
  "exe",
  "apk",
  "app",
  "ini",
  "ppl",
  "zip",
  "rar",
  "tar",
  "gz",
  "gzip",
  "jpg",
  "png",
  "jpeg",
  "svg",
  "webp",
  "gif",
  "tif",
  "tiff",
  "psd",
  "fig",
  "ai",
  "xd",
  "ttf",
  "otf",
  "fnt",
  "woff",
  "woff2",
  "iso",
  "dmg",
];

export const passwordTableDummyData = [
  {
    platform: "Company Email",
    tags: [
      {
        id: 1,
        label: "Design",
        badgeColor: "#EEE5FF",
      },
      {
        id: 2,
        label: "Company",
        badgeColor: "#F3F6F9",
      },
    ],
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://visibleone.com:2096/",
  },
  {
    platform: "Google",
    tags: [
      {
        id: 1,
        label: "Fontend",
        badgeColor: "#FFF0CD",
      },
      {
        id: 2,
        label: "HR",
        badgeColor: "#E1E9FF",
      },
      {
        id: 3,
        label: "Design",
        badgeColor: "#EEE5FF",
      },
    ],
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://www.google.com/",
  },
  {
    platform: "Hotmail",
    tags: [
      {
        id: 1,
        label: "HR",
        badgeColor: "#E1E9FF",
      },
    ],
    account: "visibleone.Sean@gmail.com",
    password: "password123",
    links: "https://outlook.live.com/",
  },
  {
    platform: "Rocket Chat",
    tags: [
      {
        id: 1,
        label: "Company",
        badgeColor: "#F3F6F9",
      },
    ],
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://project.visibleone.com/",
  },
  {
    platform: "Asana",
    tags: [
      {
        id: 1,
        label: "Design",
        badgeColor: "#EEE5FF",
      },
    ],
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://project.visibleone.com/",
  },
  {
    platform: "3cx",
    tags: [
      {
        id: 1,
        label: "Design",
        badgeColor: "#EEE5FF",
      },
    ],
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://visibleone.com:2096/",
  },
  {
    platform: "Adobe",
    tags: [
      {
        id: 1,
        label: "Design",
        badgeColor: "#EEE5FF",
      },
    ],
    account: "designer@visibleone.com",
    password: "password123",
    links: "https://visibleone.com:2096/",
  },
];

export const passwordTags = [
  {
    id: 1,
    label: "Project",
    badgeColor: "#EEE5FF",
  },
  {
    id: 2,
    label: "Design",
    badgeColor: "#EEE5FF",
  },
  {
    id: 3,
    label: "Backend",
    badgeColor: "#EEE5FF",
  },
  {
    id: 4,
    label: "Wordpress",
    badgeColor: "#EEE5FF",
  },
  {
    id: 5,
    label: "Frontend",
    badgeColor: "#EEE5FF",
  },
  {
    id: 6,
    label: "Laravel",
    badgeColor: "#EEE5FF",
  },
  {
    id: 7,
    label: "IT & Server",
    badgeColor: "#EEE5FF",
  },
  {
    id: 8,
    label: "SEO & Marketing",
    badgeColor: "#EEE5FF",
  },
  {
    id: 9,
    label: "Sales",
    badgeColor: "#EEE5FF",
  },
];

export const addNewUser = () => {
  return {
    id: 0,
    name: "",
    roles: [],
    email: "",
    createdBy: {
      name: "",
      createdAt: '',
    },
    isNew: true,
    enable: true,
  };
};

export type baseObj = { [key: string]: any };

export const userlistTableDummyData = [
  {
    name: "Palo",
    roles: [
      { id: 1, name: "Super Admin" },
      { id: 2, name: "Supervisor" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Phyllis",
    roles: [{ id: 3, name: "Project Acc Manager" }],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Sandy",
    roles: [{ id: 4, name: "Standard User" }],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Chloe",
    roles: [{ id: 5, name: "Project Acc User" }],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Muni",
    roles: [{ id: 4, name: "Standard User" }],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Nang",
    roles: [
      { id: 4, name: "Standard User" },
      { id: 6, name: "Lead/Supervisor" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Stan",
    roles: [
      { id: 7, name: "System Admin" },
      { id: 6, name: "Lead/Supervisor" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Zinnia",
    roles: [
      { id: 6, name: "Lead/Supervisor" },
      { id: 4, name: "Standard User" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Yumi",
    roles: [{ id: 4, name: "Standard User" }],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Tina",
    roles: [
      { id: 8, name: "HR Manager" },
      { id: 7, name: "System Admin" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Dummy 1",
    roles: [
      { id: 6, name: "Lead/Supervisor" },
      { id: 4, name: "Standard User" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Dummy 2",
    roles: [{ id: 4, name: "Standard User" }],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Dummy 3",
    roles: [
      { id: 8, name: "HR Manager" },
      { id: 7, name: "System Admin" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
  {
    name: "Dummy 4",
    roles: [
      { id: 8, name: "HR Manager" },
      { id: 7, name: "System Admin" },
    ],
    email: "xyz12345@gmail.com",
    createdBy: {
      name: "Tina",
      createdAt: "2023-01-30T17:44:38",
    },
    enable: true,
  },
];

export const roles = [
  { id: 1, value: 0, label: "All" },
  { id: 2, value: 1, label: "Super Admin" },
  { id: 3, value: 2, label: "Supervisor" },
  { id: 4, value: 3, label: "Project Acc Manager" },
  { id: 5, value: 4, label: "Standard User" },
  { id: 6, value: 5, label: "Project Acc User" },
  { id: 7, value: 6, label: "Lead/Supervisor" },
  { id: 8, value: 7, label: "System Admin" },
  { id: 9, value: 8, label: "HR Manager" },
  { id: 11, value: 10, label: "Administrator" },
];

export const addNewRole = () => ({
  id: 0,
  role: "",
  label: "",
  isNew: true,
  permissions: [],
});

export const rolesTableDummyData = [
  {
    id: 1,
    role: "Super Administrator",
    label: "Full Access",
    permissions: [
      "Create_New_Property",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
      "Manage_User",
      "Manage_Employee",
      "Manage_Employee_Event",
      "View_Employee_Event",
      "Manage_Project",
      "Manage_Project_Event",
      "View_Project_Event",
      "Manage_Password",
      "Assign_Password",
      "Manage_Announcement",
      "Manage_OT",
      "Manage_Job",
      "Manage_Applicants",
      "Manage_Client",
      "Manage_Role",
      "Manage_Media_Library",
      "Apply_Leave",
      "Approve_Leave",
      "Reject Leave",
      "Import_Data",
      "Export_Data",
      "Upload_Document",
      "Download_Document",
      "Assign_Document",
      "Manage_Profile",
      "Manage_payroll",
    ],
  },
  {
    id: 2,
    role: "System Administrator",
    label: "System Setting Access",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
    ],
  },
  {
    id: 3,
    role: "HR Manager",
    label: "HR Management [Full Access]",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
      "Manage_User",
      "Manage_Employee",
      "Manage_Employee_Event",
      "View_Employee_Event",
      "Manage_Project",
      "Manage_Project_Event",
      "View_Project_Event",
      "Manage_Password",
      "Assign_Password",
      "Manage_Announcement",
      "Manage_OT",
      "Manage_Job",
      "Manage_Applicants",
      "Manage_Client",
      "Manage_Role",
      "Manage_Media_Library",
      "Apply_Leave",
      "Apply_Leave",
      "Approve_Leave",
      "Reject Leave",
      "Import_Data",
      "Export_Data",
      "Upload_Document",
      "Download_Document",
      "Assign_Document",
    ],
  },
  {
    id: 4,
    role: "HR User",
    label: "HR Management [Partial Access]",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 5,
    role: "Project Account Manager",
    label: "Project Management [Full Access]",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 6,
    role: "Project Account User",
    label: "Project Management [Partial Access]",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 7,
    role: "Project Tech Manager",
    label: "Unknown",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 8,
    role: "Project Tech User",
    label: "Unknown",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 9,
    role: "Lead/Supervisor",
    label:
      "Connect with members for leave approval purpose ; Leave application access with related members",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 10,
    role: "Standard Users",
    label:
      "Only can access for own profile ; Connect with leader for leave approval purpose",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 11,
    role: "HR User 2",
    label: "HR Management [Partial Access]",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 12,
    role: "Project Account Manager 2",
    label: "Project Management [Full Access]",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 13,
    role: "Project Account User 2",
    label: "Project Management [Partial Access]",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
  {
    id: 14,
    role: "Project Tech Manager 2",
    label: "Unknown",
    permissions: [
      "Create_New_User",
      "Create_New_Role",
      "Manage_User",
      "Create_New_User",
      "Create_New_Employee",
      "Create_New_Employee_Event",
      "View_Employee",
      "Create_New_Project",
      "Create_New_Project_Event",
      "View_Project",
      "Create_New_Password",
      "Create_New_Announcement",
      "Create_New_OT",
      "Create_New_Job",
      "Create_New_Client",
      "Create_New_Role",
      "Delete_Property",
    ],
  },
];

export const regions = [
  "All",
  "Hong Kong",
  "Singapore",
  "Malaysia",
  "Australia",
  "China",
];

export const regionListData = [
  {
    id: 1,
    name: "Hong Kong",
    label: "Hong Kong",
  },
  {
    id: 2,
    name: "Singapore",
    label: "Singapore",
  },
  {
    id: 3,
    name: "Malaysia",
    label: "Malaysia",
  },
  {
    id: 4,
    name: "Australia",
    label: "Australia",
  },
  {
    id: 5,
    name: "China",
    label: "China",
  },
];

export const projectTypes = [
  "All",
  "Wordpress",
  "Drupal",
  "React.js",
  "Laravel",
  "Magento",
  "ASP.net",
];

export const projectTypesListData = [
  { id: 1, name: "Wordpress", label: "Wordpress" },
  { id: 2, name: "Drupal", label: "Drupal" },
  { id: 3, name: "React.js", label: "React.js" },
  { id: 4, name: "Laravel", label: "Laravel" },
  { id: 5, name: "Magento", label: "Magento" },
  { id: 6, name: "ASP.net", label: "ASP.net" },
];

export const projectStatus = [
  "All",
  "Design in progress",
  "Design done",
  "Client review in progress",
  "Development",
  "Internal UAT",
  "Client UAT",
  "Launch",
  "Designing All Pages",
];

export const projectStatusData = [
  {
    id: 1,
    label: "Design in progress",
    name: "Design in progress",
  },
  { id: 2, label: "Design done", name: "Design done" },
  {
    id: 3,
    label: "Client review in progress",
    name: "Client review in progress",
  },
  { id: 4, label: "Development", name: "Development" },
  { id: 5, label: "Internal UAT", name: "Internal UAT" },
  { id: 6, label: "Client UAT", name: "Client UAT" },
  { id: 7, label: "Launch", name: "Launch" },
  { id: 8, label: "Designing All Pages", name: "Designing All Pages" },
];

export const paymentTerms = [
  { id: 0, name: "All", label: "All" },
  { id: 1, name: "1", label: "1" },
  { id: 2, name: "2", label: "2" },
  { id: 3, name: "3", label: "3" },
  { id: 4, name: "4", label: "4" },
  { id: 5, name: "5", label: "5" },
  { id: 6, name: "6", label: "6" },
];

export const paymentStages = [
  { label: "All", value: 0 },
  { label: "Deposit", value: 1 },
  { label: "2nd payment", value: 2 },
  { label: "3rd payment", value: 3 },
  { label: "4th payment", value: 4 },
  { label: "5th payment", value: 5 },
  { label: "Final payment", value: 6 },
];

export const paymentStatus = [
  "All",
  "To check",
  "Payment Completed",
  "Pending Payment",
];

export const paymentStatusListData = [
  {
    id: 1,
    name: "To check",
    label: "To check",
  },
  {
    id: 2,
    name: "Payment Completed",
    label: "Payment Completed",
  },
  {
    id: 3,
    name: "Pending Payment",
    label: "Pending Payment",
  },
];

export const paymentTermsData = [
  {
    id: 6,
    name: "6",
    label: "6",
  },
  {
    id: 2,
    name: "2",
    label: "2",
  },
];

export const projectComplete = ["All", "Yes", "No"];

const dummyBaseProjectData = {
  id: 1,
  projectCode: "PVO0001",
  projectName: "Mori",
  companyName: "Mori Jewellery",
  email: "morijewellery@mymori.com",
  contactPerson: "Lawrence",
  financialContactPerson: "Jeffery",
  date: "2023-02-19T17:30:00.000Z",
  region: "Hong Kong",
  projectType: "Laravel",
  duration: "9 weeks",
  projectStatus: "Design in progress",
  quotationNumber: "EST 1315",
  confirmOn: "30/01/2020",
  remarks:
    ". Concern about UIUX design ; prior Homepage design\n. No special requirements on functions\n. Informative webpages",
  projectScope:
    "1. Good UI/UX Design\n2. Responsive Design\n3. Functionality\n4. Maintenance",
  paymentTerm: "6",
  currency: {
    label: "Hong Kong Dollar",
    sign: "HK$",
  },

  projectAmount: 68000,
  paidAmount: {
    value: 41600,
    paymentValues: [
      { id: 1, amount: 34800, isDone: true },
      { id: 2, amount: 6800, isDone: true },
      { id: 3, amount: 6800, isDone: false },
      { id: 4, amount: 6800, isDone: false },
      { id: 5, amount: 6800, isDone: false },
      { id: 6, amount: 6800, isDone: false },
    ],
  },
  paymentStatus: "To check",
  endDate: "30/01/2020",
  projectCompleted: false,
  checklist: [
    { id: 1, label: "Checklist 1", isChecked: true },
    { id: 2, label: "Checklist 2", isChecked: true },
    { id: 3, label: "Checklist 3", isChecked: false },
    { id: 4, label: "Checklist 4", isChecked: false },
    { id: 5, label: "Checklist 5", isChecked: false },
    { id: 6, label: "Checklist 6", isChecked: false },
  ],
  keyDates: [
    { id: 1, date: "2023-01-14T00:00:00.000Z", label: "Kick-off Meeting" },
    { id: 2, date: "2023-01-16T00:00:00.000Z", label: "First draft design" },
    { id: 3, date: "2023-02-14T00:00:00.000Z", label: "Design Confirmation" },
    { id: 4, date: "2023-04-20T00:00:00.000Z", label: "UAT" },
    { id: 5, date: "2023-04-30T00:00:00.000Z", label: "Client UAT" },
    { id: 6, date: "2023-06-01T00:00:00.000Z", label: "Launch" },
  ],
  projectTags: [],
};

export const addNewProject = (id: number) => ({
  // id: id,
  uniqeID: id,
  projectCode: "",
  projectName: "",
  companyName: "",
  email: "",
  contactPerson: "",
  financialContactPerson: "",
  date: new Date().toISOString(),
  region: "---",
  projectType: "---",
  duration: "",
  projectStatus: "---",
  quotationNumber: "EST 1315",
  confirmOn: "30/01/2020",
  remarks: "",
  projectScope: "",
  paymentTerm: "6",
  currency: {
    label: "Hong Kong Dollar",
    sign: "HK$",
  },
  projectAmount: 68000,
  paidAmount: {
    value: 0,
    paymentValues: [
      { id: 1, amount: 0, isDone: false },
      { id: 2, amount: 0, isDone: false },
      { id: 3, amount: 0, isDone: false },
      { id: 4, amount: 0, isDone: false },
      { id: 5, amount: 0, isDone: false },
      { id: 6, amount: 0, isDone: false },
    ],
  },
  paymentStatus: "---",
  endDate: "",
  projectCompleted: false,
  checklist: [],
  keyDates: [],
  projectTags: [],
});

export const dummyProjectData = [
  {
    ...dummyBaseProjectData,
  },
  {
    ...dummyBaseProjectData,
    id: 2,
    projectCode: "PVO0002",
    projectAmount: 40800,
    projectStatus: "Design done",
    paidAmount: {
      value: 34000,
      paymentValues: [
        { id: 1, amount: 34000, isDone: true },
        { id: 2, amount: 6800, isDone: false },
      ],
    },
    paymentStatus: "Payment Completed",
    paymentTerm: "2",
    projectCompleted: true,
  },
  {
    ...dummyBaseProjectData,
    id: 3,
    projectCode: "PVO0003",
  },
  {
    ...dummyBaseProjectData,
    id: 4,
    projectCode: "PVO0004",
    projectStatus: "Client review in progress",
    paidAmount: {
      value: 68000,
      paymentValues: [
        { id: 1, amount: 34000, isDone: true },
        { id: 2, amount: 6800, isDone: true },
        { id: 3, amount: 6800, isDone: true },
        { id: 4, amount: 6800, isDone: true },
        { id: 5, amount: 6800, isDone: true },
        { id: 6, amount: 6800, isDone: true },
      ],
    },
    projectType: "Wordpress",
    paymentStatus: "Payment Completed",
    projectCompleted: true,
    region: "Singapore",
  },
  {
    ...dummyBaseProjectData,
    id: 5,
    projectStatus: "Internal UAT",
    projectCode: "PVO0005",
    paidAmount: {
      value: 68000,
      paymentValues: [
        { id: 1, amount: 34000, isDone: true },
        { id: 2, amount: 6800, isDone: true },
        { id: 3, amount: 6800, isDone: true },
        { id: 4, amount: 6800, isDone: true },
        { id: 5, amount: 6800, isDone: true },
        { id: 6, amount: 6800, isDone: true },
      ],
    },
    paymentStatus: "Payment Completed",
    projectCompleted: true,
  },
  {
    ...dummyBaseProjectData,
    id: 6,
    projectCode: "PVO0006",
  },
  {
    ...dummyBaseProjectData,
    projectCode: "PVO0007",
    id: 7,
    paidAmount: {
      value: 68000,
      paymentValues: [
        { id: 1, amount: 34000, isDone: true },
        { id: 2, amount: 6800, isDone: true },
        { id: 3, amount: 6800, isDone: true },
        { id: 4, amount: 6800, isDone: true },
        { id: 5, amount: 6800, isDone: true },
        { id: 6, amount: 6800, isDone: true },
      ],
    },
    paymentStatus: "Payment Completed",
    projectCompleted: true,
    region: "Singapore",
  },
  {
    ...dummyBaseProjectData,
    id: 8,
    projectType: "React.js",
    projectCode: "PVO0008",
  },
  {
    ...dummyBaseProjectData,
    id: 9,
    projectCode: "PVO0009",
  },
  {
    ...dummyBaseProjectData,
    id: 10,
    projectCode: "PVO0001",
  },
  {
    ...dummyBaseProjectData,
    id: 11,
    projectCode: "PVO0011",
    projectType: "Magento",
    region: "Malaysia",
  },
  {
    ...dummyBaseProjectData,
    id: 12,
    projectCode: "PVO0012",
    region: "Malaysia",
  },
  {
    ...dummyBaseProjectData,
    id: 13,
    projectCode: "PVO0013",
    region: "Malaysia",
  },
  {
    ...dummyBaseProjectData,
    id: 14,
    projectCode: "PVO0014",
    projectType: "ASP.net",
  },
];

// Password Management Table Data
export const departments = [
  "All",
  "Project",
  "Design",
  "Wordpress",
  "Frontend",
  "Laravel",
  "IT & Server",
  "SEO & Marketing",
  "Sales",
];

export const dummyPasswordListData = [
  {
    platform: "Company Email",
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://visibleone.com:2096/",
    department: "Backend Team",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
    assignUsers: [
      {
        id: 1,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
      {
        id: 2,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
    ],
  },
  {
    platform: "Google",
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://www.google.com/",
    department: "HR",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
    assignUsers: [
      {
        id: 1,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
      {
        id: 2,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
    ],
  },
  {
    platform: "Hotmail",
    account: "visibleone.Sean@gmail.com",
    password: "password123",
    links: "https://outlook.live.com/",
    department: "Frontend Team",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
    assignUsers: [
      {
        id: 1,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
      {
        id: 2,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
    ],
  },
  {
    platform: "Rocket Chat",
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://project.visibleone.com/",
    department: "Backend",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
    assignUsers: [
      {
        id: 1,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
      {
        id: 2,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
    ],
  },
  {
    platform: "Asana",
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://project.visibleone.com/",
    department: "CMS",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
    assignUsers: [
      {
        id: 1,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
      {
        id: 2,
        name: "Aishi",
        email: "aishi@gmail.com",
        profileImage: "profile.jpg",
      },
    ],
  },
  {
    platform: "3cx",
    account: "sean@visibleone.com",
    password: "password123",
    links: "https://visibleone.com:2096/",
    department: "Admin",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
  },
  {
    platform: "Adobe",
    account: "designer@visibleone.com",
    password: "password123",
    links: "https://visibleone.com:2096/",
    department: "Design",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
  },
  {
    platform: "Envato",
    account: "designer@visibleone.com",
    password: "password123",
    links: "https://visibleone.com:2096/",
    department: "Design",
    createdBy: {
      name: "Admin",
      createdAt: Date.now(),
    },
  },
];

export const dummyEmployeeList = new Array(14)
  .fill(1)
  .map((_, index: number) => {
    const actualIndex = index + 1;
    return { id: actualIndex, name: `Employee ${actualIndex}` };
  });

export const passwordTypes = [
  { id: 1, label: "Personal", value: "personal" },
  { id: 2, label: "General", value: "general" },
  { id: 3, label: "Design", value: "design" },
  { id: 4, label: "Frontend", value: "frontend" },
  { id: 5, label: "Backend", value: "backend" },
  { id: 6, label: "CMS", value: "cms" },
  { id: 7, label: "Drupal", value: "drupal" },
  { id: 8, label: "Laravel", value: "laravel" },
  { id: 9, label: "Wordpress", value: "wordpress" },
  { id: 10, label: "Magento", value: "magento" },
  { id: 11, label: "IT & Server", value: "it-&-server" },
  { id: 12, label: "Marketing", value: "marketing" },
  { id: 13, label: "HR", value: "hr" },
  { id: 14, label: "Project", value: "project" },
  { id: 15, label: "Others", value: "others" },
];

export const dummyCrmList = [
  {
    clientId: 1,
    firstName: "Eloisa",
    lastName: "Jaine",
    email: "ejaine0@icq.com",
    address: "Female",
    contactDID: "#2e7cf2",
    websiteUrl:
      "https://geocities.jp/vestibulum/sagittis.html?aliquet=varius&ultrices=ut&erat=blandit&tortor=non&sollicitudin=interdum&mi=in&sit=ante&amet=vestibulum&lobortis=ante&sapien=ipsum&sapien=primis&non=in&mi=faucibus&integer=orci&ac=luctus&neque=et&duis=ultrices&bibendum=posuere&morbi=cubilia&non=curae&quam=duis&nec=faucibus&dui=accumsan&luctus=odio&rutrum=curabitur&nulla=convallis&tellus=duis&in=consequat&sagittis=dui&dui=nec&vel=nisi&nisl=volutpat&duis=eleifend&ac=donec&nibh=ut&fusce=dolor&lacus=morbi&purus=vel&aliquet=lectus&at=in&feugiat=quam&non=fringilla&pretium=rhoncus&quis=mauris&lectus=enim&suspendisse=leo&potenti=rhoncus&in=sed&eleifend=vestibulum&quam=sit&a=amet&odio=cursus&in=id&hac=turpis&habitasse=integer&platea=aliquet&dictumst=massa&maecenas=id&ut=lobortis&massa=convallis&quis=tortor&augue=risus&luctus=dapibus&tincidunt=augue&nulla=vel&mollis=accumsan&molestie=tellus&lorem=nisi&quisque=eu&ut=orci&erat=mauris&curabitur=lacinia&gravida=sapien",
    companyName: "Powlowski-Hermann",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 2,
    firstName: "Kriste",
    lastName: "McGovern",
    email: "kmcgovern1@sciencedirect.com",
    address: "Female",
    contactDID: "#f403d2",
    companyName: "McGlynn-Kunde",
    websiteUrl:
      "https://mit.edu/vivamus/tortor/duis/mattis.aspx?suspendisse=gravida&accumsan=nisi&tortor=at&quis=nibh&turpis=in&sed=hac&ante=habitasse&vivamus=platea&tortor=dictumst&duis=aliquam&mattis=augue&egestas=quam&metus=sollicitudin&aenean=vitae&fermentum=consectetuer&donec=eget&ut=rutrum&mauris=at&eget=lorem&massa=integer&tempor=tincidunt&convallis=ante&nulla=vel&neque=ipsum&libero=praesent&convallis=blandit&eget=lacinia&eleifend=erat&luctus=vestibulum&ultricies=sed&eu=magna&nibh=at&quisque=nunc&id=commodo&justo=placerat&sit=praesent&amet=blandit&sapien=nam&dignissim=nulla&vestibulum=integer&vestibulum=pede&ante=justo&ipsum=lacinia&primis=eget&in=tincidunt&faucibus=eget&orci=tempus&luctus=vel&et=pede&ultrices=morbi&posuere=porttitor&cubilia=lorem&curae=id&nulla=ligula&dapibus=suspendisse&dolor=ornare&vel=consequat&est=lectus&donec=in&odio=est&justo=risus&sollicitudin=auctor&ut=sed&suscipit=tristique&a=in&feugiat=tempus&et=sit&eros=amet&vestibulum=sem&ac=fusce&est=consequat&lacinia=nulla&nisi=nisl&venenatis=nunc&tristique=nisl&fusce=duis&congue=bibendum&diam=felis&id=sed&ornare=interdum&imperdiet=venenatis&sapien=turpis&urna=enim&pretium=blandit&nisl=mi&ut=in&volutpat=porttitor&sapien=pede&arcu=justo&sed=eu&augue=massa&aliquam=donec&erat=dapibus&volutpat=duis&in=at&congue=velit&etiam=eu",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 3,
    firstName: "Henrik",
    lastName: "Hillum",
    email: "hhillum2@alibaba.com",
    address: "Male",
    contactDID: "#e6c623",
    companyName: "Ernser and Sons",
    websiteUrl:
      "http://netlog.com/sapien.jsp?nisl=sapien&duis=varius&ac=ut&nibh=blandit&fusce=non&lacus=interdum&purus=in&aliquet=ante&at=vestibulum&feugiat=ante&non=ipsum&pretium=primis&quis=in&lectus=faucibus&suspendisse=orci&potenti=luctus&in=et&eleifend=ultrices&quam=posuere&a=cubilia&odio=curae",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 4,
    firstName: "Baryram",
    lastName: "Holmyard",
    email: "bholmyard3@paginegialle.it",
    address: "Male",
    contactDID: "#60b6fc",
    companyName: "Langosh Inc",
    websiteUrl:
      "http://zdnet.com/parturient/montes/nascetur/ridiculus/mus.aspx?consectetuer=orci&adipiscing=luctus&elit=et&proin=ultrices&risus=posuere&praesent=cubilia&lectus=curae&vestibulum=donec&quam=pharetra&sapien=magna&varius=vestibulum&ut=aliquet&blandit=ultrices&non=erat&interdum=tortor&in=sollicitudin&ante=mi&vestibulum=sit&ante=amet&ipsum=lobortis&primis=sapien&in=sapien&faucibus=non&orci=mi&luctus=integer&et=ac&ultrices=neque&posuere=duis&cubilia=bibendum&curae=morbi&duis=non&faucibus=quam&accumsan=nec&odio=dui&curabitur=luctus&convallis=rutrum&duis=nulla&consequat=tellus&dui=in&nec=sagittis&nisi=dui&volutpat=vel&eleifend=nisl&donec=duis&ut=ac&dolor=nibh&morbi=fusce&vel=lacus&lectus=purus&in=aliquet&quam=at&fringilla=feugiat",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 5,
    firstName: "Myron",
    lastName: "Roggieri",
    email: "mroggieri4@springer.com",
    address: "Non-binary",
    contactDID: "#ff9152",
    companyName: "Leffler, Carter and O'Reilly",
    websiteUrl:
      "https://economist.com/ornare.html?risus=euismod&dapibus=scelerisque&augue=quam&vel=turpis&accumsan=adipiscing&tellus=lorem&nisi=vitae&eu=mattis&orci=nibh&mauris=ligula&lacinia=nec&sapien=sem&quis=duis&libero=aliquam&nullam=convallis&sit=nunc&amet=proin&turpis=at&elementum=turpis&ligula=a&vehicula=pede&consequat=posuere&morbi=nonummy&a=integer&ipsum=non&integer=velit&a=donec&nibh=diam&in=neque&quis=vestibulum&justo=eget&maecenas=vulputate&rhoncus=ut&aliquam=ultrices&lacus=vel&morbi=augue&quis=vestibulum",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 6,
    firstName: "Ardis",
    lastName: "Dabs",
    email: "adabs5@squarespace.com",
    address: "Female",
    contactDID: "#2d57de",
    companyName: "Rowe-Beier",
    websiteUrl:
      "http://seattletimes.com/sagittis/nam/congue/risus.json?ut=odio&erat=cras&curabitur=mi&gravida=pede",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 7,
    firstName: "Denis",
    lastName: "Sansbury",
    email: "dsansbury6@usgs.gov",
    address: "Male",
    contactDID: "#5264bd",
    companyName: "Weber, Lind and Kutch",
    websiteUrl:
      "http://example.com/ultrices/aliquet/maecenas/leo/odio/condimentum/id.jsp?nam=nulla&congue=eget&risus=eros&semper=elementum&porta=pellentesque&volutpat=quisque&quam=porta&pede=volutpat&lobortis=erat&ligula=quisque&sit=erat&amet=eros&eleifend=viverra&pede=eget&libero=congue&quis=eget&orci=semper&nullam=rutrum&molestie=nulla&nibh=nunc&in=purus&lectus=phasellus&pellentesque=in&at=felis&nulla=donec&suspendisse=semper&potenti=sapien&cras=a&in=libero&purus=nam&eu=dui&magna=proin&vulputate=leo&luctus=odio&cum=porttitor&sociis=id&natoque=consequat&penatibus=in&et=consequat&magnis=ut&dis=nulla&parturient=sed&montes=accumsan&nascetur=felis&ridiculus=ut&mus=at&vivamus=dolor&vestibulum=quis&sagittis=odio&sapien=consequat",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 8,
    firstName: "Rani",
    lastName: "Salasar",
    email: "rsalasar7@newyorker.com",
    address: "Female",
    contactDID: "#158002",
    companyName: "Bayer-Ernser",
    websiteUrl:
      "https://bbc.co.uk/sit/amet/justo/morbi/ut/odio.aspx?vivamus=adipiscing&vestibulum=elit&sagittis=proin&sapien=interdum&cum=mauris&sociis=non&natoque=ligula&penatibus=pellentesque&et=ultrices&magnis=phasellus&dis=id&parturient=sapien&montes=in&nascetur=sapien&ridiculus=iaculis&mus=congue&etiam=vivamus&vel=metus&augue=arcu&vestibulum=adipiscing&rutrum=molestie&rutrum=hendrerit&neque=at&aenean=vulputate&auctor=vitae&gravida=nisl&sem=aenean&praesent=lectus&id=pellentesque&massa=eget&id=nunc&nisl=donec&venenatis=quis&lacinia=orci&aenean=eget&sit=orci&amet=vehicula&justo=condimentum&morbi=curabitur&ut=in&odio=libero&cras=ut&mi=massa",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 9,
    firstName: "Cherise",
    lastName: "Skerman",
    email: "cskerman8@imdb.com",
    address: "Female",
    contactDID: "#0404b1",
    companyName: "Johns, Corwin and Kemmer",
    websiteUrl:
      "https://fotki.com/habitasse/platea/dictumst/maecenas/ut/massa/quis.xml?tristique=magna&fusce=bibendum&congue=imperdiet&diam=nullam&id=orci&ornare=pede&imperdiet=venenatis&sapien=non&urna=sodales&pretium=sed&nisl=tincidunt&ut=eu&volutpat=felis&sapien=fusce&arcu=posuere&sed=felis&augue=sed&aliquam=lacus&erat=morbi&volutpat=sem&in=mauris&congue=laoreet&etiam=ut&justo=rhoncus&etiam=aliquet&pretium=pulvinar&iaculis=sed&justo=nisl&in=nunc&hac=rhoncus&habitasse=dui&platea=vel&dictumst=sem&etiam=sed&faucibus=sagittis&cursus=nam&urna=congue&ut=risus&tellus=semper&nulla=porta&ut=volutpat&erat=quam&id=pede&mauris=lobortis&vulputate=ligula&elementum=sit&nullam=amet&varius=eleifend&nulla=pede&facilisi=libero&cras=quis&non=orci&velit=nullam&nec=molestie&nisi=nibh&vulputate=in&nonummy=lectus&maecenas=pellentesque&tincidunt=at&lacus=nulla&at=suspendisse&velit=potenti&vivamus=cras&vel=in&nulla=purus&eget=eu&eros=magna&elementum=vulputate&pellentesque=luctus&quisque=cum&porta=sociis&volutpat=natoque&erat=penatibus&quisque=et&erat=magnis&eros=dis&viverra=parturient&eget=montes&congue=nascetur&eget=ridiculus&semper=mus&rutrum=vivamus&nulla=vestibulum&nunc=sagittis&purus=sapien&phasellus=cum&in=sociis&felis=natoque&donec=penatibus&semper=et&sapien=magnis&a=dis&libero=parturient&nam=montes",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
  {
    clientId: 10,
    firstName: "Judie",
    lastName: "Jorissen",
    email: "jjorissen9@miitbeian.gov.cn",
    address: "Female",
    contactDID: "#c12e76",
    companyName: "Bailey LLC",
    websiteUrl:
      "https://acquirethisname.com/vel/nisl/duis/ac/nibh.js?accumsan=at&tellus=nibh&nisi=in&eu=hac&orci=habitasse&mauris=platea&lacinia=dictumst&sapien=aliquam&quis=augue&libero=quam&nullam=sollicitudin&sit=vitae&amet=consectetuer&turpis=eget&elementum=rutrum&ligula=at&vehicula=lorem&consequat=integer&morbi=tincidunt&a=ante&ipsum=vel&integer=ipsum&a=praesent&nibh=blandit&in=lacinia&quis=erat&justo=vestibulum&maecenas=sed&rhoncus=magna&aliquam=at&lacus=nunc&morbi=commodo&quis=placerat&tortor=praesent&id=blandit&nulla=nam&ultrices=nulla&aliquet=integer&maecenas=pede&leo=justo&odio=lacinia&condimentum=eget&id=tincidunt&luctus=eget&nec=tempus&molestie=vel&sed=pede&justo=morbi&pellentesque=porttitor&viverra=lorem&pede=id&ac=ligula&diam=suspendisse&cras=ornare&pellentesque=consequat&volutpat=lectus&dui=in&maecenas=est&tristique=risus&est=auctor&et=sed&tempus=tristique&semper=in&est=tempus&quam=sit&pharetra=amet&magna=sem",
    industry: { id: 1, name: "Yoveo" },
    primaryOrNot: { id: 1, name: "Yes" },
    leadSource: { id: 1, name: "2" },
    leadType: { id: 1, name: "YCRK" },
    leadStatus: { id: 1, name: "AU" },
    serviceRequired: { id: 1, name: "false" },
    followUpStatus: { id: 1, name: "Doors, Frames & Hardware" },
  },
];

// ,
//   {
//     "clientId": 11,
//     "firstName": "Layne",
//     "lastName": "Dufour",
//     "email": "ldufoura@acquirethisname.com",
//     "address": "Female",
//     "contactDID": "#9b1f7b",
//     "companyName": "Bradtke-Paucek",
//     "industry": "Yotz",
//     "primaryOrNot": false,
//     "websiteUrl": "http://mlb.com/est/quam/pharetra/magna/ac/consequat/metus.xml?luctus=elementum&tincidunt=nullam&nulla=varius&mollis=nulla&molestie=facilisi&lorem=cras&quisque=non&ut=velit&erat=nec&curabitur=nisi&gravida=vulputate&nisi=nonummy&at=maecenas&nibh=tincidunt&in=lacus&hac=at&habitasse=velit&platea=vivamus&dictumst=vel&aliquam=nulla&augue=eget&quam=eros&sollicitudin=elementum&vitae=pellentesque&consectetuer=quisque&eget=porta&rutrum=volutpat&at=erat&lorem=quisque&integer=erat&tincidunt=eros&ante=viverra&vel=eget&ipsum=congue&praesent=eget&blandit=semper&lacinia=rutrum&erat=nulla&vestibulum=nunc&sed=purus&magna=phasellus&at=in&nunc=felis&commodo=donec&placerat=semper&praesent=sapien&blandit=a&nam=libero&nulla=nam&integer=dui&pede=proin&justo=leo&lacinia=odio&eget=porttitor&tincidunt=id&eget=consequat&tempus=in",
//     "leadSource": 2,
//     "leadType": "KSCH",
//     "leadStatus": "US",
//     "serviceRequired": false,
//     "followUpStatus": "Electrical and Fire Alarm"
//   },
//   {
//     "clientId": 12,
//     "firstName": "Marys",
//     "lastName": "Corcoran",
//     "email": "mcorcoranb@cbslocal.com",
//     "address": "Female",
//     "contactDID": "#f02ad7",
//     "companyName": "Keebler and Sons",
//     "industry": "Thoughtbeat",
//     "primaryOrNot": true,
//     "websiteUrl": "http://wunderground.com/quis/lectus/suspendisse/potenti/in.jpg?aenean=augue&fermentum=aliquam&donec=erat&ut=volutpat&mauris=in&eget=congue&massa=etiam&tempor=justo&convallis=etiam&nulla=pretium&neque=iaculis&libero=justo&convallis=in&eget=hac&eleifend=habitasse&luctus=platea&ultricies=dictumst&eu=etiam&nibh=faucibus&quisque=cursus&id=urna&justo=ut&sit=tellus&amet=nulla&sapien=ut&dignissim=erat&vestibulum=id&vestibulum=mauris&ante=vulputate&ipsum=elementum&primis=nullam&in=varius&faucibus=nulla&orci=facilisi&luctus=cras&et=non&ultrices=velit&posuere=nec&cubilia=nisi&curae=vulputate&nulla=nonummy&dapibus=maecenas&dolor=tincidunt&vel=lacus&est=at&donec=velit&odio=vivamus&justo=vel&sollicitudin=nulla&ut=eget&suscipit=eros&a=elementum&feugiat=pellentesque&et=quisque&eros=porta&vestibulum=volutpat&ac=erat&est=quisque&lacinia=erat&nisi=eros&venenatis=viverra&tristique=eget&fusce=congue&congue=eget&diam=semper&id=rutrum&ornare=nulla&imperdiet=nunc&sapien=purus&urna=phasellus&pretium=in&nisl=felis&ut=donec&volutpat=semper&sapien=sapien&arcu=a&sed=libero&augue=nam&aliquam=dui&erat=proin&volutpat=leo&in=odio&congue=porttitor&etiam=id&justo=consequat&etiam=in&pretium=consequat&iaculis=ut&justo=nulla&in=sed&hac=accumsan",
//     "leadSource": 1,
//     "leadType": "AYMN",
//     "leadStatus": "PG",
//     "serviceRequired": false,
//     "followUpStatus": "Asphalt Paving"
//   },
//   {
//     "clientId": 13,
//     "firstName": "Leonhard",
//     "lastName": "Twomey",
//     "email": "ltwomeyc@yellowbook.com",
//     "address": "Male",
//     "contactDID": "#62f9c6",
//     "companyName": "Olson-Durgan",
//     "industry": "Devify",
//     "primaryOrNot": true,
//     "websiteUrl": "http://livejournal.com/dui/nec.png?proin=convallis&at=eget&turpis=eleifend&a=luctus&pede=ultricies&posuere=eu&nonummy=nibh&integer=quisque&non=id&velit=justo&donec=sit&diam=amet&neque=sapien&vestibulum=dignissim&eget=vestibulum&vulputate=vestibulum&ut=ante&ultrices=ipsum&vel=primis&augue=in&vestibulum=faucibus&ante=orci&ipsum=luctus&primis=et&in=ultrices&faucibus=posuere&orci=cubilia&luctus=curae&et=nulla&ultrices=dapibus&posuere=dolor&cubilia=vel&curae=est&donec=donec&pharetra=odio&magna=justo&vestibulum=sollicitudin&aliquet=ut&ultrices=suscipit&erat=a&tortor=feugiat&sollicitudin=et&mi=eros&sit=vestibulum",
//     "leadSource": 1,
//     "leadType": null,
//     "leadStatus": "PH",
//     "serviceRequired": true,
//     "followUpStatus": "Doors, Frames & Hardware"
//   },
//   {
//     "clientId": 14,
//     "firstName": "Roze",
//     "lastName": "Fritchly",
//     "email": "rfritchlyd@smugmug.com",
//     "address": "Female",
//     "contactDID": "#c696cb",
//     "companyName": "Bednar-Anderson",
//     "industry": "Zava",
//     "primaryOrNot": false,
//     "websiteUrl": "http://ed.gov/orci/nullam/molestie.jsp?vivamus=velit&vel=vivamus&nulla=vel&eget=nulla&eros=eget&elementum=eros&pellentesque=elementum&quisque=pellentesque&porta=quisque&volutpat=porta&erat=volutpat&quisque=erat&erat=quisque&eros=erat&viverra=eros&eget=viverra&congue=eget&eget=congue&semper=eget&rutrum=semper&nulla=rutrum&nunc=nulla&purus=nunc&phasellus=purus&in=phasellus&felis=in&donec=felis&semper=donec&sapien=semper&a=sapien&libero=a&nam=libero&dui=nam&proin=dui&leo=proin&odio=leo&porttitor=odio&id=porttitor&consequat=id&in=consequat&consequat=in&ut=consequat&nulla=ut&sed=nulla&accumsan=sed&felis=accumsan&ut=felis&at=ut&dolor=at&quis=dolor&odio=quis&consequat=odio&varius=consequat&integer=varius&ac=integer&leo=ac&pellentesque=leo&ultrices=pellentesque&mattis=ultrices&odio=mattis&donec=odio&vitae=donec&nisi=vitae&nam=nisi&ultrices=nam&libero=ultrices&non=libero&mattis=non&pulvinar=mattis&nulla=pulvinar&pede=nulla&ullamcorper=pede&augue=ullamcorper&a=augue&suscipit=a&nulla=suscipit&elit=nulla&ac=elit&nulla=ac&sed=nulla&vel=sed",
//     "leadSource": 3,
//     "leadType": "SVEZ",
//     "leadStatus": "VE",
//     "serviceRequired": true,
//     "followUpStatus": "EIFS"
//   },
//   {
//     "clientId": 15,
//     "firstName": "Gena",
//     "lastName": "Bigglestone",
//     "email": "gbigglestonee@1688.com",
//     "address": "Female",
//     "contactDID": "#82726c",
//     "companyName": "Trantow, Bogisich and Connelly",
//     "industry": "Oyoyo",
//     "primaryOrNot": true,
//     "websiteUrl": "http://bloglovin.com/sit.png?vitae=neque&consectetuer=libero&eget=convallis&rutrum=eget&at=eleifend&lorem=luctus&integer=ultricies&tincidunt=eu&ante=nibh&vel=quisque&ipsum=id&praesent=justo&blandit=sit&lacinia=amet&erat=sapien&vestibulum=dignissim&sed=vestibulum&magna=vestibulum&at=ante&nunc=ipsum&commodo=primis&placerat=in&praesent=faucibus&blandit=orci&nam=luctus&nulla=et&integer=ultrices&pede=posuere&justo=cubilia&lacinia=curae&eget=nulla&tincidunt=dapibus&eget=dolor&tempus=vel&vel=est&pede=donec&morbi=odio&porttitor=justo&lorem=sollicitudin&id=ut&ligula=suscipit&suspendisse=a&ornare=feugiat&consequat=et&lectus=eros&in=vestibulum&est=ac&risus=est&auctor=lacinia&sed=nisi&tristique=venenatis&in=tristique&tempus=fusce&sit=congue&amet=diam&sem=id&fusce=ornare&consequat=imperdiet&nulla=sapien&nisl=urna&nunc=pretium&nisl=nisl&duis=ut&bibendum=volutpat&felis=sapien&sed=arcu&interdum=sed&venenatis=augue&turpis=aliquam&enim=erat&blandit=volutpat&mi=in&in=congue&porttitor=etiam&pede=justo&justo=etiam&eu=pretium&massa=iaculis&donec=justo&dapibus=in&duis=hac&at=habitasse&velit=platea&eu=dictumst&est=etiam",
//     "leadSource": 3,
//     "leadType": "KSFM",
//     "leadStatus": "US",
//     "serviceRequired": false,
//     "followUpStatus": "Wall Protection"
//   },
//   {
//     "clientId": 16,
//     "firstName": "Dido",
//     "lastName": "Linny",
//     "email": "dlinnyf@goo.ne.jp",
//     "address": "Female",
//     "contactDID": "#70ac5f",
//     "companyName": "DuBuque, Cronin and Krajcik",
//     "industry": "Wikizz",
//     "primaryOrNot": true,
//     "websiteUrl": "https://washington.edu/dolor/vel/est/donec/odio.aspx?ut=nec&dolor=dui&morbi=luctus&vel=rutrum&lectus=nulla&in=tellus&quam=in&fringilla=sagittis&rhoncus=dui&mauris=vel&enim=nisl&leo=duis&rhoncus=ac&sed=nibh&vestibulum=fusce&sit=lacus&amet=purus&cursus=aliquet&id=at&turpis=feugiat&integer=non&aliquet=pretium&massa=quis&id=lectus&lobortis=suspendisse&convallis=potenti&tortor=in&risus=eleifend&dapibus=quam&augue=a&vel=odio&accumsan=in&tellus=hac&nisi=habitasse&eu=platea&orci=dictumst&mauris=maecenas&lacinia=ut&sapien=massa&quis=quis&libero=augue&nullam=luctus&sit=tincidunt&amet=nulla&turpis=mollis&elementum=molestie&ligula=lorem&vehicula=quisque&consequat=ut&morbi=erat&a=curabitur&ipsum=gravida&integer=nisi&a=at&nibh=nibh&in=in&quis=hac&justo=habitasse&maecenas=platea&rhoncus=dictumst&aliquam=aliquam&lacus=augue&morbi=quam&quis=sollicitudin&tortor=vitae",
//     "leadSource": 2,
//     "leadType": "KDVT",
//     "leadStatus": "US",
//     "serviceRequired": true,
//     "followUpStatus": "Prefabricated Aluminum Metal Canopies"
//   },
//   {
//     "clientId": 17,
//     "firstName": "Foss",
//     "lastName": "Mathivet",
//     "email": "fmathivetg@usgs.gov",
//     "address": "Male",
//     "contactDID": "#5f714c",
//     "companyName": "Wintheiser-Corwin",
//     "industry": "Topiclounge",
//     "primaryOrNot": false,
//     "websiteUrl": "http://yandex.ru/cum.png?ac=lacus&neque=at&duis=velit&bibendum=vivamus&morbi=vel&non=nulla&quam=eget&nec=eros&dui=elementum&luctus=pellentesque&rutrum=quisque&nulla=porta&tellus=volutpat&in=erat&sagittis=quisque&dui=erat&vel=eros&nisl=viverra&duis=eget&ac=congue&nibh=eget&fusce=semper",
//     "leadSource": 2,
//     "leadType": "SKVV",
//     "leadStatus": "CO",
//     "serviceRequired": true,
//     "followUpStatus": "Temp Fencing, Decorative Fencing and Gates"
//   },
//   {
//     "clientId": 18,
//     "firstName": "Rosalia",
//     "lastName": "Stede",
//     "email": "rstedeh@gov.uk",
//     "address": "Female",
//     "contactDID": "#7f4f55",
//     "companyName": "Herman-Pfeffer",
//     "industry": "Ntags",
//     "primaryOrNot": false,
//     "websiteUrl": "https://microsoft.com/erat/eros/viverra/eget/congue/eget.png?sapien=at&sapien=vulputate&non=vitae&mi=nisl&integer=aenean&ac=lectus&neque=pellentesque&duis=eget&bibendum=nunc&morbi=donec&non=quis&quam=orci&nec=eget&dui=orci&luctus=vehicula&rutrum=condimentum&nulla=curabitur&tellus=in&in=libero&sagittis=ut&dui=massa&vel=volutpat&nisl=convallis&duis=morbi&ac=odio&nibh=odio&fusce=elementum&lacus=eu&purus=interdum&aliquet=eu&at=tincidunt&feugiat=in&non=leo&pretium=maecenas&quis=pulvinar&lectus=lobortis&suspendisse=est&potenti=phasellus&in=sit&eleifend=amet&quam=erat&a=nulla&odio=tempus&in=vivamus&hac=in&habitasse=felis&platea=eu&dictumst=sapien&maecenas=cursus&ut=vestibulum&massa=proin&quis=eu&augue=mi&luctus=nulla&tincidunt=ac&nulla=enim&mollis=in&molestie=tempor&lorem=turpis&quisque=nec&ut=euismod&erat=scelerisque&curabitur=quam&gravida=turpis&nisi=adipiscing&at=lorem&nibh=vitae&in=mattis&hac=nibh&habitasse=ligula&platea=nec&dictumst=sem&aliquam=duis&augue=aliquam&quam=convallis&sollicitudin=nunc&vitae=proin&consectetuer=at&eget=turpis&rutrum=a",
//     "leadSource": 2,
//     "leadType": "KUTS",
//     "leadStatus": "US",
//     "serviceRequired": false,
//     "followUpStatus": "Termite Control"
//   },
//   {
//     "clientId": 19,
//     "firstName": "Matthias",
//     "lastName": "Lackney",
//     "email": "mlackneyi@clickbank.net",
//     "address": "Male",
//     "contactDID": "#c69286",
//     "companyName": "Stokes and Sons",
//     "industry": "Oyoba",
//     "primaryOrNot": true,
//     "websiteUrl": "https://facebook.com/cubilia/curae.html?nisi=volutpat&nam=erat&ultrices=quisque&libero=erat&non=eros&mattis=viverra&pulvinar=eget&nulla=congue&pede=eget&ullamcorper=semper&augue=rutrum&a=nulla&suscipit=nunc&nulla=purus&elit=phasellus&ac=in&nulla=felis&sed=donec&vel=semper&enim=sapien&sit=a&amet=libero&nunc=nam&viverra=dui&dapibus=proin&nulla=leo&suscipit=odio&ligula=porttitor&in=id&lacus=consequat&curabitur=in&at=consequat&ipsum=ut&ac=nulla&tellus=sed",
//     "leadSource": 3,
//     "leadType": "LTFE",
//     "leadStatus": "TR",
//     "serviceRequired": true,
//     "followUpStatus": "Masonry & Precast"
//   },
//   {
//     "clientId": 20,
//     "firstName": "Jaquelyn",
//     "lastName": "Murby",
//     "email": "jmurbyj@diigo.com",
//     "address": "Female",
//     "contactDID": "#c6fe96",
//     "companyName": "Huel, Beatty and Kub",
//     "industry": "Snaptags",
//     "primaryOrNot": true,
//     "websiteUrl": "https://huffingtonpost.com/convallis/duis.png?duis=non&at=velit&velit=donec&eu=diam&est=neque&congue=vestibulum&elementum=eget&in=vulputate&hac=ut&habitasse=ultrices&platea=vel&dictumst=augue&morbi=vestibulum&vestibulum=ante&velit=ipsum&id=primis&pretium=in&iaculis=faucibus&diam=orci&erat=luctus&fermentum=et&justo=ultrices&nec=posuere&condimentum=cubilia&neque=curae&sapien=donec&placerat=pharetra&ante=magna&nulla=vestibulum&justo=aliquet&aliquam=ultrices&quis=erat&turpis=tortor&eget=sollicitudin&elit=mi&sodales=sit&scelerisque=amet&mauris=lobortis&sit=sapien&amet=sapien",
//     "leadSource": 1,
//     "leadType": "KCGI",
//     "leadStatus": "US",
//     "serviceRequired": true,
//     "followUpStatus": "Roofing (Metal)"
//   },
//   {
//     "clientId": 21,
//     "firstName": "Cary",
//     "lastName": "Keys",
//     "email": "ckeysk@moonfruit.com",
//     "address": "Female",
//     "contactDID": "#ee2475",
//     "companyName": "Monahan-Schamberger",
//     "industry": "Muxo",
//     "primaryOrNot": false,
//     "websiteUrl": "https://xrea.com/ligula.xml?sed=erat&tristique=id&in=mauris&tempus=vulputate&sit=elementum&amet=nullam&sem=varius&fusce=nulla&consequat=facilisi&nulla=cras&nisl=non&nunc=velit",
//     "leadSource": 1,
//     "leadType": "MTPX",
//     "leadStatus": "HT",
//     "serviceRequired": true,
//     "followUpStatus": "Masonry & Precast"
//   },
//   {
//     "clientId": 22,
//     "firstName": "Valentina",
//     "lastName": "Dumblton",
//     "email": "vdumbltonl@theguardian.com",
//     "address": "Female",
//     "contactDID": "#575608",
//     "companyName": "Kohler, Crooks and Casper",
//     "industry": "Topicblab",
//     "primaryOrNot": false,
//     "websiteUrl": "https://wp.com/nulla.png?blandit=massa&non=quis&interdum=augue&in=luctus&ante=tincidunt&vestibulum=nulla&ante=mollis&ipsum=molestie&primis=lorem&in=quisque&faucibus=ut&orci=erat&luctus=curabitur&et=gravida&ultrices=nisi&posuere=at&cubilia=nibh&curae=in&duis=hac&faucibus=habitasse",
//     "leadSource": 2,
//     "leadType": "AYBF",
//     "leadStatus": "PG",
//     "serviceRequired": true,
//     "followUpStatus": "Framing (Steel)"
//   },
//   {
//     "clientId": 23,
//     "firstName": "Beau",
//     "lastName": "Frane",
//     "email": "bfranem@craigslist.org",
//     "address": "Male",
//     "contactDID": "#a4aef0",
//     "companyName": "Fritsch Group",
//     "industry": "Dabtype",
//     "primaryOrNot": false,
//     "websiteUrl": "http://ameblo.jp/posuere/metus/vitae.png?suspendisse=pharetra&potenti=magna&cras=vestibulum&in=aliquet&purus=ultrices&eu=erat&magna=tortor&vulputate=sollicitudin&luctus=mi&cum=sit&sociis=amet&natoque=lobortis&penatibus=sapien&et=sapien&magnis=non&dis=mi&parturient=integer&montes=ac&nascetur=neque&ridiculus=duis&mus=bibendum&vivamus=morbi&vestibulum=non&sagittis=quam&sapien=nec&cum=dui&sociis=luctus&natoque=rutrum&penatibus=nulla&et=tellus&magnis=in&dis=sagittis&parturient=dui&montes=vel&nascetur=nisl&ridiculus=duis&mus=ac&etiam=nibh&vel=fusce&augue=lacus&vestibulum=purus&rutrum=aliquet&rutrum=at&neque=feugiat&aenean=non&auctor=pretium&gravida=quis&sem=lectus&praesent=suspendisse&id=potenti&massa=in&id=eleifend&nisl=quam&venenatis=a&lacinia=odio&aenean=in&sit=hac&amet=habitasse&justo=platea&morbi=dictumst&ut=maecenas&odio=ut&cras=massa&mi=quis&pede=augue&malesuada=luctus&in=tincidunt&imperdiet=nulla&et=mollis&commodo=molestie&vulputate=lorem&justo=quisque&in=ut&blandit=erat&ultrices=curabitur&enim=gravida&lorem=nisi&ipsum=at&dolor=nibh&sit=in&amet=hac&consectetuer=habitasse&adipiscing=platea&elit=dictumst&proin=aliquam&interdum=augue&mauris=quam&non=sollicitudin&ligula=vitae&pellentesque=consectetuer&ultrices=eget&phasellus=rutrum&id=at",
//     "leadSource": 1,
//     "leadType": null,
//     "leadStatus": "ZA",
//     "serviceRequired": true,
//     "followUpStatus": "Prefabricated Aluminum Metal Canopies"
//   },
//   {
//     "clientId": 24,
//     "firstName": "Guendolen",
//     "lastName": "Coppens",
//     "email": "gcoppensn@jalbum.net",
//     "address": "Female",
//     "contactDID": "#aea2f3",
//     "companyName": "Larson-Runolfsson",
//     "industry": "Blogspan",
//     "primaryOrNot": true,
//     "websiteUrl": "http://netvibes.com/rhoncus/aliquam.js?eget=curae&vulputate=nulla&ut=dapibus&ultrices=dolor&vel=vel&augue=est&vestibulum=donec&ante=odio&ipsum=justo&primis=sollicitudin&in=ut&faucibus=suscipit&orci=a&luctus=feugiat&et=et&ultrices=eros&posuere=vestibulum&cubilia=ac&curae=est&donec=lacinia&pharetra=nisi&magna=venenatis&vestibulum=tristique&aliquet=fusce&ultrices=congue&erat=diam&tortor=id&sollicitudin=ornare&mi=imperdiet&sit=sapien&amet=urna&lobortis=pretium&sapien=nisl&sapien=ut&non=volutpat&mi=sapien&integer=arcu&ac=sed&neque=augue&duis=aliquam&bibendum=erat&morbi=volutpat&non=in&quam=congue&nec=etiam&dui=justo&luctus=etiam&rutrum=pretium&nulla=iaculis&tellus=justo&in=in&sagittis=hac&dui=habitasse&vel=platea&nisl=dictumst&duis=etiam&ac=faucibus&nibh=cursus&fusce=urna&lacus=ut&purus=tellus&aliquet=nulla&at=ut&feugiat=erat&non=id&pretium=mauris&quis=vulputate&lectus=elementum&suspendisse=nullam&potenti=varius&in=nulla&eleifend=facilisi&quam=cras&a=non&odio=velit&in=nec&hac=nisi&habitasse=vulputate&platea=nonummy&dictumst=maecenas&maecenas=tincidunt&ut=lacus&massa=at&quis=velit&augue=vivamus",
//     "leadSource": 4,
//     "leadType": "KGWO",
//     "leadStatus": "US",
//     "serviceRequired": false,
//     "followUpStatus": "Retaining Wall and Brick Pavers"
//   },
//   {
//     "clientId": 25,
//     "firstName": "Thomasa",
//     "lastName": "Brookes",
//     "email": "tbrookeso@sciencedirect.com",
//     "address": "Female",
//     "contactDID": "#b16a45",
//     "companyName": "Gulgowski Group",
//     "industry": "Brainlounge",
//     "primaryOrNot": true,
//     "websiteUrl": "http://nih.gov/suscipit/nulla/elit/ac/nulla/sed.xml?pede=tortor&ullamcorper=id&augue=nulla&a=ultrices&suscipit=aliquet&nulla=maecenas&elit=leo&ac=odio&nulla=condimentum&sed=id&vel=luctus&enim=nec&sit=molestie&amet=sed&nunc=justo&viverra=pellentesque&dapibus=viverra&nulla=pede&suscipit=ac&ligula=diam",
//     "leadSource": 2,
//     "leadType": null,
//     "leadStatus": "AU",
//     "serviceRequired": false,
//     "followUpStatus": "Exterior Signage"
//   },
//   {
//     "clientId": 26,
//     "firstName": "Trent",
//     "lastName": "Burbudge",
//     "email": "tburbudgep@kickstarter.com",
//     "address": "Male",
//     "contactDID": "#86be35",
//     "companyName": "Grady LLC",
//     "industry": "Bubblemix",
//     "primaryOrNot": true,
//     "websiteUrl": "https://soundcloud.com/at/diam/nam/tristique/tortor.jpg?in=ut&faucibus=tellus&orci=nulla&luctus=ut&et=erat&ultrices=id&posuere=mauris&cubilia=vulputate&curae=elementum&nulla=nullam&dapibus=varius&dolor=nulla&vel=facilisi&est=cras&donec=non&odio=velit&justo=nec&sollicitudin=nisi&ut=vulputate&suscipit=nonummy&a=maecenas&feugiat=tincidunt&et=lacus&eros=at&vestibulum=velit&ac=vivamus&est=vel&lacinia=nulla&nisi=eget&venenatis=eros&tristique=elementum&fusce=pellentesque&congue=quisque&diam=porta&id=volutpat&ornare=erat&imperdiet=quisque&sapien=erat&urna=eros&pretium=viverra&nisl=eget&ut=congue&volutpat=eget&sapien=semper&arcu=rutrum&sed=nulla&augue=nunc&aliquam=purus&erat=phasellus&volutpat=in&in=felis&congue=donec&etiam=semper&justo=sapien&etiam=a&pretium=libero&iaculis=nam&justo=dui&in=proin&hac=leo&habitasse=odio&platea=porttitor&dictumst=id&etiam=consequat&faucibus=in&cursus=consequat&urna=ut&ut=nulla&tellus=sed&nulla=accumsan&ut=felis&erat=ut&id=at&mauris=dolor&vulputate=quis&elementum=odio&nullam=consequat&varius=varius&nulla=integer&facilisi=ac&cras=leo&non=pellentesque&velit=ultrices&nec=mattis&nisi=odio&vulputate=donec&nonummy=vitae",
//     "leadSource": 1,
//     "leadType": "LFRJ",
//     "leadStatus": "FR",
//     "serviceRequired": true,
//     "followUpStatus": "Drywall & Acoustical (MOB)"
//   },
//   {
//     "clientId": 27,
//     "firstName": "Nevin",
//     "lastName": "Morrowe",
//     "email": "nmorroweq@etsy.com",
//     "address": "Male",
//     "contactDID": "#5eb713",
//     "companyName": "Herman-Dickens",
//     "industry": "Dynabox",
//     "primaryOrNot": false,
//     "websiteUrl": "https://google.com.hk/non/mi/integer.html?at=in&vulputate=felis&vitae=eu&nisl=sapien&aenean=cursus&lectus=vestibulum&pellentesque=proin&eget=eu&nunc=mi&donec=nulla&quis=ac&orci=enim&eget=in&orci=tempor&vehicula=turpis&condimentum=nec&curabitur=euismod&in=scelerisque&libero=quam&ut=turpis&massa=adipiscing&volutpat=lorem&convallis=vitae&morbi=mattis&odio=nibh&odio=ligula&elementum=nec&eu=sem&interdum=duis&eu=aliquam&tincidunt=convallis&in=nunc&leo=proin&maecenas=at&pulvinar=turpis&lobortis=a&est=pede&phasellus=posuere&sit=nonummy&amet=integer&erat=non&nulla=velit&tempus=donec&vivamus=diam&in=neque&felis=vestibulum&eu=eget&sapien=vulputate&cursus=ut&vestibulum=ultrices&proin=vel&eu=augue&mi=vestibulum&nulla=ante&ac=ipsum&enim=primis&in=in&tempor=faucibus&turpis=orci&nec=luctus&euismod=et&scelerisque=ultrices&quam=posuere&turpis=cubilia&adipiscing=curae&lorem=donec&vitae=pharetra&mattis=magna&nibh=vestibulum&ligula=aliquet&nec=ultrices&sem=erat&duis=tortor&aliquam=sollicitudin",
//     "leadSource": 1,
//     "leadType": "FAMD",
//     "leadStatus": "ZA",
//     "serviceRequired": true,
//     "followUpStatus": "Retaining Wall and Brick Pavers"
//   },
//   {
//     "clientId": 28,
//     "firstName": "Wildon",
//     "lastName": "Iacovielli",
//     "email": "wiacoviellir@apple.com",
//     "address": "Male",
//     "contactDID": "#8afe72",
//     "companyName": "Schulist-Nolan",
//     "industry": "Thoughtbeat",
//     "primaryOrNot": true,
//     "websiteUrl": "https://webs.com/nisl/venenatis/lacinia/aenean/sit.jsp?quam=volutpat&fringilla=eleifend&rhoncus=donec&mauris=ut&enim=dolor&leo=morbi&rhoncus=vel&sed=lectus&vestibulum=in&sit=quam&amet=fringilla&cursus=rhoncus&id=mauris&turpis=enim&integer=leo&aliquet=rhoncus&massa=sed&id=vestibulum&lobortis=sit&convallis=amet&tortor=cursus&risus=id&dapibus=turpis&augue=integer&vel=aliquet&accumsan=massa&tellus=id&nisi=lobortis&eu=convallis&orci=tortor&mauris=risus&lacinia=dapibus&sapien=augue&quis=vel&libero=accumsan&nullam=tellus&sit=nisi&amet=eu&turpis=orci&elementum=mauris&ligula=lacinia&vehicula=sapien&consequat=quis&morbi=libero&a=nullam&ipsum=sit&integer=amet&a=turpis&nibh=elementum&in=ligula&quis=vehicula&justo=consequat&maecenas=morbi&rhoncus=a&aliquam=ipsum&lacus=integer",
//     "leadSource": 3,
//     "leadType": "BGAP",
//     "leadStatus": "GL",
//     "serviceRequired": false,
//     "followUpStatus": "Asphalt Paving"
//   },
//   {
//     "clientId": 29,
//     "firstName": "Carmine",
//     "lastName": "Dumingos",
//     "email": "cdumingoss@phpbb.com",
//     "address": "Male",
//     "contactDID": "#a6b0cb",
//     "companyName": "Schmeler-Rodriguez",
//     "industry": "Omba",
//     "primaryOrNot": false,
//     "websiteUrl": "https://usda.gov/sit/amet/eleifend/pede/libero.jpg?id=quis&ornare=libero&imperdiet=nullam&sapien=sit&urna=amet&pretium=turpis&nisl=elementum&ut=ligula&volutpat=vehicula&sapien=consequat&arcu=morbi&sed=a&augue=ipsum&aliquam=integer&erat=a&volutpat=nibh&in=in&congue=quis&etiam=justo&justo=maecenas&etiam=rhoncus&pretium=aliquam&iaculis=lacus&justo=morbi&in=quis&hac=tortor&habitasse=id&platea=nulla&dictumst=ultrices&etiam=aliquet&faucibus=maecenas&cursus=leo&urna=odio&ut=condimentum&tellus=id&nulla=luctus&ut=nec&erat=molestie&id=sed&mauris=justo&vulputate=pellentesque&elementum=viverra&nullam=pede&varius=ac&nulla=diam&facilisi=cras&cras=pellentesque&non=volutpat&velit=dui&nec=maecenas&nisi=tristique&vulputate=est&nonummy=et&maecenas=tempus&tincidunt=semper&lacus=est&at=quam&velit=pharetra&vivamus=magna&vel=ac&nulla=consequat&eget=metus&eros=sapien&elementum=ut&pellentesque=nunc&quisque=vestibulum&porta=ante&volutpat=ipsum&erat=primis&quisque=in&erat=faucibus&eros=orci&viverra=luctus&eget=et&congue=ultrices&eget=posuere&semper=cubilia&rutrum=curae&nulla=mauris",
//     "leadSource": 6,
//     "leadType": "LYNI",
//     "leadStatus": "RS",
//     "serviceRequired": false,
//     "followUpStatus": "Ornamental Railings"
//   }

export const activityLog = [
  {
    id: 1,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 2,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 3,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 4,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 5,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 6,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 7,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 8,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 9,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
  {
    id: 10,
    dateTime: "2023-11-03",
    action: "Add",
    activityLog: "A new item 'PH00001'",
    module: "Employee List",
    user: "Tina",
  },
];
