import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import Button from "../../../components/common/Button";
import { NumberDropdownList } from "../../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import ServiceSectionTable from "./LatestNewsTable";
import { baseObj } from "../../../utils/constants";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import LatestNews, { ILabelValueData, LatestNewsType } from "../LatestNews";
import { useGetMediaListQuery } from "../../../app/services/services/media";
import { ResurceType } from "../../../media-resources/components/Resources";
import { endpointUrl } from "../../../app/services/api";
import axios from "../../../axios";
import { ToastContainer, toast } from "react-toastify";
import { useGetMediaCategoryListQuery } from "../../../app/services/services/mediaCategory";
import LatestNewsImageUploader, { IInitialData } from "./LatestNewsImageUploader";
import { useGetLatestImageListInfoMutation, useGetLatestImageListQuery } from "../../../app/services/services/mediaResource";
import LatestNewsImagesTable from "./LatestNewsImagesTable";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { getAuthUser } from "../../../app/services/dashboard";
export interface ILatestImages {
  id: number;
  news:string;
  image_list: File[] | undefined;
  image_url_list: string[];
}
const LatestNewsSectionImageList = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [activeLang, setActiveLang] = useState<string>("en");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [showList, setShowList] = useState<boolean>(true);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isSave, setIsSave] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [removeImages,setRemoveImage]=useState<string[]>([]);
  const [servicesData, setServicesData] = useState<IInitialData>({
    id: 0,
    category_id:'',
    media_files: []
  });
  const [categoryList,setCategoryList]=useState<ILabelValueData[]>([]);
  const [getMediaInfo] = useGetLatestImageListInfoMutation();
  const {data:images}=useGetLatestImageListQuery({
    page: 1,
    limit: pagination.pageSize,
    search: '',
    sort: sorting?sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','):'',
  })
  const { data: medias } = useGetMediaListQuery({
    page: 1,
    limit: pagination.pageSize,
    search: '',
    type: 'latest-news',
    sort:''
  })
  useEffect(()=>{
    if(images){
      setData(images?.data);
      setItemCount(images?.total);
    }
  },[images])
  useEffect(()=>{
    if(medias){
      console.log("media ",medias)
      if(medias?.data){
        setCategoryList(medias?.data?.map((x:any)=>{
          return {
            value:x.id,
            label:x.title_en
          }
        }));
      }
    }
  },[medias]);
  const handle_submit = async (data:ILatestImages|undefined, imageFiles: File[],imageUrlList:string[]) => {
    let existingImages:any=[];
    if(data){
      servicesData.media_files?.map((img:any)=>{
        if(imageUrlList?.find(x=>x!==img.file_path)){
          existingImages=[...existingImages,img.id]
        }
      })
      setIsSave(true);
      const formData = new FormData();
      console.log("data ",data)
      formData.append('media_id', data.news?data.news.toString():categoryList?categoryList[0]?.value:'');
     // formData.append('files[]', imageFiles as any);      
      imageFiles?.forEach(function(img){
        formData.append('files[]', img as any);      
      })
      formData.append('remove_ids', removeImages?.map(x=>x)?.join(','))//(imageUrlList.length>0?JSON.stringify(existingImages):JSON.stringify([])));
      // formData.append('page_type', 'media-report');
      //const url=data.id?'media-files/update':'media-files/upload';
      const url='media-files/upload';
      var getUser = getAuthUser();
      await axios(endpointUrl + url, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getUser.token}`,
        },
        data: formData,
      })
        .then((res: any) => {
          if (res?.data?.status == true) {
            getMediaInfo({
              page: currentPage,
              limit: pagination.pageSize,
              search: '',
              sort:''
            }).then((res: any) => {
              setData(res?.data?.data);
              setItemCount(res?.data?.total)
            })
            toast(res?.data?.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
            setShowList(true);
          } else {
            setErrorPopup(true);
            setMessage(res?.data?.message);
          }
          setIsSave(false);
        })
        .catch((err) => {
          setIsSave(false);
          setErrorPopup(true);
          console.log(err?.response)
          setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
        });
    }
    
  }
  const clear_data = () => {
    setServicesData({
      id: 0,
      category_id:'',
      media_files:[]
    })
  }
  return (
    <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 ">
       <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
        />
      {showList == false ? <div>
        <LatestNewsImageUploader
          handle_submit={handle_submit}
          isSave={isSave}
          initialData={servicesData}
          setValue={setServicesData}
          setShowList={setShowList}
          categoryList={categoryList}
          setRemoveImage={setRemoveImage}
          removeImages={removeImages}
        />
      </div> : <>
        <div className="flex flex-wrap justify-between items-center mb-1">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {setState =>
                <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={e => setState(e.target.value)}
                  />
                  <SearchIcon className="mr-2" />
                </div>}
            </DebounceSearchInput>
          </div>

          <div className="flex items-center xs:mt-1 sm:mt-1">
            <Button
              label="+ Add New"
              onClick={() => { clear_data(); setShowList(false) }}
              type="button"
              variant="primary"
              customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
              size="base"
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col justify-end mb-1">
          <div className="flex items-center sm:justify-start justify-end">
            <div className="flex items-center 3xl:ml-3 mr-[10px]">
              <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                DISPLAY
              </p>
              <NumberDropdownList
                state={pagination.pageSize}
                setState={value =>
                  setPagination(prev => ({
                    ...prev,
                    pageSize: value as number
                  }))}
                dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                  const count = (index + 1) * 20;
                  return {
                    value: count,
                    label: String(count)
                  };
                })}
                className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                textAlign="text-left pl-3"
                paddingClass="py-[2px]"
                placeholder="All"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="text-left">
            <LatestNewsImagesTable
              // columnsData={columnsData}
              isLayoutControlOpen={isLayoutControlOpen}
              setIsLayoutControlOpen={setIsLayoutControlOpen}
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              pagination={pagination}
              data={data}
              setData={setData}
              setPagination={setPagination}
              hiddenFields={hiddenFields ? hiddenFields : []}
              setHiddenFields={setHiddenFields}
              checkManagement={true}
              total={itemCount}
              localModuleId={localModuleId}
              // visArray={visArray}
              templateList={tempList}
              setCurrentView={() => 1}
              setCols={setCols}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setDetailData={setServicesData}
              setShowList={setShowList}
              sorting={sorting}
            setSorting={setSorting}
            />
          </div>
        </div>
      </>}
    </div>
  );
};
export default LatestNewsSectionImageList;
