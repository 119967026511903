import {
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  // PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
  // Dispatch,
  // FC,
  // SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import {
  // tagTypes,
  tagTypeArr,
} from "../../utils/announcementDatas";
import { baseObj } from "../../utils/constants";
import { AddIcon, ChevronDown, EditIcon, TrashIcon, ViewIcon } from "../common/Icons";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import styles from "./AnouncementTable.module.scss";
import { makeStyles } from "@mui/styles";
import { Tooltip, Backdrop, Modal } from "@mui/material";
import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { toast, ToastContainer } from "react-toastify";
import EmployeeAssignment from "../Passwords/EmployeeAssignment";
import { PasswordUserList } from "../../app/services/password";
import { renderDateFormatDMYTimeStamp } from "../DashboardCalendarView/DashboardAddEventPopup";
import { useDeleteNoticeBoardMutation, useMultiDeleteNoticeBoardMutation } from "../../app/services/notice-board";
import NoticeBoardAcknowledgePopup from "../NoticeBoardModalEditor/NoticeBoardAcknowledgePopup";

export type RowData = { [key: string]: any };
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
export const tagsColor = ["#FFF0CD", "#E1E9FF", "#E5F6FF"];

const NoticeBoardTable = (props: any) => {
  const classes = useStyles();
  const [showAck, setShowAck] = useState<boolean>(false);
  const [isNewAnModalOpen, setIsNewAnModalOpen] = useState<boolean>(false);
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const selectRowsCount = Object.keys(rowSelection).length;
  // const [data, setData] = useState<RowData[]>(props.dummyAnnounData);
  const [data, setData] = useState<RowData[]>([]);
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [multipleDelete, setMultipleDelete] = useState<boolean>(false);
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<"assign" | "remove">("assign");
  const [quotationModalState, setQuotationModalState] =
    useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number | 0>();
  // const [dropdownData, setDropDownData] = useState<DropdownFieldData[]>();
  // const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const [editRowData, setEditRowData] = useState<any>();
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [deleteNoticeBoard] = useDeleteNoticeBoardMutation();
  const [multipleDeleteNoticeBoard] = useMultiDeleteNoticeBoardMutation();
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<number[]>();
  const [existingMember, setExistingMember] = useState<PasswordUserList[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [ackID,setAckID]=useState<number>(0);
  const [selected,setSelected]=useState<any>();

  useEffect(() => {
    if (data) {
      // setData(props.dummyAnnounData);
      setData(props.data);
    }
  }, [props]);

  const editRow = useCallback(
    (rowData?: any) => {
      setIsNewAnModalOpen(true);
      props.setEditRowDataCus(rowData);
      setEditRowData(rowData?.id);
    },
    [editRowData]
  );

  const deleteRow = useCallback(
    (id?: number, dataId?: number) => {
      setDeleteRowID(id);
      setDeleteID(dataId);
      setMultipleDelete(false);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const deleteItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      if (dataId) {
        deleteNoticeBoard(dataId)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setOpenDeleteopup(false);
              setSuccessPopup(true);
              setMessage([payload?.message]);
              toast("Successfully Deleted.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: "success",
                theme: "light",
              });
              setDeleting(false);
              setData((prev: baseObj[]) => {
                if (dataId != undefined) {
                  // var $dataId = dataId;
                }
                return prev.filter((_, index) => {
                  return id !== undefined
                    ? id !== index
                    : !selectedRows.includes(String(index));
                });
              });
              id === undefined && setRowSelection({});
            }
          })
          .catch((error: any) => {
            setOpenDeleteopup(true);
            setErrorPopup(true);
            setDeleting(false);
            setMessage(
              error ? [error?.data?.message] : ["Something went wrong!"]
            );
          });
      }
    },
    [setData, rowSelection]
  );

  const deleteUsersHandler = useCallback(() => {
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x: any, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x : 0;
      })
      .map((x: any) => x.id);

    var $data: any = {
      ids: selectionIds,
    };

    multipleDeleteNoticeBoard($data)
      .then((payload: any) => {
        const selectedRows = Object.keys(rowSelection);
        setData((prev: baseObj[]) =>
          prev.filter((_, index) => {
            return !selectedRows.includes(String(index));
          })
        );
        setRowSelection({});
        setMultipleDelete(false);
        setOpenDeleteopup(false);
        toast("Successfully Deleted.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
        setDeleting(false);
      })
      .catch((error: any) => {
        setDeleting(false);
      });
  }, [setData, rowSelection]);

  const confirmDeleteData = () => {
    setDeleting(true);
    let id = deleteRowID;
    var realId = deleteID;

    if (multipleDelete) {
      deleteUsersHandler();
    } else {
      deleteItem(id, realId);
      setDeleteID(-1);
    }
  };

  const MultideleteHandler = () => {
    setOpenDeleteopup(true);
    setMultipleDelete(true);
  };

  const handleUpdate = (value: any) => {
    let testType: any = [];
    tagTypeArr.map(function (item: any) {
      value.tag.map((vtag: any, id: any) => {
        if (item.name == vtag) {
          testType.push(item);
        }
      });
    });
    const eidtData = {
      id: value.id,
      title: value.title,
      tag: testType,
      expiryDate: formatDate(value.expiryDate),
      updatedBy: value.updatedBy,
      content: value.content,
      postedDate: value.postedDate,
      postedBy: value.postedBy,
    };
    // for change date format
    function addZero(i: any) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    function formatDate(date: any) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        h = addZero(d.getHours()),
        m = addZero(d.getMinutes()),
        s = addZero(d.getSeconds());
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var customeDate =
        [year, month, day].join("-") + " " + h + ":" + m + ":" + s;
      return customeDate;
    }
    const announceupdated = data.map((emp) => {
      if (emp.id === value.id) {
        return eidtData;
      } else return emp;
    });
    setData(announceupdated);
  };

  // TODO add type definitions when api binding
  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: `${props?.checkManagement ? "selection" : "none-selection"}`,
      }),
      columnHelper.accessor("id", {
        header: "Notice ID",
        id: "id",
        cell: ({ getValue }) => {
          const noticeCode = getValue();
          return (
            <div className=" ">
              {/* min-w-[110px] */}
              <p className="h-full flex items-center">#0000{noticeCode}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("name_en", {
        header: "Name (EN)",
        id: "name_en",
        cell: ({ getValue }) => {
          const noticeName = getValue();
          return (
            <div className=" bg-white text-black2 px-2 flex items-center">
              {/* min-w-[50px] */}
              <p className=" whitespace-nowrap text-ellipsis">
                {/* w-[80%] */}
                {noticeName}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("name_tc", {
        header: "Name (TC)",
        id: "name_tc",
        cell: ({ getValue }) => {
          const noticeName = getValue();
          return (
            <div className=" bg-white text-black2 px-2 flex items-center">
              {/* min-w-[50px] */}
              <p className=" whitespace-nowrap text-ellipsis">
                {/* w-[80%] */}
                {noticeName}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("name_sc", {
        header: "Name (SC)",
        id: "name_sc",
        cell: ({ getValue }) => {
          const noticeName = getValue();
          return (
            <div className=" bg-white text-black2 px-2 flex items-center">
              {/* min-w-[50px] */}
              <p className=" whitespace-nowrap text-ellipsis">
                {/* w-[80%] */}
                {noticeName}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("categories", {
        header: "Categories",
        id: "categories",
        enableSorting: false,
        cell: ({ getValue }) => {
          const tag = getValue();

          return (
            <div className="  overflow-hidden">
              {/* min-w-[190px] */}
              <div>
                {tag?.length == 0 && (
                  <div className="flex justify-center items-center ">---</div>
                )}
                {tag?.map((x: any, index: number) => {
                  var idx = index;
                  if (index >= 3) {
                    idx = index % 3;
                  }
                  return (
                    <div
                      key={index}
                      className="px-[5px] mr-[3px] rounded-[5px] w-fll"
                      style={{ backgroundColor: tagsColor[idx] }}
                    >
                      {x.name_en}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor("published_at", {
        header: "Publish Date",
        id: "published_at",
        cell: ({ getValue }) => {
          const published_at = getValue();
          return (
            <div className="  px-[5px] flex items-center">
              {/* min-w-[140px] */}
              <p className="flex items-center h-full">{published_at}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("expired_at", {
        header: "Expiry Date",
        id: "expired_at",
        cell: ({ getValue }) => {
          const expiryDate = getValue();

          return (
            <div className=" px-[5px] flex items-center">
              {/* min-w-[140px]  */}
              <p className="flex items-center h-full">{expiryDate}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("created_by", {
        header: "Posted By",
        id: "created_by",
        cell: ({ getValue }) => {
          const created_by = getValue();
          return (
            <div className="  px-[5px]">
              {/* min-w-[140px] */}
              <p className="flex items-center h-full">{created_by?.name}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("updated_at", {
        header: "Updated By",
        id: "updated_at",
        cell: ({ getValue }) => {
          const updatedBy = getValue();
          return (
            <div className=" px-[5px]">
              {/* min-w-[140px]  */}
              <p className="flex items-center h-full">{updatedBy}</p>
            </div>
          );
        },
      }),
      // columnHelper.accessor("viewers", {
      //   header: "Viewed By",
      //   id: "viewers",
      //   enableSorting: false,
      //   cell: ({ getValue, cell }) => {
      //     const viewBy = getValue();
      //     const id = cell.row.original.id;
      //     return (
      //       <button
      //         type="button"
      //         onClick={() => {
      //           setModalType("remove");
      //           setIsAssignModalOpen(true);
      //           setSelectedUser(id);
      //           setExistingMember(viewBy);
      //         }}
      //         className="flex justify-center items-center h-full  ml-[10px]"
      //         // min-w-[150px]
      //       >
      //         {viewBy?.length > 0 ? (
      //           viewBy?.map((x: any, index: number) => {
      //             if (index < 3) {
      //               var idx = index;
      //               if (idx > 4) {
      //                 idx = 0;
      //               }
      //               return x.photo ? ( //profile_image
      //                 <img
      //                   key={index}
      //                   src={x.photo}
      //                   alt="profileImage"
      //                   className="w-[35px] h-[35px] rounded-full object-cover ml-[-10px]"
      //                 />
      //               ) : (
      //                 <div
      //                   key={index}
      //                   className={classNames(
      //                     "bg-[" + assignedColor[idx] + "]",
      //                     "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
      //                   )}
      //                 >
      //                   {x.name?.substring(0, 1)}
      //                 </div>
      //               );
      //             }
      //           })
      //         ) : (
      //           <span>---</span>
      //         )}
      //         {viewBy?.length > 3 ? (
      //           <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
      //             {viewBy?.length - 4}+
      //           </div>
      //         ) : (
      //           ""
      //         )}
      //       </button>
      //     );
      //   },
      // }),

      columnHelper.display({
        header: "Action",
        id: `${props?.checkManagement ? "actions" : "none-actions"}`,
        cell: ({ cell }) => {
          const isNew: boolean = cell.row.original?.isNew;
          const dataId: number = cell.row.original?.id
            ? cell.row.original?.id
            : 0;
          return (
            <div className="flex justify-center items-center  max-w-[120px] min-w-[100px]">
              {props?.checkManagement ? (
                <>
                <button
                    type="button"
                    onClick={() => {
                      setSelected(cell.row.original)
                      setAckID(cell.row.original?.id);
                      setShowAck(true);
                    }}
                    className="w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                  >
                    <ViewIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      editRow(cell.row.original);
                      props.setIsNewAnModalOpen(true);
                    }}
                    className="w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                  >
                    <EditIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteRow(Number(cell.row.id), dataId)}
                    className="w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                  >
                    <TrashIcon />
                  </button>
                </>
              ) : (
                <span>---</span>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, props?.data]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }
    x.classList.remove("absolute");
    x.classList.add("left-0");
    x.classList.add("relative");
    var div = document.getElementById(x.id);
    if (div) {
      div.style.setProperty("left", "");
    }
    if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.remove("relative");
      x.classList.add("right-0");
      x.classList.remove("left-0");
    }
  });

  const visibleColumns: { [key: string]: boolean } = {};

  props?.hiddenFields?.map((x: any) => {
    visibleColumns[x] = true;
  });

  if (props?.checkManagement) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (props?.hiddenFields?.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = props?.hiddenFields?.find((h: any) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  useEffect(() => {
    if (data) {
      setData(props.data);
    }
  }, [props]);

  useEffect(() => {
    if (props?.hiddenFields.length > 0) {
      setColumnOrder(["selection", ...props?.hiddenFields]);
    }
  }, [props?.hiddenFields]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  return (
    <div className={classNames(styles.anouncementsTable)}>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
      descText="Are you sure to delete?"
      loading={deleting}
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
      />
      <Modal
        open={isAssignModalOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 5s",
        }}
        // onClose={() => setIsAssignModalOpen(false)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setIsAssignModalOpen(false);
          }
        }}
        aria-labelledby="password-add-modal"
        aria-describedby="password-add-modal"
        closeAfterTransition
      >
        <>
          <EmployeeAssignment
            showInfo={false}
            title="Viewed Members"
            isMultiple={isMultiple}
            assignedUsersMultipleDoc={() => 1}
            setSelectedMemberData={setSelectedMember}
            assignedUsers={() => 1}
            employeeList={data?.find((d: any) => d.id == selectedUser)?.viewers}
            modalType={modalType}
            setIsOpen={setIsAssignModalOpen}
            isOpen={isAssignModalOpen}
            existingMember={existingMember}
            isAnnounc={true}
          />
        </>
      </Modal>

      <div className="text-primary text-left">
        {/* <QuotationNumModal
          state={quotationModalState}
          setState={setQuotationModalState}
        /> */}
        <div
          className={classNames(
            styles.annoucementTable,
            "relative max-w-full"
          )}
        >
          <LayoutAdjustableTable
            // saveTemplateData={saveTemplateData}
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={props.sorting}
            setSortingState={props.setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={props?.isLayoutControlOpen}
            setIsLayoutControlOpen={props?.setIsLayoutControlOpen}
            label="Notice Board Lists"
            setItemCount={setItemCount}
            pagination={props?.pagination}
            setPagination={props?.setPagination}
            globalFilter={props?.globalSearch}
            setGlobalFilter={props?.setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            localModuleId={props?.localModuleId}
            // visArray={props?.visArray}
            setCols={props?.setCols}
            setIsTemplate={props?.setIsTemplate}
          />
        </div>
        {selectRowsCount > 0 && (
          <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-8 mb-10 lg:mt-10 lg:mb-12 ">
            <p>{selectRowsCount} selected.</p>
            {props?.checkManagement ? (
              <button
                type="button"
                onClick={() => MultideleteHandler()}
                className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
              >
                <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
                  <TrashIcon width={10} height={12} />
                </div>
                Delete
              </button>
            ) : null}
          </div>
        )}
        <div className="pt-6">
          <TablePaginator
            total={props.itemCount}
            pagination={props.pagination}
            setPagination={props.setPagination}
            itemCount={props.total}
            setCurrentPage={props.setCurrentPage}
            currentPageDefault={props.currentPage}
            // changePage={props.changePage}
          />
        </div>
      </div>
      <NoticeBoardAcknowledgePopup isOpen={showAck} setIsOpen={setShowAck} id={ackID} selected={selected}/>
    </div>
  );
};

export default NoticeBoardTable;
