import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import Button from "../../../components/common/Button";
import { NumberDropdownList } from "../../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import SectionTable from "./SectionTable.module.scss";
import { baseObj } from "../../../utils/constants";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import MediaReportTable from "./SectionTable";
import Category, { CategoryType } from "../Category";
import { useGetMediaCategoryInfoMutation, useGetMediaCategoryListQuery } from "../../../app/services/services/mediaCategory";
import { endpointUrl } from "../../../app/services/api";
import axios from "../../../axios";
import { toast } from "react-toastify";
import { getAuthUser } from "../../../app/services/dashboard";
const LatestSectionList = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [showList, setShowList] = useState<boolean>(true);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [activeLang, setActiveLang] = useState<string>("en");
  const [servicesData, setServicesData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isSave, setIsSave] = useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [detailData, setDetailData] = useState<CategoryType>({
    id: 0,
    title_en: "",
    title_sc: "",
    title_tc: "",
  });
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const [getCategoryInfo] = useGetMediaCategoryInfoMutation();
  const { data: categories } = useGetMediaCategoryListQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    type: 'latest-news',
    sort: sorting?sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','):'',
    
  });
  useEffect(() => {
    if (categories) {
      setData(categories?.data);
      setItemCount(categories?.total);
    }
  }, [categories]);
  const handle_submit = async (data:CategoryType) => {

    const formData = new FormData();
    formData.set('id', data.id?.toString() ?? '0');
    formData.set('title_en', data.title_en);
    formData.set('title_tc', data.title_tc);
    formData.set('title_sc', data.title_sc);
    formData.set('page_type', 'latest-news');
    const url = data?.id ? 'update-media-category' : 'create-media-category';
    var getUser = getAuthUser();
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          getCategoryInfo({
            page: currentPage,
            limit: pagination.pageSize,
            search: '',
            type: 'latest-news',
            sort:''
          }).then((res: any) => {
            setData(res?.data?.data);
            setItemCount(res?.data?.total)
          })
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          setShowList(true);
        } else {
          setErrorPopup(true);
          setMessage(res?.response?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        setErrorPopup(true);
        setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
      });
  }
  return (
    <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 px-4 w-full h-full font-primary">
      {showList == false ? <Category
      isSave={isSave}
        handle_submit={handle_submit}
        initialData={detailData}
        setValue={setData}
        setShowList={setShowList}
      /> : <>
        <div className="flex flex-wrap justify-between items-center mb-1">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {setState =>
                <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={e => setState(e.target.value)}
                  />
                  <SearchIcon className="mr-2" />
                </div>}
            </DebounceSearchInput>
          </div>

          <div className="flex items-center xs:mt-1 sm:mt-1">
            <Button
              label="+ Add New"
              onClick={() => setShowList(false)}
              type="button"
              variant="primary"
              customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
              size="base"
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col justify-end mb-1">
          <div className="flex items-center sm:justify-start justify-end">
            <div className="flex items-center 3xl:ml-3 mr-[10px]">
              <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                DISPLAY
              </p>
              <NumberDropdownList
                state={pagination.pageSize}
                setState={value =>
                  setPagination(prev => ({
                    ...prev,
                    pageSize: value as number
                  }))}
                dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                  const count = (index + 1) * 20;
                  return {
                    value: count,
                    label: String(count)
                  };
                })}
                className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                textAlign="text-left pl-3"
                paddingClass="py-[2px]"
                placeholder="All"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="text-left">
            <MediaReportTable
              // columnsData={columnsData}
              isLayoutControlOpen={isLayoutControlOpen}
              setIsLayoutControlOpen={setIsLayoutControlOpen}
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              pagination={pagination}
              data={data}
              setData={setData}
              setPagination={setPagination}
              hiddenFields={hiddenFields ? hiddenFields : []}
              setHiddenFields={setHiddenFields}
              checkManagement={true}
              total={itemCount}
              localModuleId={localModuleId}
              // visArray={visArray}
              templateList={tempList}
              setCurrentView={() => 1}
              setCols={setCols}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setDetailData={setDetailData}
              setShowList={setShowList}
              sorting={sorting}
            setSorting={setSorting}
            />
          </div>
        </div>
      </>}
    </div>
  );
};
export default LatestSectionList;
