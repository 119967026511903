import { FC, useEffect, useState } from "react";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { ILeaderShipMember } from "./LeadershipPage";
import LabelAndNumber from "../../components/SeoData/LabelAndNumber";
export interface ILeadershipInput{
    subtitle_en:string;
    subtitle_tc:string;
    subtitle_sc:string;
    title_en:string;
    title_tc:string;
    title_sc:string;
    id:number;
    order:number;
    hide_profile_image:boolean;
}
interface ILeaderShip{
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    info:ILeaderShipMember;
    setInfo:React.Dispatch<React.SetStateAction<ILeaderShipMember>>;
    name:string;
    setHideProfileImage: React.Dispatch<React.SetStateAction<boolean>>;
}
const LeadershipInput:FC<ILeaderShip>=({
    setShow,
    info,
    setInfo,
    name,
    setHideProfileImage
})=>{
    const [data, setData] = useState<ILeadershipInput | undefined>();
    const [hideProfile,setHideProfile]=useState<boolean>(false);
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (info) {
            setInfo({ ...info, [key]: value });
        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(()=>{
        if(info){            
            setData({
                id:info.id,
                title_en:info.title_en,
                title_tc:info.title_tc,
                title_sc:info.title_sc,
                subtitle_en:info.subtitle_en,
                subtitle_tc:info.subtitle_tc,
                subtitle_sc:info.subtitle_sc,
                order:info.order,
                hide_profile_image:info.hide_profile_image??false
            });
            setHideProfile(info.hide_profile_image??false)
        }
    },[info])
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Subtitle (EN)" value={data?.subtitle_en} setValue={(value) => change_data(value, 'subtitle_en')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
               <LabelAndTextbox label="Subtitle (TC)" value={data?.subtitle_tc} setValue={(value) => change_data(value, 'subtitle_tc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Title (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Subtitle (SC)" value={data?.subtitle_sc} setValue={(value) => change_data(value, 'subtitle_sc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Title (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                </div>
            </div>
            <div className="mb-0 last:mb-0 w-full mt-5">
                    <label
                      className="text-black2 font-normal body-15"
                      htmlFor=""
                    >
                      Hide Profile
                    </label>
                    <div className={`w-fit h-fit customSwitch`}>
                      <label
                        htmlFor={name}
                        className="flex items-center cursor-pointer relative"
                      >
                        <input
                        className="sr-only"
                          type="checkbox"
                          id={name}
                          name={name}
                          value={`${hideProfile}`}
                          checked={hideProfile}
                          onChange={(e) => {
                            change_data(e.target.checked,'hide_profile_image')
                            setHideProfile(e.target.checked);
                            setHideProfileImage(e.target.checked);
                          }}
                        />
                        <div className="toggleBg bg-[#EFF2F5] w-10 h-6 sm:h-[30px] sm:w-12 rounded-full after:absolute after:content-[''] after:top-1 after:left-1 after:bg-white after:rounded-full  after:h-4 after:w-4  after:sm:h-[22px] after:sm:w-[22px] transition-all duration-300 after:transition-all after:duration-300"></div>
                      </label>
                    </div>
                  </div>
        </div>
        
    </div>
}
export default LeadershipInput;