import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import Button from "../../../components/common/Button";
import { NumberDropdownList } from "../../../components/common/DropdownList";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import JobOpeningSectionTable from "./JobOpeningSectionTable.module.scss";
import { baseObj } from "../../../utils/constants";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import MediaReportTable from "./JobOpeningTable";
import JobOpening, { JobOpeningType } from "../JobOpening";
import { useFetch_job_openingQuery, useFetch_job_opening_infoMutation } from "../../../app/services/job/job";
import { ToastContainer, toast } from "react-toastify";
import { endpointUrl } from "../../../app/services/api";
import axios from "../../../axios";
import { useFetch_jobcategory_openingQuery } from "../../../app/services/job/jobCategory";
import { JobCategoryType } from "../JobCategory";
import { useFetch_jobdepartment_openingQuery } from "../../../app/services/job/jobDepartment";
import ErrorPopup from "../../../components/ErrorPopup/ErrorPopup";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { getAuthUser } from "../../../app/services/dashboard";
const JobOpeningSectionList = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [activeLang, setActiveLang] = useState<string>("en");
  const [servicesData, setServicesData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [detailData, setDetailData] = useState<JobOpeningType>({
    id: 0,
    job_category_id: "1",
    job_department_id: '1',
    title_en: "",
    title_sc: "",
    title_tc: "",
    description_en: "",
    description_sc: "",
    description_tc: "",
    meta_title_en: "",
    meta_title_tc: "",
    meta_title_sc: "",
    meta_description_en: '',
    meta_description_tc: '',
    meta_description_sc: '',
    meta_image: undefined,
    meta_image_url: ''
  });
  const [showList, setShowList] = useState<boolean>(true);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isSave, setIsSave] = useState<boolean>(false);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const [getJobInfo] = useFetch_job_opening_infoMutation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { data: jobs } = useFetch_job_openingQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    sort: sorting?sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','):'',
  });
  const [categoryList, setCategoryList] = useState<JobCategoryType[]>([]);
  const [deptList, setDeptList] = useState<JobCategoryType[]>([]);
  const { data: categories } = useFetch_jobcategory_openingQuery({
    page: 1,
    limit: 0,
    search: '',
    sort:''
  });
  const { data: departments } = useFetch_jobdepartment_openingQuery({
    page: 1,
    limit: 0,
    search: '',
    sort:''
  })
  useEffect(() => {
    if (jobs) {
      setData(jobs?.data);
      setItemCount(jobs?.total);
    }
  }, [jobs]);
  useEffect(() => {
    if (categories) {
      setCategoryList(categories?.data)
    }
  }, [categories])
  useEffect(() => {
    if (departments) {
      setDeptList(departments?.data)
    }
  }, [departments])
  const clear_data = () => {
    setDetailData({
      id: 0,
      job_category_id: "1",
      job_department_id: "1",
      title_en: "",
      title_sc: "",
      title_tc: "",
      description_en: "",
      description_sc: "",
      description_tc: "",
      meta_title_en: "",
      meta_title_tc: "",
      meta_title_sc: "",
      meta_description_en: '',
      meta_description_tc: '',
      meta_description_sc: '',
      meta_image: undefined,
      meta_image_url: ''
    })
  }
  const handle_submit = async (data: JobOpeningType) => {
    const formData = new FormData();
    formData.append('id', data?.id?.toString() ?? '0');
    formData.append('title_en', data?.title_en ?? '');
    formData.append('title_tc', data?.title_tc ?? '');
    formData.append('title_sc', data?.title_sc ?? '');
    formData.append('description_en', data?.description_en ?? '');
    formData.append('description_tc', data?.description_tc ?? '');
    formData.append('description_sc', data?.description_sc ?? '');
    formData.append('job_category_id', data?.job_category_id ?? '');
    formData.append('job_department_id', data?.job_department_id ?? '');
    formData.append('meta_title_en', data?.meta_title_en ?? '');
    formData.append('meta_title_tc', data?.meta_title_tc ?? '');
    formData.append('meta_title_sc', data?.meta_title_sc ?? '');
    formData.append('meta_description_en', data?.meta_description_en ?? '');
    formData.append('meta_description_tc', data?.meta_description_tc ?? '');
    formData.append('meta_description_sc', data?.meta_description_sc ?? '');
    if(data?.meta_image)
    formData.append('meta_image', data?.meta_image ?? '');
    const url = data?.id ? 'update-job-openings' : 'create-job-openings';
    var getUser = getAuthUser();
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          getJobInfo({
            page: 1,
            limit: 20,
            search: '',
            sort:'',
          }).then((res: any) => {
            setData(res?.data?.data);
            setItemCount(res?.data?.total)
          })
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          setShowList(true);
        } else {
          setErrorPopup(true);
          setMessage(res?.data?.message);
        }
        setIsSave(false);
      })
      .catch((err) => {
        setErrorPopup(true);
        console.log("err ",err?.response)
        setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
      });
  }
  return (
    <div className="bg-white rounded-xl pt-4 pb-4 sm:pt-4 sm:pb-9 px-4 w-full h-full font-primary">
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      {showList == false ? <div>
        <JobOpening isSave={isSave}
          initialData={detailData}
          setValue={setDetailData}
          setShowList={setShowList}
          handle_submit={handle_submit}
          categoryList={categoryList}
          departmentList={deptList}
        />
      </div> : <>
        <div className="flex flex-wrap justify-between items-center mb-1">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {setState =>
                <div className="flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={e => setState(e.target.value)}
                  />
                  <SearchIcon className="mr-2" />
                </div>}
            </DebounceSearchInput>
          </div>

          <div className="flex items-center xs:mt-1 sm:mt-1">
            <Button
              label="+ Add New"
              onClick={() => { clear_data(); setShowList(false) }}
              type="button"
              variant="primary"
              customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
              size="base"
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col justify-end mb-1">
          <div className="flex items-center sm:justify-start justify-end">
            <div className="flex items-center 3xl:ml-3 mr-[10px]">
              <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                DISPLAY
              </p>
              <NumberDropdownList
                state={pagination.pageSize}
                setState={value =>
                  setPagination(prev => ({
                    ...prev,
                    pageSize: value as number
                  }))}
                dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                  const count = (index + 1) * 20;
                  return {
                    value: count,
                    label: String(count)
                  };
                })}
                className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                textAlign="text-left pl-3"
                paddingClass="py-[2px]"
                placeholder="All"
              />
            </div>
          </div>
        </div>

        <div>
          <div className="text-left">
            <MediaReportTable
              // columnsData={columnsData}
              isLayoutControlOpen={isLayoutControlOpen}
              setIsLayoutControlOpen={setIsLayoutControlOpen}
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              pagination={pagination}
              data={data}
              setData={setData}
              setPagination={setPagination}
              hiddenFields={hiddenFields ? hiddenFields : []}
              setHiddenFields={setHiddenFields}
              checkManagement={true}
              total={itemCount}
              localModuleId={localModuleId}
              // visArray={visArray}
              templateList={tempList}
              setCurrentView={() => 1}
              setCols={setCols}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setDetailData={setDetailData}
              setShowList={setShowList}
              sorting={sorting}
                setSorting={setSorting}
            />
          </div>
        </div></>}
    </div>
  );
};
export default JobOpeningSectionList;
