import { FC, useEffect, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { AddIcon, CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import classNames from "classnames";
import { IFooterCareer } from "../../app/services/home/home";


interface IData {
  activeLang: string;
  initialData: IFooterBanner;
  setValue: React.Dispatch<any>;
}
export interface IFooterBanner {
  description_en: string;
  description_zh: string;
  description_cn: string;
  button1_en: string;
  button1_zh: string;
  button1_cn: string;
  button2_en: string;
  button2_zh: string;
  button2_cn: string;
  button1_en_link: string;
  button1_zh_link: string;
  button1_cn_link: string;
  button2_en_link: string;
  button2_zh_link: string;
  button2_cn_link: string;
}
interface WhyChooseUsData {
  title: string;
  description: string;
}
const FooterBanner: FC<IData> = ({
  activeLang,
  initialData,
  setValue
}) => {
  const [data, setData] = useState<IFooterBanner>();
  const descriptionRef_en: any = useRef(null);
  const descriptionRef_tc: any = useRef(null);
  const descriptionRef_sc: any = useRef(null);
  const change_data = (value: any, key: string) => {
    if (data) {
      setData({ ...data, [key]: value });
    }
    if (initialData) {
      setValue({ ...initialData, [key]: value })
    }
  };
  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);
  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">Footer Banner</h2>
      <div
        className={twJoin(
          activeLang == "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >

        <div className="grid grid-cols-1">
          <LabelAndEditor
            name="description"
            refId={descriptionRef_en}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value: any) => change_data(value, 'description_en')}
          />
          <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
            <LabelAndTextbox
              label="Career With Us Button Text ( En)"
              value={data?.button1_en}
              setValue={(value: any) => change_data(value, 'button1_en')}
            />
            <LabelAndTextbox
              label="Career With Us Button Link (En)"
              value={data?.button1_en_link}
              setValue={(value: any) => change_data(value, 'button1_en_link')}
            />
            <LabelAndTextbox
              label="Career Path Development Button Text (En)"
              value={data?.button2_en}
              setValue={(value: any) => change_data(value, 'button2_en')}
            />
            <LabelAndTextbox
              label="Career Path Development Button Link (En)"
              value={data?.button2_en_link}
              setValue={(value: any) => change_data(value, 'button2_en_link')}
            />
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>

          <div className="grid grid-cols-1">
            <LabelAndEditor
              name="description"
              refId={descriptionRef_tc}
              label="Description (TC)"
              value={data?.description_zh}
              setValue={(value: any) => change_data(value, 'description_tc')}
            />
            <div className="grid md:grid-cols-2 grid-cols-1">
              <LabelAndTextbox
                label="Career With Us Button Text ( TC)"
                value={data?.button1_zh}
                setValue={(value: any) => change_data(value, 'button1_zh')}
              />
              <LabelAndTextbox
                label="Career With Us Button Link (TC)"
                value={data?.button1_zh_link}
                setValue={(value: any) => change_data(value, 'button1_zh_link')}
              />
              <LabelAndTextbox
                label="Career Path Development Button Text (TC)"
                value={data?.button2_zh}
                setValue={(value: any) => change_data(value, 'button2_zh')}
              />
              <LabelAndTextbox
                label="Career Path Development Button Link (TC)"
                value={data?.button2_zh_link}
                setValue={(value: any) => change_data(value, 'button2_zh_link')}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div className="grid grid-cols-1">
          <LabelAndEditor
            name="description"
            refId={descriptionRef_sc}
            label="Description (SC)"
            value={data?.description_cn}
            setValue={(value: any) => change_data(value, 'description_cn')}
          />
          <div className="grid md:grid-cols-2 grid-cols-1">
            <LabelAndTextbox
              label="Career With Us Button Text ( SC)"
              value={data?.button1_cn}
              setValue={(value: any) => change_data(value, 'button1_cn')}
            />
            <LabelAndTextbox
              label="Career With Us Button Link (SC)"
              value={data?.button1_cn_link}
              setValue={(value: any) => change_data(value, 'button1_cn_link')}
            />
            <LabelAndTextbox
              label="Career Path Development Button Text (SC)"
              value={data?.button2_cn}
              setValue={(value: any) => change_data(value, 'button2_cn')}
            />
            <LabelAndTextbox
              label="Career Path Development Button Link (SC)"
              value={data?.button2_cn_link}
              setValue={(value: any) => change_data(value, 'button2_cn_link')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterBanner;
