import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import HeaderDetailView from "./HeaderDetailView";
import CommonLanguage from "../../components/common/CommonLanguage";
import HeaderSubmenuList from "./HeaderSubmenuList";
import HeaderSection from "./HeaderSection";
import HeaderSubmenuView from "./HeaderSubmenuView";
import Button from "../../components/common/Button";
interface IData {
  initialData: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface IMenu {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  slug: string;
  link_en: string;
  link_zh: string;
  link_cn: string;
  sub_menus: ISubMenu[];
}
interface ISubMenu {
  id: number;
  menu_id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  slug: string;
  status: 1;
  order_list: 0;
}
const HeaderSectionDetail: FC<IData> = ({
  initialData,
  setShow,
  setShowList
}) => {
  const [data, setData] = useState<IMenu | undefined>(initialData);
  const [detailData, setDetailData] = useState<any>();
  const [showEditDetail, setShowEditDetail] = useState<boolean>(false);
  const [activeLang, setActiveLang] = useState<string>("en");
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  useEffect(() => {
    setData(initialData);
  }, [initialData]);
  return (
    <div className="mt-5">
      <Button
        label="Back"
        onClick={() => { setShow(false); setShowList(true); }}
        type="button"
        variant="primary"
        customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
        size="base"
      />
      {/* <CommonLanguage
        changeLanguage={changeLanguage}
        activeLang={activeLang}
      /> */}
      <div className="px-2">
        <div
          className={twJoin(
            activeLang == "en"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <HeaderSubmenuView title_label="Title (EN)" link_label="Link (EN)" activeLang={activeLang} setDetailData={setDetailData} detailData={detailData} isShow={showEditDetail} setShowEditDetail={setShowEditDetail} setShowList={setShowList} setShow={setShow} data={data} setData={setData}/>
        </div>
        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <HeaderSubmenuView title_label="Title (TC)" link_label="Link (TC)" activeLang={activeLang} setDetailData={setDetailData} detailData={detailData} isShow={showEditDetail} setShowEditDetail={setShowEditDetail} setShowList={setShowList} setShow={setShow} data={data} setData={setData}/>
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <HeaderSubmenuView title_label="Title (SC)" link_label="Link (SC)" activeLang={activeLang} setDetailData={setDetailData} detailData={detailData} isShow={showEditDetail} setShowEditDetail ={setShowEditDetail} setShowList={setShowList} setShow={setShow} data={data} setData={setData} />
        </div>
      </div>
    </div>
  );
};
export default HeaderSectionDetail;
