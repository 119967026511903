import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface ICompanyData {
    "overview_section": IContactOverview,
    "page": IContactPage,
}
export interface IContactOverview {
    "id": number,
    "page_id": number,
    "section_order": number,
    "unique_name": string,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "name_en": string,
    "name_tc": string,
    "name_sc": string,
    "position_en": string,
    "position_tc": string,
    "position_sc": string,
    "additional_data_en":{
        "name": "",
        "email": "",
        "phone": "",
        "position": ""
    },
    "additional_data_tc":{
        "name": "",
        "email": "",
        "phone": "",
        "position": ""
    },
    "additional_data_sc":{
        "name": "",
        "email": "",
        "phone": "",
        "position": ""
    }
}
export interface IContactPage{
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": File|undefined,
    "banner_desktop_image": File|undefined,
}
export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
};
export const contactApi = api.injectEndpoints({
    endpoints: (build) => ({
        getContactUsData: build.query<ICompanyData, string>({
            query: (page_type) => ({
                url: `fetch-contact-us-page?page_type=${page_type}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "CompanyHistory" as const, id: "LIST" }]
        }),
        deleteCompanyHistory: build.mutation<ICompanyData, number>({
            query(body) {
              return {
                headers: authHeader(),
                url: `delete-our-journey-content`,
                body: {
                  id: body
                },
                method: "POST",
              };
            },
            invalidatesTags: ['CompanyHistory']
          }),
          fetch_contact_list: build.query<TData, PaginationData>({
            query: ({ limit, page, search, sort }) => ({
                url: `contact-list?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "ContactList" as const, id: "LIST" }]
        }),
        fetch_contact_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, sort }) {
                return {
                    headers: authHeader(),
                    url: `contact-list?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['ContactList']
        }),
        deleteContactList: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-contact-list`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['ContactList']
        }),


        fetch_contact_data: build.query<TData, PaginationData>({
            query: ({ limit, page, search, sort }) => ({
                url: `contact-data?per_page=${limit}&page=${page}&search=${search}&sort=${sort??''}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "ContactList" as const, id: "LIST" }]
        }),
        fetch_contact_data_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, sort }) {
                return {
                    headers: authHeader(),
                    url: `contact-data?per_page=${limit}&page=${page}&search=${search}&sort=${sort??''}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['ContactList']
        }),
        deleteContactData: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-contact-data`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['ContactList']
        }),
    })
});

export const { useGetContactUsDataQuery,useFetch_contact_listQuery,useFetch_contact_infoMutation,useDeleteContactListMutation,
useFetch_contact_dataQuery,useFetch_contact_data_infoMutation,useDeleteContactDataMutation  } = contactApi;

export const {
    endpoints: { getContactUsData,fetch_contact_list,fetch_contact_info,deleteContactList,
    fetch_contact_data,fetch_contact_data_info,deleteContactData }
} = contactApi;
