import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { useGetMenusInfoMutation, useUpdateMenuMutation } from "../../app/services/header";
import { IMenu } from "./HeaderSectionDetail";
import CommonLanguage from "../../components/common/CommonLanguage";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
interface IData {
  initialData: IMenu;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditDetail: React.Dispatch<React.SetStateAction<boolean>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuData: React.Dispatch<React.SetStateAction<IMenu | undefined>>;
  menuData: IMenu | undefined;
}
interface ISubmenu {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
}
const HeaderSubmenuSection: FC<IData> = ({
  initialData,
  setValue,
  setShowList,
  setShowEditDetail,
  setShow,
  setMenuData,
  menuData
}) => {
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [data, setData] = useState<IMenu | undefined>(initialData);
  const [activeLang, setActiveLang] = useState<string>("en");
  const [getMenuInfo]=useGetMenusInfoMutation();
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  const [save_menu] = useUpdateMenuMutation();
  const change_data = (value: any, key: string) => {
    if (data) {
      setData({ ...data, [key]: value });
    }
  };
  const update_menu = () => {
    const menu = {
      id: data ? data.id : 0,
      title_en: data?.title_en ?? "",
      title_tc: data?.title_tc ?? "",
      title_sc: data?.title_sc ?? "",
      type: 'submenu'
    }
    save_menu(menu).then((res: any) => {
      if (res?.data?.message == "success") {
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
        getMenuInfo({
          page:1,
          limit:20,
          search:'',
          sort:''
        }).then((res:any)=>{
          if(res?.data){
            console.log("es?.data?.data ",menuData,res?.data?.data,res?.data?.data?.find((x:any)=>x.id==menuData?.id))
            
            setMenuData(res?.data?.data?.find((x:any)=>x.id==menuData?.id))
          }
          
        })
        setShow(true);
        setShowList(false);
        setShowEditDetail(false);
      }
    })
  }
  return (
    <div className="mt-5">
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="mb-5">
        <CommonLanguage
          changeLanguage={changeLanguage}
          activeLang={activeLang}
        />
      </div>
      <div
        className={twJoin(
          activeLang == "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (EN)"
          value={data?.title_en}
          setValue={(value) => change_data(value, 'title_en')}
        />
      </div>
      <div
        className={twJoin(
          activeLang == "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (TC)"
          value={data?.title_tc}
          setValue={(value) => change_data(value, 'title_tc')}
        />
      </div>
      <div
        className={twJoin(
          activeLang == "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <LabelAndTextbox
          label="Title (SC)"
          value={data?.title_sc}
          setValue={(value) => change_data(value, 'title_sc')}
        />
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
            // saving ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => update_menu()}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShow(true);
            setShowList(false);
            setShowEditDetail(false);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default HeaderSubmenuSection;
