import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface IFooterInput{
    description_en:string;
    description_tc:string;
    description_sc:string;
}
interface IFooterData{
    footer_data:IFooterInput | undefined;
    setFooterData: React.Dispatch<React.SetStateAction<IFooterInput>>;
}
const FooterInputBoxes:FC<IFooterData> = ({
    footer_data,
    setFooterData
}) => {
    const [activeLang, setActiveLang] = useState<string>("en");
    const [data, setData] = useState<IFooterInput|undefined>(footer_data);
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(()=>{
        setData(footer_data);
    },[footer_data])
    const handle_change=(value:any,key:string)=>{
        if(data){
            setData({...data,[key]:value});            
        }
        if(footer_data){
            setFooterData({...footer_data,[key]:value});
        }
    }
    const descriptionRef_en: any = useRef(null);
    const descriptionRef_zh: any = useRef(null);
    const descriptionRef_cn: any = useRef(null);
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndEditor label="Footer Description (EN)" refId={descriptionRef_en} setValue={(value)=>handle_change(value,'description_en')} value={data?.description_en}/>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndEditor label="Footer Description (TC)" refId={descriptionRef_zh} setValue={(value)=>handle_change(value,'description_tc')} value={data?.description_tc}/>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndEditor label="Footer Description (SC)" refId={descriptionRef_cn} setValue={(value)=>handle_change(value,'description_sc')} value={data?.description_sc}/>
            </div>
        </div>
    </div>
}
export default FooterInputBoxes; 